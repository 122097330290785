.info-search-map {
    position: relative;
    width: 102%;
    height: 100%;
    font-weight: 600;
    font-size: 13px;
  }

.info-search-map p{
    padding: 15px 10px;
  }

.mapTableInfo{
    background-color: #fff;
    padding: 5px 4px; 
    

    margin: -1px;
  }

.mapTableInfo .topText{
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;

    min-width: 168px;
    max-width: 330px;
  }
.mapTableInfo .topText a, .mapTableInfo .topText p{
    background-color: #dcdcdc;
    border-radius: 5px;
    margin-left: 3px;
    margin-bottom: 5px;
    padding: 5px;
    line-height: 15px;
  }
  .mapTableInfo .topText a{
    color: dodgerblue;
    position: relative;
    background-color: transparent;
  }
  .mapTableInfo .topText a::before{
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    background: url('../imgs/icons/link\ copy.svg') no-repeat;
    top: 8px;
    left: -14px;
  }

.mapTableInfo .map-table{
      border: 1px solid #dcdcdc;
      width: 100%;
      height: 100%;
      font-size: 11px;
      border-collapse : collapse
  }

  .mapTableInfo .map-table td{
      padding: 5px 1px;
      text-align: center; 
      border-top: 1px solid #dcdcdc;
  }

  .mapTableInfo .map-table td .inputBox{
    display: flex;
  }
  .mapTableInfo .map-table td .inputBox label{
    margin: 0 3px;
    font-size: 10px;
    line-height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .mapTableInfo .map-table td .inputBox input{
    display: none;
  }