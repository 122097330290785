@charset "UTF-8";
/*reset*/
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap");
*/

/* noto-sans-kr-100 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-100.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/NotoSansKR-Thin.otf") format("otf");
}

/* noto-sans-kr-300 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-300.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/NotoSansKR-Light.otf") format("otf");
}

/* noto-sans-kr-400 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/NotoSansKR-Regular.otf") format("otf");
}

/* noto-sans-kr-500 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-500.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/NotoSansKR-Medium.otf") format("otf");
}

/* noto-sans-kr-700 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/NotoSansKR-Bold.otf") format("otf");
}

/* noto-sans-kr-900 - korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("../fonts/noto-sans-kr-v27-korean-900.woff") format("woff"),
    url("../fonts/NotoSansKR-Black.otf") format("otf");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* user-select: text; */
  -webkit-touch-callout: none;
  -webkit-overflow-scrolling: auto !important;
}

button {
  border: none;
  outline: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

/* css 디자인 참고사항 0614 */
/*flex 정렬*/
/*썸네일 사진 크기 조정*/
/*grid 사진 크기 조정*/
/* button 기본 style setup */
/* 버튼 active */
/* 버튼 기본 스타일 삭제 */
:root {
  --text-defaultBlackColor: #232323;
  --text-defaultGrayColor: #797979;

  --text-warningColor: #cf514a;
  --text-linkColor: #1a78c9;

  --hover-color: #888888;

  --default-deepBlueColor: #014681;
  --default-backgroundGrayColor: #f5f5f5;

  --category-allColor: linear-gradient(
    316.2deg,
    #3773ff -3.34%,
    #22e6f3 131.85%
  );
  --category-weatherColor: #3773ff;
  --category-trafficColor: #f4a100;
  --category-newsColor: #c849f5;
  --category-dailyColor: #fc6eb2;
}

/*-------------------------------------------------------------
  font
--------------------------------------------------------------*/
html,
body {
  min-width: 320px;
  /* -webkit-overflow-scrolling: auto !important; */
  /* overflow: auto !important; */
  /* height: 100% !important; */
  height: auto !important;
  /* 100%시 화면 짤리는 구간발생으로 auto 로 수정*/
}

html {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* html {
  scroll-behavior: smooth;
} */

body {
  line-height: 1;
  font-family: "Noto Sans KR", sans-serif;
}

button,
a,
input,
textarea,
select {
  font-family: "Noto Sans KR", sans-serif;
}

textarea {
  resize: none;
}

button:focus,
a:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  /* font-size: inherit; */
}

button {
  cursor: pointer;
}

/* a:focus, 
button:focus,
input:focus{
  outline: none;
} */

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus::-moz-placeholder {
  color: transparent !important;
}

input:focus:-ms-input-placeholder {
  color: transparent !important;
}

input:focus::-ms-input-placeholder {
  color: transparent !important;
}

textarea:focus::-webkit-textarea-placeholder {
  color: transparent !important;
}

textarea:focus::-moz-placeholder {
  color: transparent !important;
}

textarea:focus:-ms-textarea-placeholder {
  color: transparent !important;
}

textarea:focus::-ms-textarea-placeholder {
  color: transparent !important;
}

/*ios*/
input[type="text"],
input[type="password"],
select,
textarea {
  -webkit-appearance: none;
}

/*ie*/
input::-ms-clear {
  display: none;
}

input[type="radio"] {
  display: none;
}

.radio-label {
  font-size: 13px;
  color: #666666;
  line-height: 13px;
  position: relative;
  padding-left: 10px;
}

.img-item.remove-radio input[type="radio"] + label {
  width: 100%;
  height: 50%;
}

.img-item.remove-radio input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border: solid 1px #c4c4c4; */
  border: solid 1px #c4c4c4;
  border-radius: 50%;
  margin-right: 2em;
  vertical-align: middle;
  position: relative;
  left: 26px;
  top: 3px;
}

.img-item.remove-radio input[type="radio"] + label::after {
  content: "";
  top: 9px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  /* background: var(--default-deepBlueColor); */
  background-color: #3773ff;
  left: 48px;
}

input[type="radio"] + label {
  font-size: 13px;
  color: #666666;
  line-height: 13px;
  position: relative;
  padding-left: 19px;
}

input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  border: solid 1px #c4c4c4;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 3px;
}

input[type="radio"]:checked + label::before {
  border-color: var(--default-deepBlueColor);
}

input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 3px;
  top: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: var(--default-deepBlueColor);
}

/*catergories 별 색상 코드*/
/* 카테고리 추가 될때마다 컬러, 라벨, 탭버튼, 배경색 커스템 추가 필요 */
/*label badge*/
.tag.type01 {
  background: var(--category-weatherColor);
}

.tag.type02 {
  background: var(--category-trafficColor);
}

.tag.type03 {
  background: var(--category-newsColor);
}

.tag.type04 {
  background: var(--category-dailyColor);
}

.tag.type05 {
  background: #ff7676;
}

/*카테고리 탭 버튼*/
.tab-btns .btn.active {
  font-weight: bold;
}

.tab-btns .btn.active.type0 {
  background: var(--category-allColor);
  /* border-color: var(--category-allColor); */
  /* border-color: transparent; */
  color: #fff;
}

.tab-btns .btn.active.type01 {
  background: var(--category-weatherColor);
  border-color: var(--category-weatherColor);
  color: #fff;
}

.tab-btns .btn.active.type02 {
  background: var(--category-trafficColor);
  border-color: var(--category-trafficColor);
  color: #fff;
}

.tab-btns .btn.active.type03 {
  background: var(--category-newsColor);
  border-color: var(--category-newsColor);
  color: #fff;
}

.tab-btns .btn.active.type04 {
  background: var(--category-dailyColor);
  border-color: var(--category-dailyColor);
  color: #fff;
}

.tab-btns .btn.active.type05 {
  background: #ff7676;
  border-color: #ff7676;
  color: #fff;
}

/*게시물 배경화면 색상*/
.default-img.type00 {
  background: #dae0e4;
}

.default-img.type01 {
  /* background: var(--category-weatherColor); */
  background: #83a7fb;
}

.default-img.type02 {
  /* background: var(--category-trafficColor); */
  background: #f5c362;
}

.default-img.type03 {
  /* background: var(--category-newsColor); */
  background: #da8ef5;
}

.default-img.type04 {
  /* background: var(--category-dailyColor); */
  background: #f9a4cd;
}

.default-img.type05 {
  background: #ff7676;
}

.input-group.type .type-check {
  display: none;
}

.input-group.type .type-check + label {
  display: inline-block;
  width: auto;
  /* min-width: 90px; */
  min-width: 1rem;
  /* height: 40px; */
  height: 2.5rem;

  border-radius: 20px;
  text-align: center;
  line-height: 2.2rem;
  /* line-height: 36px; */
  font-size: 0.875rem;
  border: solid 1px transparent;
  margin-right: 10px;
  border: solid 1px #dcdcdc;
  background: #fff;
  font-size: 0.875rem;
  color: #292929;
  font-weight: normal;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  cursor: pointer;
}

.input-group.type .type-check.type01:checked + label {
  background: var(--category-weatherColor);
  border-color: var(--category-weatherColor);
  color: #fff;
}

.input-group.type .type-check.type02:checked + label {
  background: var(--category-trafficColor);
  border-color: var(--category-trafficColor);
  color: #fff;
}

.input-group.type .type-check.type03:checked + label {
  background: var(--category-newsColor);
  border-color: var(--category-newsColor);
  color: #fff;
}

.input-group.type .type-check.type04:checked + label {
  background: var(--category-dailyColor);
  border-color: var(--category-dailyColor);
  color: #fff;
}

.input-group.type .type-check.type05:checked + label {
  background: #ff7676;
  border-color: #ff7676;
  color: #fff;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes modalOpen {
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes modalOpen {
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}

@-webkit-keyframes spinAni {
  0%,
  100%,
  80% {
    opacity: 0.6;
    -webkit-transform: scale(0.4);
  }

  40% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes spinAni {
  0%,
  100%,
  80% {
    opacity: 0.6;
    -webkit-transform: scale(0.4);
  }

  40% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes dmSlide {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes dmSlide {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes resultOpen {
  0% {
    left: -390px;
  }

  100% {
    left: 0;
  }
}

@keyframes resultOpen {
  0% {
    left: -390px;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes resultClose {
  0% {
    left: 0;
  }

  100% {
    left: -390px;
  }
}

@keyframes resultClose {
  0% {
    left: 0;
  }

  100% {
    left: -390px;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 0;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }

  100% {
    opacity: 0;
    width: 1px;
    height: 1px;
    left: -5px;
    top: -5px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 0;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }

  100% {
    opacity: 0;
    width: 1px;
    height: 1px;
    left: -5px;
    top: -5px;
  }
}

@keyframes ModalOpenBadge {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 50px;
  }
}

@keyframes ModalOpenBadge_settings {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0px;
  }
}

html.hidden,
body.hidden {
  /* when modal active */
  touch-action: none;
  overflow: hidden;

  /* Other browsers */
  overscroll-behavior: none;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* .container::after {
  content: "";
  display: block;
  clear: both;
} */

.tags .tag {
  height: 17px;
  width: auto;
  min-width: 32px;
  padding: 0 5px;
  border: none;
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  line-height: 17px;
  border-radius: 3px;
  margin-right: 3px;
}

@media screen and (min-width: 481px) and (max-width: 1230px) {
  .container {
    padding: 0 15px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0;
    max-width: 100%;
    margin: 0;
  }

  /* 0614김경민 */
  .container.home {
    padding-top: 35px;
    /* padding-bottom: 50px; */
  }

  /* 0614김경민 */
  .container.search {
    /* 진희 수정 230130 */
    /* padding-top: 120px; */
    padding-top: 85px;
    /* padding-bottom: 50px; */
  }

  /* 진희 수정 230130 */
  .container.search .cont-feed {
    padding-top: 0;
  }

  /* 진희 수정 230130 */
  .container.search .cont-feed .list {
    padding-bottom: 0;
  }
}

.tags a {
  font-size: 0.75rem;
  color: #3179cb;
  line-height: 1.3em;
  padding-right: 3px;
}

.link {
  color: #1a78c9;
}

.info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  min-height: 31px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* 0112 */
.info .user-name {
  width: 100%;
  display: block;
  font-size: 13px;
  line-height: 1em;
  font-weight: 500;
  color: var(--text-defaultBlackColor);

  display: flex;
  align-items: center;
}

.info .user-name a {
  display: inline-block;
  max-width: 100%;
  line-height: 1.4em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info .location {
  width: 100%;
  display: block;
  font-size: 11px;
  line-height: 1.3em;
  font-weight: 300;
  color: #797979;
}

.info .location a {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* 0107 미디어 쿼리 수정*/

@media screen and (min-width: 481px) and (max-width: 620px) {
  .info-ct {
    max-width: calc(100% - 100px) !important;
  }
}

@media screen and (max-width: 620px) {
  .item-header-wrap .item-header .distance {
    flex: 0 1 auto;
  }

  .item-header-wrap .item-header .time {
    width: 48px;
  }

  .info-ct .info .location {
    width: 100% !important;
  }
}

.hashtag {
  color: #3179cb;
}

/*--------------------------------------
    loader spinner
  ---------------------------------------*/
.feed-spinner {
  margin-top: 20px;
  padding: 20px 0;
  text-align: center;
}

.feed-spinner > span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--category-allColor);
  margin: 3px;
}

.feed-spinner > span:nth-of-type(1) {
  -webkit-animation: spinAni 1.3s 0.3s infinite ease-in;
  animation: spinAni 1.3s 0.3s infinite ease-in;
}

.feed-spinner > span:nth-of-type(2) {
  -webkit-animation: spinAni 1.3s 0.6s infinite ease-in;
  animation: spinAni 1.3s 0.6s infinite ease-in;
}

.feed-spinner > span:nth-of-type(3) {
  -webkit-animation: spinAni 1.3s 0.9s infinite ease-in;
  animation: spinAni 1.3s 0.9s infinite ease-in;
}

/*--------------------------------------
    img
  ---------------------------------------*/
video.hor,
img.hor {
  width: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

video.ver {
  width: 100%;
  height: -webkit-fill-available;
}

img.ver {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.profile.item-menu.active {
  width: 150px;
  top: 50%;
}

.profile.item-menu.active ul li {
  padding: 10px;
}

.profile.item-menu.active ul li:not(:nth-child(2)) {
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 481px) {
  .pop-menu.item-menu.active.comment {
    top: unset !important;
    left: unset !important;
  }
}

@media screen and (max-width: 480px) {
  .profile.item-menu.active {
    width: 100%;
    top: 0;
    position: fixed;
  }

  .profile.item-menu.active ul li {
    padding: 0;
    border-bottom: none !important;
  }

  .item-menu.active {
    width: 100%;
    height: calc(100% - 50px); /* 신희 수정 */

    border-radius: 0;
    /* 0614김경민 */
    /* position: fixed !important;
    left: 0 !important;
    top: 0 !important; */
    position: absolute;
    top: 0;
    left: 0;

    z-index: 101;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.25);
  }

  /*진희 작성 0704 1:1 메시지에서 더보기 옵션 배경 처리 */
  .pop-menu.message.active {
    top: 0 !important;
  }

  .item-menu.message.active {
    height: 100vh;
  }

  /*진희 작성 0704 1:1 메시지에서 더보기 옵션 배경 처리 */

  .item-menu.active ul,
  .modal-detail .item-menu.active ul {
    width: calc(100% - 28px);
    border-radius: 10px;
    position: relative;
    left: 14px;
    bottom: -68%;

    /* 0614김경민 */
    /* position: absolute;
    left: 14px;*/
    /* ptr library 삽입 이슈로 btm값 수정 진희 */
    /* bottom: 330px;
    padding: 0;
    border: none;
    transition: 0.2s; */
  }

  .item-menu.active ul {
    bottom: -35%;
    overflow-y: scroll;
  }

  .comments .item-menu.active ul {
    bottom: -80%;
    /* 0614김경민 */
    /* position: absolute;
    left: 14px;*/
    /* ptr library 삽입 이슈로 btm값 수정 진희 */
    /* bottom: 330px;
    padding: 0;
    transition: 0.2s; */
    border: none;
  }

  .item-menu.active ul li {
    border-top: solid 1px #dcdcdc;
  }

  .item-menu.active ul li button,
  .item-menu.active ul li a {
    height: 45px !important;
    text-align: center;
    color: var(--text-defaultBlackColor);
    font-size: 0.835em;
    letter-spacing: -0.03em;
  }

  .item-menu.active ul li:first-child {
    border-top: none;
  }

  .profile.item-menu.active ul li:last-child {
    position: static;
    border-radius: 0;
    border-top: 1px solid #dcdcdc;
  }

  .item-menu.active ul li:last-child {
    border-top: none;
    position: absolute;
    /* border-radius: 10px; */

    /* bottom: -54px; */
    border-top: 1px solid #dcdcdc;
    width: 100%;
    background: #fff;

    border-bottom: none;
    overflow: hidden;
  }
}

.header-wrap {
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: solid 1px #dcdcdc;
  /* 0614김경민 */
  /* position: fixed;
  left: 0;
  top: 0; */
  z-index: 9;
}

header {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 18px;
}

header .logo {
  display: inline-block;
  /* width: 160px; */
  width: 120px;
  margin-right: 15px;
  position: absolute;
  left: 0;
  top: 18px;
}

header .logo img {
  display: block;
  width: 100%;
}

header .search-ct {
  display: block;
  width: 550px;
  height: 45px;
  border: solid 1px #4075ad;
  border-radius: 2px;
  position: relative;
  margin: 0 auto;
}

header .search-ct .top-search {
  width: 100%;
  height: 100%;
  position: relative;
}

header .search-ct .top-search input {
  width: 100%;
  height: 43px;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 16px;
  border: none;
  color: #4e4e4e;
  font-size: 0.813rem;
}

header .search-ct .top-search .search-btn {
  width: 50px;
  height: 43px;
  position: absolute;
  right: 1px;
  top: 0;
  z-index: 2;
  border: none;
  background: url("../imgs/icons/search.svg") no-repeat;
  background-size: 22px;
  background-color: #fff;
  background-position: center center;
}

header .top-menu {
  width: auto;
  height: 32px;
  min-width: 140px;
  position: absolute;
  right: 0;
  top: 20px;
  margin-top: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

header .top-menu > button,
header .top-menu > a {
  border: none;
  background: transparent;
  margin: 0;
  font-size: 0.75rem;
  display: inline-block;
}

header .top-menu .btn-signin {
  width: 30px;
  height: 30px;
  background: url("../imgs/icons/login.svg") no-repeat;
  background-size: 19px;
  background-position: center center;
  margin-right: 8px;
}

header .top-menu .btn-signup {
  width: 30px;
  height: 30px;
  background: url("../imgs/icons/m_my.svg") no-repeat;
  background-size: 18px;
  background-position: center center;
}

header .top-menu .sign-pop {
  width: 100%;
  height: 30px;
  border: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 14px;
  position: fixed;
  left: 0;
  bottom: 48px;
  background: #7cc5d8;
  color: #fff !important;
  font-size: 13.5px;
  z-index: 100;
  display: none;
}

header .top-menu .sign-pop i {
  margin-left: 10px;
  font-size: 0.75rem;
  position: relative;
  top: 2px;
}

header .top-menu .menu-dm {
  width: 26px;
  height: 32px;
  background: url("../imgs/icons/dm_line.svg") no-repeat;
  background-position: center center;
  background-size: 23px;
  margin-right: 20px;
  position: relative;
}

header .top-menu .menu-dm::after {
  display: none !important;
}

header .top-menu .menu-dm.active::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #cf514a;
  position: absolute;
  left: -5px;
  top: 0;
}

header .top-menu .alarm-box {
  position: relative;
  width: 28px;
  height: 32px;
  margin-right: 10px;
}

header .top-menu .alarm-box .alarm-header {
  display: none;
}

header .top-menu .alarm-box .menu-alarm {
  border: none;
  margin: 0;
  padding: 0;
  width: 28px;
  height: 30px;
  background: url("../imgs/icons/alarm.svg") no-repeat;
  background-size: 20px;
  background-position: center center;
  margin-top: 2px;
}

header .top-menu .alarm-box .menu-alarm.active::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #cf514a;
  position: absolute;
  left: 0;
  top: 2px;
}

header .top-menu .btn-search {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("../imgs/icons/search_bk.svg") no-repeat;
  background-position: center center;
  background-size: 22px;
  margin-right: 12px;
}

header .top-menu .my-box {
  width: auto;
}

header .top-menu .my-box .my-account {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  height: 32px;
}

/* 0107 */
header .top-menu .my-box .my-account .thumb {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
}

header .top-menu .my-box .my-account .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

header .top-menu .my-box .my-menu {
  position: absolute;
  top: 50px;
  right: 0;
  display: none;
  z-index: 10;
}

.keyword-wrap {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50px;
  border: solid 1px #dcdcdc;
  border-radius: 3px;
  padding: 19px 0;
  background: #fff;
  display: none;
}

.keyword-wrap.on {
  display: block;
  z-index: 11;
  /* z-index: 5; */
}

.keyword-wrap .keywords-ct {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.keyword-wrap .keywords-ct .k-panel {
  width: 50%;
  padding-left: 20px;
  position: relative;
}

.keyword-wrap .keywords-ct .k-panel strong {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 15px;
  color: #1e2530;
}

.keyword-wrap .keywords-ct .k-panel .delete-all {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
  background: transparent;
  color: #a3a3a3;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: -0.03em;
}

.keyword-wrap .keywords-ct .k-panel ul li {
  margin-bottom: 5px;
}

.keyword-wrap .keywords-ct .k-panel ul li:last-child {
  margin-bottom: 0;
}

.keyword-wrap .keywords-ct .k-panel ul li a {
  padding-right: 20px;
  position: relative;
  font-size: 13px;
  line-height: 20px;
  color: #1e2530;
}

.keyword-wrap .keywords-ct .k-panel ul li a span {
  color: var(--text-linkColor);
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.keyword-wrap .keywords-ct .k-panel ul li .delete {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  color: #a4a4a4;
  font-size: 12px;
  background: transparent;
}

.keyword-wrap .keywords-ct .k-panel.reco {
  border-right: solid 1px #dcdcdc;
}

@media screen and (max-width: 937px) {
  header .search-ct {
    max-width: calc(100% - 326px);
    margin-left: 175px;
  }
}

@media screen and (max-width: 768px) {
  .keyword-wrap {
    padding: 0 19px;
  }

  .keyword-wrap .keywords-ct {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .keyword-wrap .keywords-ct .k-panel {
    width: 100%;
    padding-left: 0;
    position: relative;
    padding: 19px 0;
  }

  .keyword-wrap .keywords-ct .k-panel strong {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 15px;
    color: #1e2530;
  }

  .keyword-wrap .keywords-ct .k-panel .delete-all {
    right: 0;
  }

  .keyword-wrap .keywords-ct .k-panel ul li {
    margin-bottom: 5px;
  }

  .keyword-wrap .keywords-ct .k-panel ul li:last-child {
    margin-bottom: 0;
  }

  .keyword-wrap .keywords-ct .k-panel ul li a {
    padding-right: 20px;
    position: relative;
    font-size: 13px;
    line-height: 20px;
    color: #1e2530;
  }

  .keyword-wrap .keywords-ct .k-panel ul li a span {
    color: var(--text-linkColor);
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .keyword-wrap .keywords-ct .k-panel ul li .delete {
    right: 0px;
  }

  .keyword-wrap .keywords-ct .k-panel.reco {
    border-right: none;
    border-bottom: solid 1px #dcdcdc;
  }
}

@media screen and (max-width: 480px) {
  .sign-pop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .nav-bar {
    width: 100%;
    /* 0614김경민 */
    height: 50px;
    /* 0324 height 주석처리 bottom에 1px정도 틈이 보임 */
    /* height: 48px; */
    /* 0324 height 주석처리 bottom에 1px정도 틈이 보임 */
    /*62*/
    /*padding-bottom: 5px;*/
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    /* z-index: 10; */

    /* 230120 GNB가 항상 보이도록 수정 z index 100 */
    z-index: 100;

    border-top: solid 1px #dcdcdc;
  }

  .nav-bar nav {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-bar a {
    width: 20%;
    height: 100%;
    text-align: center;
    padding-top: 32px;
  }

  .nav-bar a img {
    display: block;
  }

  .nav-bar a.menu-home {
    background: url("../imgs/icons/m_home.svg") no-repeat;
    background-size: 17.5px;
    /*19.5*/
    background-position: center 7px;
    opacity: 0.5;
  }

  .nav-bar a.menu-home.active {
    background: url("../imgs/icons/m_home_on.svg") no-repeat;
    background-size: 17.5px;
    background-position: center 7px;
    opacity: 1;
  }

  .nav-bar a.menu-pin {
    background: url("../imgs/icons/m_map.svg") no-repeat;
    background-size: 18px;
    /*24px*/
    background-position: center 5px;
    opacity: 0.5;
  }

  .nav-bar a.menu-pin.active {
    background: url("../imgs/icons/m_map_on.svg") no-repeat;
    background-size: 18px;
    background-position: center 5px;
    opacity: 1;
  }

  .nav-bar a.menu-add {
    background: url("../imgs/icons/m_write.svg") no-repeat;
    background-size: 35px;
    /*21.5*/
    background-position: center 6px;
  }

  .nav-bar a.menu-add.active {
    background: url("../imgs/icons/m_write.svg") no-repeat;
    background-size: 35px;
    background-position: center 6px;
  }

  .nav-bar a.menu-dm {
    background: url("../imgs/icons/m_dm.svg") no-repeat;
    background-size: 20px;
    /*22*/
    background-position: center 7px;
    opacity: 0.5;
  }

  .nav-bar a.menu-dm.active {
    background: url("../imgs/icons/m_dm_on.svg") no-repeat;
    background-size: 20px;
    background-position: center 7px;
    opacity: 1;
  }

  .nav-bar a.menu-dm::after {
    display: none !important;
  }

  .nav-bar a.menu-dm.dot::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #cf514a;
    position: absolute;
    top: 4px;
  }

  .nav-bar a.menu-setting {
    background: url("../imgs/icons/m_my.svg") no-repeat;
    background-size: 17px;
    background-position: center 9px;
  }

  .nav-bar a.menu-setting.active {
    background: url("../imgs/icons/m_my_on.svg") no-repeat;
    background-size: 17px;
    background-position: center 9px;
  }

  /*  더보기수정 */
  .nav-bar a.menu-more {
    background: url("../imgs/icons/m_cube.svg") no-repeat;
    background-size: 20px;
    background-position: center 5px;
    opacity: 0.5;
  }

  .nav-bar a.menu-more.active {
    background: url("../imgs/icons/m_cube_on.svg") no-repeat;
    background-size: 20px;
    background-position: center 5px;
    opacity: 1;
  }

  /*
  .nav-bar a.menu-more {
    background: url("../imgs/icons/nav_icon_off.svg") no-repeat;
    background-size: 35px;
    background-position: center 0px;
  }

  .nav-bar a.menu-more.active {
    background: url("../imgs/icons/nav_icon_on.svg") no-repeat;
    background-size: 35px;
    background-position: center 0px;
  }
  */

  .nav-bar a span {
    font-size: 0.5rem;
    display: inline-block;
    vertical-align: top;
  }

  .header-wrap {
    padding: 0 12px;
    border-bottom: none;
    /* 0614김경민 */
    /* position: relative;
    height: 48px;
    */
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;

    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .header-wrap header {
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 3px;

    /* mobile header border bottom 추가 진희 수정 */
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdcdc;
  }

  .header-wrap header .logo {
    position: relative;
    left: 0;
    top: 0;
    width: 106px;
  }

  .header-wrap header .top-menu {
    min-width: 0;
    position: relative;
    top: 0;
    right: 0;
    z-index: 9;
    margin-top: 0;

    align-items: center;
  }

  .header-wrap header .top-menu .menu-dm.active::before {
    bottom: -5px;
  }

  .header-wrap header .top-menu .alarm-box {
    position: relative;
    z-index: 99;
    margin-right: 5px;
  }

  .header-wrap header .top-menu .alarm-box .menu-alarm.active::before {
    content: "";
    bottom: -5px;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct {
    width: 100%;
    height: 100%;
    min-height: 700px;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #fff;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct .alarm-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 55px;
    border-bottom: solid 1px #dcdcdc;
    position: relative;
    padding: 0;
    padding-left: 4px;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct .alarm-header strong {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: var(--text-defaultBlackColor);
    font-size: 1rem;
    letter-spacing: -0.03em;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct .alarm-lists {
    max-height: calc(100vh - 102px);
    overflow-y: auto;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct .list-today strong,
  .header-wrap header .top-menu .alarm-box .alarm-ct .list-prev strong {
    padding: 0 14px;
  }

  .header-wrap header .top-menu .alarm-box .alarm-ct .list-today .alarm-item,
  .header-wrap header .top-menu .alarm-box .alarm-ct .list-prev .alarm-item {
    padding: 5px 14px;
  }

  .header-wrap header .top-menu .my-box {
    margin: 0;
    position: relative;
    top: 0;
    height: 30px;
  }

  .header-wrap header .top-menu .my-box .my-menu {
    left: unset;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    right: 0;
  }

  header .top-menu .my-box .my-menu {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 123;
  }

  header .top-menu .my-box .my-menu ul {
    width: calc(100% - 28px);
    border-radius: 10px;
    position: absolute;
    left: 14px;
    bottom: 140px;
    padding: 0;
    border: none;
  }

  header .top-menu .my-box .my-menu ul li {
    border-top: solid 1px #dcdcdc;
  }

  header .top-menu .my-box .my-menu ul li:first-child {
    border-top: none;
  }

  header .top-menu .my-box .my-menu ul li:last-child {
    border-top: none;
    position: absolute;
    bottom: -54px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    border-bottom: none;
  }

  header .top-menu .my-box .my-menu ul li button,
  header .top-menu .my-box .my-menu ul li a {
    display: block;
    width: 100%;
    height: 45px !important;
    text-align: center;
    color: var(--text-defaultBlackColor);
    font-size: 0.835rem;
    line-height: 45px;
    letter-spacing: -0.03em;
    white-space: nowrap;
    margin: 0;
    padding: 0 10px;
    background: transparent;
    border: none;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
}

.mobile-header {
  width: 100%;
  background: #fff;
  border-bottom: solid 1px #dcdcdc;
  height: 55px;
  padding: 0 14px;
  position: relative;
  display: none;
}

.mobile-header strong {
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
  letter-spacing: -0.03em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 480px) {
  .mobile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.btn-addFeed {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  display: block;
  margin-bottom: 10px;
  text-align: center;
  background: url("../imgs/icons/add_wh.png") no-repeat;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: #15a3d3;
  background-position: center center;
}

/* 0104 카메라 버튼추가 */
.btn-camera,
.btn-top {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  display: block;
  margin-bottom: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

.btn-top {
  background: url("../imgs/icons/img_pre.svg") no-repeat;
  background-color: #fff;
  background-position: center center;
  background-size: 24px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn-camera {
  background: url("../imgs/icons/quick_icon.svg") no-repeat;
  background-color: #fff;
  background-position: center center;
  /* background-size: 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* 0104 */
.btn-dm {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  display: block;
  margin-bottom: 10px;
  text-align: center;
  background: url("../imgs/icons/dm_line_wh.svg") no-repeat;
  background-color: #aaaaaa;
  background-position: center center;
  background-size: 24px;
}

.btn-tools {
  width: auto;
  height: auto;
  position: fixed;
  right: 50%;
  bottom: 10%;
  -webkit-transform: translateX(670px);
  transform: translateX(670px);
  z-index: 5;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  pointer-events: none;
}

.btn-tools.active {
  opacity: 1;
  pointer-events: fill;
}

@media screen and (max-width: 1400px) {
  .btn-tools {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    right: 30px;
  }
}

@media screen and (max-width: 480px) {
  .btn-tools {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    right: 10px;
    bottom: 75px;
  }

  /* 0104 카메라 버튼추가 */
  .btn-camera,
  .btn-top {
    width: 45px;
    height: 45px;
  }

  /* 0104 */
}

.write-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #f1f1f1;
}

.write-item:last-of-type {
  border-bottom: none;
}

.write-item .text-container {
  text-align: center;
  margin: 50px 0;
}

.write-item .text-container .title {
  font-size: 0.825rem;
  margin-bottom: 10px;
  line-height: 1.1rem;
  color: var(--text-defaultBlackColor);
}

.write-item .text-container .desc {
  font-size: 0.75rem;
  color: var(--text-defaultGrayColor);
}

.input-group {
  position: relative;
}

.input-group.search {
  height: 45px;
}

.input-group.search input {
  border: solid 1px #dddddd;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  /*padding-right: 46px;
  padding-left: 20px;*/
  padding: 20px 0;
}

.input-group.search .btn-search {
  width: 42px;
  height: 41px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 2;
  border: none;
  background: url("../imgs/icons/search.svg") no-repeat;
  background-size: 17px;
  background-color: #fff;
  background-position: center center;
}

.input-group {
  position: relative;
}

.input-group.search {
  height: 50px;
}

.input-group.search input {
  border: solid 1px #dddddd;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding-right: 45px;
  padding-left: 20px;
}

.input-group.search .btn-search {
  width: 42px;
  height: 46px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 2;
  border: none;
  background: url("../imgs/icons/search.svg") no-repeat;
  background-size: 17px;
  background-color: #fff;
  background-position: center center;
}

.input-group.type .type-check + label {
  width: 23%;
  max-width: 100px;
  min-width: 0;
  margin: 5px 0;
  margin-right: 10px;
}

.input-group.search {
  height: 50px;
}

.input-group.search .btn-search {
  width: 42px;
  height: 46px;
}

.input-group.check {
  padding-top: 10px;
  padding-bottom: 20px;
}

.input-group.pswd {
  margin-bottom: 10px;
}

.input-group.center {
  text-align: center;
}

.input-group.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-group .input-group-btn {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100px;
  height: 45px;
  font-size: 0.7rem;
  color: var(--text-linkColor);
  text-align: center;
  background: transparent;
  border: none;
  /* word-break: keep-all; */
}

.input-group .invalid {
  margin: 8px 0;
}

.input-group .modal-txt {
  font-size: 0.813rem;
  color: #4e4e4e;
  letter-spacing: -0.03rem;
}

.input-group .modal-txt-s {
  font-size: 11px;
  color: #4e4e4e;
  letter-spacing: -0.03rem;
}

.invalid {
  display: block;
  color: #cf514a;
  font-size: 12px;
  margin: 8px 0;
}

.write-btn .invalid {
  margin-bottom: 8px;
}

.input-title {
  margin-bottom: 18px;
}

.input-title .ttl {
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--text-defaultBlackColor);
  width: auto;
  margin-right: 10px;
}

.input-title .desc {
  color: #666666;
  font-size: 0.75rem;
  display: inline-block;
}

.input-control {
  width: 100%;
  height: 45px;
  border: solid 1px #dddddd;
  border-radius: 3px;
  padding-left: 13px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}

.input-control:focus {
  border: solid 1px var(--default-deepBlueColor);
  outline: none;
  font-size: 14px !important;
}

/*명삼 추가*/
.input-control::placeholder {
  font-size: 75%;
}

.toggle-check {
  display: none;
}

.toggle-check + label {
  display: inline-block;
  width: 50px;
  height: 28px;
  border-radius: 14px;
  background: #dcdcdc;
  position: relative;
  cursor: pointer;
}

.toggle-check + label::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}

.toggle-check:checked + label {
  background: var(--text-linkColor);
}

.toggle-check:checked + label::after {
  left: 24px;
}

@media screen and (max-width: 480px) {
  .write-item {
    /* padding: 20px 16px; */
    /* 진희 수정 0407*/
    /* padding 사이즈 반응형으로 스타일 적용 */
    /* padding: 4.3% 3.35%; */
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background: #fff;
  }

  .modal .modal-body .write-btn {
    /* 0407 진희 수정 */
    /* 반응형으로 사이즈 적용 */
    padding: 10px 12px;
    width: 100%;
    /*김경민 */
    /* position: fixed; */
    bottom: 0px;
    z-index: 2;
  }

  /* 0407 진희 수정 */
  /*  */
  .input-title {
    margin-bottom: 3.5%;
  }

  .input-group.type .type-check + label {
    width: 22%;
    max-width: 100px;
    min-width: 0;
    margin: 5px 0;
    margin-right: 2%;
  }

  .input-group.search {
    height: 50px;
  }

  .input-group.search .btn-search {
    width: 42px;
    height: 46px;
  }
}

.search-dropdown {
  position: absolute;
  left: 0;
  border: solid 1px #e2e2e2;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  top: 100%;
  width: 100%;
  height: auto;
  /* max-height: 272px; */
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  border-top: none;
  background: #fff;
  padding: 6px 2px;
  display: none;
}

.search-dropdown ul {
  width: 100%;
  height: auto;
}

.search-dropdown ul li {
  width: 100%;
  padding-bottom: 1px;
  border-bottom: 1px solid #e2e2e2;
}

.search-dropdown ul li:last-child {
  border-bottom: none;
}

.search-dropdown ul li a {
  display: block;
  line-height: 26px;
}

.search-dropdown ul li a,
.search-dropdown ul li button {
  width: 100%;
  min-height: 26px;
  border: none;
  margin: 0;
  padding: 0;
  background: #fff;
  font-size: 0.813rem;
  color: #4e4e4e;
  text-align: left;
  padding: 0 6px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* .search-dropdown ul li a:hover,
.search-dropdown ul li button:hover {
  background: #edf2f6;
} */

.search-dropdown.open {
  display: block;
}

.section-tab .tab-btns .tab-swiper {
  display: inline-block;
  width: auto;
  max-width: 100%;
}

.section-tab .tab-btns .tab-swiper .swiper-slide {
  display: inline-block;
  width: auto;
}

.list-container .section-tab .tab-btns .tab-swiper {
  display: block;
  width: 100%;
}

.list-container .section-tab .tab-btns .tab-swiper .swiper-slide {
  display: inline-block;
  width: calc(20% - 8px);
}

.list-container .section-tab .tab-btns .btn {
  width: 100%;
  height: 27px;
  min-width: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1;
}

@media screen and (max-width: 480px) {
  .section-tab .tab-btns .tab-swiper {
    display: block;
    width: 100%;
  }

  .section-tab .tab-btns .tab-swiper .swiper-slide {
    display: inline-block;
    width: calc(20% - 8px);
  }
}

.splash-wrap {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url("../imgs/splash/splash_bg.png") no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 999;
}

.splash-wrap .sub {
  font-size: 5vw;
  color: #fff;
}

.splash-wrap .title {
  display: block;
  width: 40vw;
  margin-top: 3vh;
  padding-bottom: 10vh;
}

.splash-wrap .title img {
  max-width: 100%;
}

.splash-wrap .domain {
  display: block;
  width: 20vw;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 10vh;
}

.splash-wrap.fadeout {
  overflow: hidden;
  -webkit-animation: fadeOut 0.25s ease-in;
  animation: fadeOut 0.25s ease-in;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.pop-menu {
  min-width: 82px;
  position: absolute;
  /* 0614김경민 */
  top: 25px;
  right: 10px;

  display: none;
  z-index: 10;
  opacity: 0;
}

.pop-menu > ul {
  border: solid 1px #dcdcdc;
  border-radius: 3px;
  padding: 6px 0;
  background: #fff;
}

.pop-menu > ul > li a,
.pop-menu > ul > li button {
  display: block;
  width: 100%;
  line-height: 20px;
  color: var(--text-defaultBlackColor);
  font-size: 12.5px;
  letter-spacing: -0.03em;
  white-space: nowrap;
  margin: 0;
  padding: 0 10px;
  background: transparent;
  border: none;
  text-align: left;
  -webkit-transition: 0.2s;
  transition: 0.2s;

  background-color: #fff;
}

.pop-menu > ul > li a:hover,
.pop-menu > ul > li button:hover {
  color: var(--hover-color);
}

.pop-menu.active {
  display: block !important;
  -webkit-animation: fadeIn 0.15s ease-out;
  animation: fadeIn 0.15s ease-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  z-index: 21;
}

.pop-menu .btns {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -17px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pop-menu .btns button.alert-ok {
  width: 100%;
  height: 45px;
  font-size: 13px;
  font-weight: 300;
  border: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-top: solid 1px #dcdcdc;
  color: #666;
}

.pop-menu .btns button {
  width: 50%;
  height: 45px;
  font-size: 13px;
  font-weight: 300;
  border: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-top: solid 1px #dcdcdc;
  display: block;
}

.pop-menu .btns button:nth-child(2) {
  border-left: solid 1px #dcdcdc;
}

.pop-menu .btns button.cancel {
  color: #666;
}

.pop-menu .btns button.confirm {
  color: var(--default-deepBlueColor);
}

.pop-menu .btns button.delete {
  color: #e82a2a;
}

/* 0110 팝업 위치 position fixed 수정 */
.pop-menu.item-pop {
  width: auto;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border: solid 1px #dbdbdb;
  padding: 0 20px;
  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0px rgb(0, 0, 0, 0.2);
}

.comments .pop-menu.item-pop {
  position: fixed;
  top: 40%;

  /* web에서 댓글 창으로 옮기려면 media쿼리 left 80%로 조정하면된다 */
  /* @media screen and (min-width: 481px) {
    left: 80%;
  } */
}

/* 김경민 수정 : 공유하기 레이어팝업 위치 수정 mobile */
@media screen and (max-width: 480px) {
  /* .pop-menu.item-pop {
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  } */
}

.pop-menu.item-pop.report {
  width: 270px !important;
}

.pop-menu.item-pop.report ul {
  margin-bottom: 20px;
}

.pop-menu.item-pop.report ul li {
  margin-bottom: 8px;
}

.pop-menu.item-pop.share {
  width: 252px;
}

.pop-menu.item-pop.share .pop-body ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.pop-menu.item-pop.share .pop-body ul li {
  width: 45px;
  text-align: center;
}

.pop-menu.item-pop.share .pop-body ul li .btn {
  width: 45px;
  height: 45px;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.pop-menu.item-pop.share .pop-body ul li .btn.copy {
  background: url("../imgs/icons/link.svg") no-repeat;
  background-color: #e3e9ed;
  background-size: 21px 21px;
  background-position: center center;
}

.pop-menu.item-pop.share .pop-body ul li .btn.email {
  background: url("../imgs/icons/email.svg") no-repeat;
  background-color: #cb2027;
  background-size: 24px 18px;
  background-position: center center;
}

/* 김경민 : 카카오 공유 추가 (임시 - 디자인 요청 필요) */
.pop-menu.item-pop.share .pop-body ul li .btn.kakao {
  background: url("../imgs/icons/sns_k.svg") no-repeat;
  background-size: 50px 50px;
  background-position: center center;
}

.pop-menu.item-pop.share .pop-body ul li .btn.facebook {
  background: url("../imgs/icons/facebook.svg") no-repeat;
  background-color: #3b5998;
  background-size: 13px 22px;
  background-position: center center;
}

.pop-menu.item-pop.share .pop-body ul li .btn.twitter {
  background: url("../imgs/icons/twitter.svg") no-repeat;
  background-color: #55acee;
  background-size: 24px 20px;
  background-position: center center;
}

.pop-menu.item-pop.share .pop-body ul li span {
  display: inline-block;
  margin-top: 10px;
  font-size: 12px;
  color: #666666;
  letter-spacing: -0.03em;
}

.pop-menu.item-pop.delete {
  width: 280px;
  text-align: center;
  padding-top: 20px;
  transform: translateX(-50%);
}

.pop-menu.item-pop.delete .pop-header strong {
  width: 100%;
  line-height: 1.4em;
}

.pop-menu.item-pop.delete .pop-body {
  padding-top: 10px;
}

.pop-menu.item-pop.delete .pop-body .txt {
  color: #666666;
  font-size: 13px;
  line-height: 1.4em;
  word-break: keep-all;
}

.pop-menu.item-pop.delete .pop-body .btns {
  margin-top: 20px;
}

.pop-menu.item-pop .pop-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.pop-menu.item-pop .pop-header strong {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-defaultBlackColor);
}

.pop-menu.item-pop .pop-header .close {
  border: none;
  margin: 0;
  padding: 0;
  font-weight: bold;
  padding: 8px 10px;
  background: transparent;
  position: relative;
  top: 8px;
  left: 10px;
  color: #333;
}

.pop-menu.item-pop .pop-body {
  padding: 17px 0;
}

.item-toast {
  width: 252px;
  height: auto;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 4;
  padding: 5px 10px;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  display: none;
  opacity: 0;
}

.item-toast.on {
  display: inline-block;
  -webkit-animation: fadeIn 0.25s ease-out;
  animation: fadeIn 0.25s ease-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 480px) {
  .item-toast {
    width: calc(100% - 28px);
    height: auto;
    padding: 20px 8px;
    min-height: 60px;
    /* 명삼 22.06.30 */
    /* position: fixed; */
    display: inline-block;
    top: unset;
    bottom: 130px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    /* background: #d8e9f5;
    border: solid 1px #3d93cf; */
    text-align: center;
    z-index: 4;
    font-size: 14px;
    /* color: var(--default-deepBlueColor); */
    -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
    line-height: 1.5em;
    display: none;
    opacity: 0;
  }

  .item-toast.on {
    display: inline-block;
    -webkit-animation: fadeIn 0.25s ease-out;
    animation: fadeIn 0.25s ease-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .pop-menu.item-pop.share,
  .pop-menu.item-pop.delete,
  .pop-menu.item-pop.report {
    width: 280px !important;
  }
}

.alarm-ct {
  width: 334px;
  height: auto;
  position: absolute;
  top: 45px;
  right: -71px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.0975);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.0975);
  display: none;
}

.alarm-ct.active {
  display: block;
}

.alarm-ct::after {
  content: "";
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  right: 82px;
  background: #fff;
  -webkit-box-shadow: -3px -4px 6px 0px rgba(0, 0, 0, 0.0775);
  box-shadow: -3px -4px 6px 0px rgba(0, 0, 0, 0.0775);
}

.alarm-ct .alarm-lists {
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
}

.alarm-ct .list-today,
.alarm-ct .list-prev {
  padding-top: 20px;
  padding-bottom: 15px;
}

.alarm-ct .list-today strong,
.alarm-ct .list-prev strong {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  color: var(--text-defaultBlackColor);
  padding: 0 20px;
}

.alarm-ct .list-today .alarm-item,
.alarm-ct .list-prev .alarm-item {
  padding: 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.alarm-ct .list-today .alarm-item .thumb,
.alarm-ct .list-prev .alarm-item .thumb {
  width: 36px;
  height: 36px;
  margin-right: 18px;
  position: relative;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.alarm-ct .list-today .alarm-item .text,
.alarm-ct .list-prev .alarm-item .text {
  font-size: 12px;
  color: var(--text-defaultBlackColor);
  line-height: 1.5em;
}

.alarm-ct .list-today .alarm-item .text > span,
.alarm-ct .list-prev .alarm-item .text > span {
  padding-right: 16px;
}

.alarm-ct .list-today .alarm-item .text .time,
.alarm-ct .list-prev .alarm-item .text .time {
  color: #999999;
  padding-right: 0;
  white-space: nowrap;
}

.alarm-ct .list-today .alarm-item .text .link,
.alarm-ct .list-prev .alarm-item .text .link {
  padding-right: 8px;
}

.alarm-ct .list-today .alarm-item:hover,
.alarm-ct .list-prev .alarm-item:hover {
  background: #f5f5f5;
}

.alarm-ct .list-prev {
  border-top: solid 1px #dcdcdc;
}

/*계정 페이지 획득 뱃지 팝업*/
/*feed 게시물*/
.cont-feed {
  width: calc(100% + 20px);
  margin-left: -10px;
  text-align: center;
}

.cont-feed .list {
  width: auto;
  max-width: 100%;
  display: inline-block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  text-align: left;
}

/* 0614김경민 */
/* .cont-feed .list::after {
  content: "";
  display: block;
  clear: both;
} */

.cont-feed .list .item {
  width: 285px;
  height: 455px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;

  /* margin: 0 8.5px; */
  margin: 0 10px;
  margin-bottom: 40px;
  text-align: left;
  vertical-align: top;
}

/*feed header*/
.item-header-wrap {
  border: solid 1px #dcdcdc;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  background: #ffff;
  padding: 8px 10px 8px 8px;
  position: relative;
  z-index: 3;
}

.item-header-wrap .item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
}

.item-header-wrap .item-header .thumb {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-right: 5px;

  /* 0107 */
  flex-shrink: 0;
}

.item-header-wrap .item-header .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.item-header-wrap .item-header .info-ct {
  max-width: calc(100% - 85px);
  padding-right: 3px;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.item-header-wrap .item-header .info-ct .location a {
  font-size: 11.5px;
  line-height: 1.4em;
}

.item-header-wrap .item-header .time {
  /* 24.07.08 codelua 시간을 누르면 YYMMDD HH:MM 를 표현하기 위해  fit-content로 변경 기존 값 */
  /* width: 48px;   */
  width: fit-content;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 11px;
  line-height: 1.55em;
  font-weight: 300;
  color: #797979;
  text-align: right;
  letter-spacing: -0.3px;
  cursor: pointer;
  /* 0107 */
  flex-shrink: 0;
}

/* 0110  거리 추가 진희 수정*/
.item-header-wrap .item-header .distance {
  width: 52px;
  font-size: 0.713rem;
  font-weight: 300;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* 0110 */
  line-height: 1.35em;
  margin-top: auto;
  color: #797979;
}

/* 0110 거리 추가 진희 수정*/

.item-header-wrap .item-header .menu-open {
  border: none;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -2px;
  top: -2px;
  background: url("../imgs/icons/dm_more.svg") no-repeat;
  background-position: center center;
  background-size: 2px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.item-header-wrap .item-header .item-menu {
  position: absolute;
  top: 25px;
  right: 0px;
}

/*feed photo*/
.item-body-wrap {
  position: relative;
  border-left: solid 1px #dcdcdc;
  border-right: solid 1px #dcdcdc;
}

.item-photo {
  width: 100%;
  /* height: 285px; */
  height: 300px;
  /* overflow: hidden; */
  position: relative;
}

.item-photo.pl::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("../imgs/icons/multi.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  text-align: center;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}

/*
.item-photo.vdo::after {
  content: "";
  position: absolute;
  width: 54px;
  height: 54px;
  background: url("../imgs/icons/play-button.svg") no-repeat;
  background-size: 100%;
  background-position: center center;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}
*/
.pht-slider {
  width: 100%;
  height: 300px;
}

/* .pht-slider .swiper-slide {
  overflow: hidden;
} */

.pht-slider .swiper-pagination {
  display: none;
}

.default-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 12px;
}

.default-img-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 12px;
  background-color: #89c8d5;
}

.default-img span {
  display: inline-block;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: -0.03em;
  /* word-break: keep-all; */
  color: #fff;
  text-align: center;
}

/*feed text*/
.item-info {
  position: relative;
  padding: 15px 12px;
  background: #fff;
  min-height: 90px;

  /* 1108 공감내역으로 수정함 */
  padding-bottom: 20px;
  /* 1108 */
}

.item-info .tags {
  margin-bottom: 10px;
}

.item-info .tags .tag {
  height: 17px;
  width: auto;
  min-width: 32px;
  padding: 0 5px;
  border: none;
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  line-height: 17px;
  border-radius: 3px;
  margin-right: 3px;
}

@media screen and (min-width: 481px) {
  .item-info .text {
    height: 49px;
  }
}

@media screen and (max-width: 480px) {
  .item-info .text {
    height: 60px;
    word-wrap: break-word;
    height: 100%;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-inline-box;
  }
}

.item-info .text-clamp {
  word-wrap: break-word;
  word-break: break-all;
  height: 100%;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-inline-box;
}
.item-info .text-clamp a {
  word-wrap: break-word;
  word-break: break-all;
}

.item-info .text {
  width: 100%;
  color: var(--text-defaultBlackColor);
  font-size: 13px;
  line-height: 1.2em;
  letter-spacing: -0.02em;
}

.item-info.active {
  height: auto;
}

.item-info .text p {
  width: 100%;

  /* height: 32px;
  word-wrap: break-word;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-inline-box; */
}

/* .item-info .text p.text-clamp {
  -webkit-line-clamp: 2;
} */

.item-info .text .hashtag {
  color: #3179cb;
}

/*feed bottom bar*/
.item-bar-wrap {
  height: 35px;
  padding: 7px 12px;
  padding-right: 3px;
  border-radius: 0 0 10px 10px;
  border: solid 1px #dcdcdc;
  background: #fff;
  position: relative;
}

.item-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
}

.likeHistory-section {
  height: 100%;
  z-index: 10;
}

.likeHistory-section .container .subscribe-mobile-popup {
  z-index: 10;
  margin-top: 28px;
  padding-top: 0;
  padding-bottom: 45px;
  overflow-y: scroll;
}

/* 1108 */

/* 1109 */

/* web */
.likeHistory-section .modal-header {
  display: block !important;
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
}

.likeHistory-section .modal-body {
  width: 450px;
  height: 550px;
}

.likeHistory-section .likeHistory__ct {
  height: 100%;
}

.likeHistory-section .likeHistory__list {
  display: flex;
  flex-direction: column;
  padding: 2px 15px;
  height: 100%;
  overflow-y: scroll;
}

.likeHistory-section .likeHistory__user {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.likeHistory-section .likeHistory__user .tab-subs-list-item__pic img,
.likeHistory-section .likeHistory__user .tab-subs-list-item__pic {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 50%;
}

.likeHistory__user .tab-subs-list-item__name {
  font-size: 14px;
}

.likeHistory__user .btn-edit {
  margin-left: auto !important;
  width: 65px !important;
  height: 30px !important;
  border-radius: 5px;
  font-size: 11px !important;
  font-weight: 300 !important;
}

/* web */

/* 1109 */
.item-bar .count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom .count {
  margin-right: -5px;
  display: flex;
  flex-wrap: wrap;
}
.bottom .count .btn {
  margin-right: 2.5px;
  height: 14px;
  width: 25px;
  height: 15px;
  background-position: center;
  border: 1px solid transparent;
}
.item-bar .count .btn {
  border: none;
  margin: 0;
  padding: 0;
  width: 27px;
  height: 22px;
}

/* 1109 */
.count .btn.up {
  background: url("../imgs/icons/up_black.svg") no-repeat;
  background-size: contain;
}

.count .btn.down {
  background: url("../imgs/icons/down_black.svg") no-repeat;
  background-size: contain;
}

.count .btn.active.up {
  background: url("../imgs/icons/up_black_solid_ver02.svg") no-repeat;
  background-size: contain;
}

.count .btn.active.down {
  background: url("../imgs/icons/down_black_solid_ver02.svg") no-repeat;
  background-size: contain;
}

.count .text {
  color: var(--text-defaultBlackColor);
  font-size: 14px;
  line-height: 14px;
  padding: 0 15px 0 5px;
}

.count .text.active {
  color: var(--text-defaultBlackColor);
  font-weight: bold;
}

.item-bar .tools ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
}

.item-bar .tools ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 32px;
  height: 16px;
  border-right: solid 1px #dcdcdc;
}

.item-bar .tools ul li:last-child {
  border-right: none;
}

/* 1109 */
.item-bar .tools ul .btn {
  border: none;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 25px;
}

.item-bar .tools ul .btn.share {
  background: url("../imgs/icons/share_black.svg") no-repeat;
  background-size: 18px;
  background-position: center center;
}

.item-bar .tools ul .btn.comments {
  /* height: 13px; */
  background: url("../imgs/icons/comment_black.svg") no-repeat;
  background-size: 18px;
  background-position: center center;
}

.item-bar .tools ul .btn.bookmark {
  background: url("../imgs/icons/bookmark_black.svg") no-repeat;
  background-size: 18px;
  background-position: center center;
  /* height: 14px; */
}

/* 1109 */

.item-bar .tools ul .btn.bookmark.active {
  background: url("../imgs/icons/bookmark_b_ac.svg") no-repeat;
  background-position: center center;
}

.item-bar .tools ul .c-count {
  padding-right: 5px;
  color: #5293c5;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  left: -3px;
  top: -1px;
}

/*------------------------------------------------------
  responsive
------------------------------------------------------*/
@media screen and (max-width: 1230px) {
  .cont-feed .list {
    max-width: 915px;
  }
}

@media screen and (max-width: 940px) {
  .cont-feed .list {
    max-width: 610px;
  }
}

@media screen and (max-width: 629px) {
  .cont-feed .list {
    max-width: 100%;
    text-align: center;
  }

  .cont-feed .list .item {
    width: calc(50% - 19px);
  }
}

@media screen and (max-width: 480px) {
  .cont-feed {
    width: 100%;
    height: 100%;
    margin-left: 0;
    padding-top: 35px;

    /* 0608 content list에 스크롤이 발생하게 만들기 위해서 삭제함 진희 수정 */
    /* 0614김경민 */
    /* height: 100vh;
    padding-bottom: 32vh;
    overflow-y: scroll;
    overflow-x: hidden; */

    /* overscroll-behavior-y: "contain"; */
  }

  .cont-feed.nopadding {
    padding-top: 0;
  }

  .cont-feed .list {
    width: 100%;

    /* 230120 캠페인&테마 모바일에서 GNB안보이는 문제때문에 삽입 */
    padding-bottom: 50px;

    /* 0614김경민 */
    /* cont feed 영역에 height를 추가하고 .list에도 height를 추가해줘야 함 그렇지 않으면 list item이 검색을 통해서 보여줬을때 하단이 안보임 */
    /* border: 1px solid red; */
    /* 0610 */
    /* height: 100vh !important; */
    /* min-height: 100%;
    max-height: max-content; */
  }

  .member-feed_list {
    width: 100%;
    background: #ececec;

    /* 230120 상세 및 함께하는 일상 주변 모바일에서 GNB안보이는 문제때문에 삽입 */
    padding-bottom: 50px;
  }

  .member-feed_list .item {
    height: auto;
    margin-bottom: 12px;
  }

  .cont-feed .list .item {
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 12px;
  }

  .item-header-wrap {
    border-radius: 0;
    border: none;
    padding: 8px 14px;
    position: unset;
  }

  .item-header-wrap .item-header {
    height: 42px;
  }

  .item-header-wrap .item-header .thumb {
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-right: 10px;
  }

  .item-header-wrap .item-header .thumb img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .item-header-wrap .item-header .info-ct {
    max-width: calc(100% - 85px);
  }

  .item-header-wrap .item-header .info {
    min-height: 40px;
  }

  .item-header-wrap .item-header .info .user-name {
    height: 22px;
    font-size: 0.938rem;
  }

  .item-header-wrap .item-header .info .location {
    color: #4e4e4e;
  }

  .item-header-wrap .item-header .info .location a {
    font-size: 0.75rem;
    margin-top: 2px;
  }

  .item-header-wrap .item-header .time {
    /* 24.07.08 codelua 시간을 누르면 YYMMDD HH:MM 를 표현하기 위해  fit-content로 변경 기존 값 */
    /* width: 52px; */
    width: fit-content;
    font-size: 0.713rem;
    line-height: 1.4em;
    color: #4e4e4e;

    margin-bottom: 2.5px;
  }

  .item-header-wrap .item-header .distance {
    margin-bottom: 3px;
  }

  .item-header-wrap .item-header .item-menu {
    position: fixed;
  }

  .item-body-wrap {
    border-left: none;
    border-right: none;
  }

  .item-photo {
    height: 350px;
    background: #fff;
  }

  .item-photo.pl::after {
    display: none;
  }

  .pht-slider.ad {
    height: 440px;
  }

  .item-photo .pht-slider {
    height: 100%;
    pointer-events: all;
  }

  .item-photo .pht-slider .swiper-pagination {
    display: block;
  }

  .item-photo .pht-slider .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 3px;
    background: #fff;
    opacity: 1;
  }

  .item-photo
    .pht-slider
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--text-linkColor);
  }

  .item-info {
    border: none;
  }

  .item-info .text {
    font-size: 14px;
    line-height: 1.4em;
  }

  .item-info .text p {
    height: 60px;
  }

  .item-info .text p.text-clamp {
    max-height: 4.2em;

    -webkit-line-clamp: 3;
  }

  .item-bar-wrap {
    padding: 8px 5px 6px 14px;
    border-radius: 0;
    border: none;
    border-top: solid 1px #dcdcdc;
  }

  .wheather-type {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  .wheather-type.sunny {
    background: url("../imgs/icons/api_sunny.svg") no-repeat;
    background-position: center 20vw;
    background-size: 42vw;
  }

  .wheather-type.cloudy {
    background: url("../imgs/icons/api_cloudy.svg") no-repeat;
    background-position: center 19vw;
    background-size: 55vw;
  }

  .wheather-type.gray {
    background: url("../imgs/icons/api_gray.svg") no-repeat;
    background-position: center 22vw;
    background-size: 47vw;
  }

  .wheather-type.rainy {
    background: url("../imgs/icons/api_rainy.svg") no-repeat;
    background-position: center 23vw;
    background-size: 34vw;
  }

  .wheather-type.snowy {
    background: url("../imgs/icons/api_snowy.svg") no-repeat;
    background-position: center 22vw;
    background-size: 43vw;
  }
}

.thumb-weather {
  background: url("../imgs/icons/api_weather.svg") no-repeat;
  background-size: 100%;
  cursor: auto !important;
}

.thumb-traffic {
  background: url("../imgs/icons/api_traffic.svg") no-repeat;
  background-size: 100%;
  cursor: auto !important;
}

.item-bar.wheather,
.item-bar.traffic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  color: #666666;
}

.item-bar.wheather b,
.item-bar.traffic b {
  color: #ff6465;
  margin-right: 8px;
}

.w-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  height: 19.25px;
  margin-bottom: 10px;
}

.w-list .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #dcdcdc;
}

.wheather-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 25px;
  padding-bottom: 20px;
  background-color: #e7eef4 !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.wheather-type.sunny {
  background: url("../imgs/icons/api_sunny.svg") no-repeat;
  background-position: center 55px;
  background-size: 110px;
}

.wheather-type.cloudy {
  background: url("../imgs/icons/api_cloudy.svg") no-repeat;
  background-position: center 55px;
  background-size: 142px;
}

.wheather-type.gray {
  background: url("../imgs/icons/api_gray.svg") no-repeat;
  background-position: center 60px;
  background-size: 123px;
}

.wheather-type.rainy {
  background: url("../imgs/icons/api_rainy.svg") no-repeat;
  background-position: center 65px;
  background-size: 87px;
}

.wheather-type.snowy {
  background: url("../imgs/icons/api_snowy.svg") no-repeat;
  background-position: center 60px;
  background-size: 101px;
}

.w-current {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.w-info {
  width: 100%;
  padding: 0 14px;
}

.temp {
  display: inline-block;
  font-size: 21px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.temp strong {
  font-size: 55px;
  font-family: "arial", sans-serif;
  line-height: 1em;
  vertical-align: initial;
}

.dust {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  color: #4e4e4e;
}

.dust span {
  line-height: 16px;
}

.dust-type {
  font-weight: 600;
}

.dust-type::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  vertical-align: bottom;
}

.dust-type.type01::before {
  background: #5491dd;
}

.dust-type.type02::before {
  background: #509639;
}

.dust-type.type03::before {
  background: #ff9000;
}

.dust-type.type04::before {
  background: #f13b3b;
}

@media screen and (max-width: 480px) {
  .wheather-type {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  .wheather-type.sunny {
    background: url("../imgs/icons/api_sunny.svg") no-repeat;
    background-position: center 20vw;
    background-size: 42vw;
  }

  .wheather-type.cloudy {
    background: url("../imgs/icons/api_cloudy.svg") no-repeat;
    background-position: center 19vw;
    background-size: 55vw;
  }

  .wheather-type.gray {
    background: url("../imgs/icons/api_gray.svg") no-repeat;
    background-position: center 22vw;
    background-size: 47vw;
  }

  .wheather-type.rainy {
    background: url("../imgs/icons/api_rainy.svg") no-repeat;
    background-position: center 23vw;
    background-size: 34vw;
  }

  .wheather-type.snowy {
    background: url("../imgs/icons/api_snowy.svg") no-repeat;
    background-position: center 22vw;
    background-size: 43vw;
  }
}

.item.issue .thumb-issue {
  background: url("../imgs/icons/jebo_thumb.svg") no-repeat;
  background-size: 100%;
  cursor: auto;
}

.item.issue .item-body-wrap {
  width: 100%;
  overflow: hidden;
}

.item.issue .issue-cont {
  height: 285px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px;
}

.item.issue strong.title {
  font-size: 28px;
  display: inline-block;
  word-break: keep-all;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

.item.issue strong {
  font-size: 21px;
  word-break: keep-all;
  color: #fff;
  text-align: center;
}

.item.issue .item-bar-wrap.issue .link {
  display: inline-block;
  width: 100%;
  color: #5593c5;
  font-size: 13px;
}

.item.issue .item-bar-wrap.issue .link i {
  float: right;
}

@media screen and (max-width: 480px) {
  .item.issue .item-body-wrap {
    width: 100%;
    border: none;
    border-radius: 0;
  }

  .item.issue .issue-cont {
    height: 100vw;
    padding: 50px 30px;
  }
}

/*---------------------------------------------------------
    modal - common
-----------------------------------------------------------*/
.modal-wrap {
  width: 100%;
  height: 100%;
  min-height: 700px;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: none;
}

.modal-wrap.agree {
  min-height: 635px;
}

.modal-wrap.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal {
  display: none;
  max-width: 90vw;
  max-height: 96vh;
  border-radius: 5px;
  background: #fff;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 0;
  display: none;
}

.modal.open {
  -webkit-animation: modalOpen 0.35s ease-in-out;
  animation: modalOpen 0.35s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.modal .modal-btn {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: none;
  background: var(--default-deepBlueColor);
  color: #ffffff;
  font-size: 1rem;
}

.modal .modal-btn:disabled {
  background: #e4e4e4;
  color: #999999;
}

.modal-header {
  width: 100%;
  height: 48px;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.modal-header.signup {
  display: block;
}

.modal-header strong {
  display: inline-block;
  width: auto;
  font-size: 1.625rem;
  color: var(--default-deepBlueColor);
  font-weight: 500;
  line-height: 50px;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.modal-header .modal-close {
  float: right;
  border: none;
  background: #fff;
}

.modal-header .modal-close i {
  font-size: 1.25rem;
  color: var(--default-deepBlueColor);
}

.modal-body {
  padding: 0 30px;
  max-height: calc(86vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-editor-body {
  padding: 0 30px;
  max-height: calc(86vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-editor-footer {
  width: 100%;
  height: 48px;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.margin-center {
  margin-left: 25%;
}

.height100 {
  height: 100%;
}

.mTop-auto {
  margin: 3% 10.5rem;
}

/*---------------------------------------------------------
    modal - write
-----------------------------------------------------------*/
.modal.modal-write {
  width: 790px;
  /* height: auto; */
  padding-top: 20px;
}

.modal.modal-write .modal-body {
  padding-top: 10px;
  padding-bottom: 40px;
  max-height: calc(96vh - 80px);
}

.modal.modal-write .input-group.text .text-area {
  border: solid 1px #dddddd;
  border-radius: 3px;
  width: 100%;
  height: 100px;
  resize: none;
}

.modal.modal-write .input-group.text .text-area .DraftEditor-root {
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.modal.modal-write
  .input-group.text
  .text-area
  .public-DraftEditorPlaceholder-root {
  width: auto;
}

.modal.modal-write .input-group.text .text-area .DraftEditor-editorContainer,
.modal.modal-write .input-group.text .text-area .DraftEditor-root,
.modal.modal-write .input-group.text .text-area .public-DraftEditor-content {
  height: auto;
  line-height: 1.3em;
  font-size: 16px;
}

.modal.modal-write .input-group.image {
  /*height: 80px;*/
  height: 100px;
  overflow: hidden;
}

.modal.modal-write .input-group.image .img-item {
  width: 80px;
  height: 80px;

  margin-right: 10px;
  position: relative;
}

.modal.modal-write .input-group.image .img-item .img-del {
  position: absolute;

  /* right: 5px;
  top: 5px; */
  right: 12px;
  top: 5px;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 50%;
  z-index: 2;
  padding: 0;
  margin: 0;
  border: none;
  /* background: var(--default-deepBlueColor); */
  background: url(../imgs/icons/clear.svg) no-repeat;
  color: #fff;
}

.fileUpload_profile,
.modal.modal-write .input-group.image .img-item .file-upload,
.comment-write .comment-input .file-upload {
  display: none;
}

.modal.modal-write .input-group.image .img-item .file-upload + label {
  border-radius: 10px;
  display: block;

  /* 0407 진희 수정 */
  /* width: 100%;
  height: 100%; */
  width: 90%;
  height: 90%;
  background: #eeeeee;
  position: relative;
  cursor: pointer;
}

.modal.modal-write .input-group.image .img-item .file-upload + label::before {
  content: url("../imgs/icons/add_wh.png");
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal.modal-write .input-group.image .img-item .file-image {
  display: none;
}

/* 0407 추가한 사진 이미지 진희 수정 */
.modal.modal-write .input-group.image .img-item .file-image + label {
  display: block;
  /* width: 100%;
  height: 100%; */
  width: 90%;
  height: 90%;
  /* border-radius: ; */
  border: solid 1px #c9c9c9;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.modal.modal-write .input-group.image .img-item .file-image + label img {
  display: block;
  width: 100%;
}

/*---------------------------------------------------------
    modal - location
-----------------------------------------------------------*/
.modal.modal-search .modal-body {
  background: #dfdfdf;
}

.modal.modal-search .write-item {
  margin-bottom: 12px;
}

.modal.modal-search .write-item:last-of-type {
  margin-bottom: 0;
}

.modal.modal-search .write-item .input-title {
  margin-bottom: 20px;
}

.modal.modal-search .write-item .input-title.location {
  margin-bottom: 10px;
}

.modal.modal-search .btn-location {
  width: 100%;
  height: 50px;
  font-size: 0.938rem;
  color: #fff;
  background: var(--default-deepBlueColor);
  border-radius: 3px;
  border: none;
  margin-top: 12px;
}

.modal.modal-search .delete-all {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 12px;
  background: transparent;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.03em;
}

.k-list {
  width: calc(100% + 28px);
  position: relative;
  left: -14px;
}

.k-list li {
  position: relative;
  padding: 10px 14px;
  padding-right: 25px;
  cursor: pointer;
}

.k-list li a {
  padding-right: 20px;
  position: relative;
  font-size: 0.938rem;
  line-height: 20px;
  color: #1e2530;

  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-list li a span {
  color: var(--text-linkColor);
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.k-list li .delete {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #000;
  font-size: 12px;
  background: transparent;
}

.k-list li.active {
  background: #f5f5f5;
}

/*---------------------------------------------------------
    modal - responsive
-----------------------------------------------------------*/
@media screen and (max-width: 480px) {
  .modal-wrap {
    display: none;
    /*height: calc(100% - 48px) !important;*/
    min-height: unset;
    /*max-height: calc(100% - 48px);*/
  }

  .modal-wrap.open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .modal {
    width: 100vw;
    max-width: 100vw;
    height: 100% !important;
    max-height: 100vh !important;
    border-radius: 0;
  }

  .modal-agree {
    overflow-y: scroll;
  }

  .modal.open {
    -webkit-animation: modalOpen 0.2s ease-in-out;
    animation: modalOpen 0.2s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
  }

  .modal form {
    height: 100%;
  }

  .modal-header {
    width: 100%;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 0 7px;
    padding-right: 16px;
    border-bottom: solid 1px #dcdcdc;
    background: #fff;
  }

  .modal-header strong {
    display: inline-block;
    width: auto;
    font-size: 1rem;
    color: var(--text-defaultBlackColor);
    font-weight: 500;

    /* 닉네임 10자 이상시 표시 내용 변경 진희 작성 0706 */
    /* line-height: 50px; */
    line-height: normal !important;
    width: 70%;
    text-align: center;
    /* 닉네임 10자 이상시 표시 내용 변경 진희 작성 0706 */

    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .modal-header .page-back {
    width: 27px;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    background: url("../imgs/icons/img_pre.svg") no-repeat;
    background-color: #fff;
    background-position: left center;
  }

  .modal-header .m-btn {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-linkColor);
  }

  .modal-body {
    padding: 0;
    height: calc(100% - 48px);
    overflow-y: auto;
    border-radius: 0;
    max-height: none !important;
  }

  .modal-body .input-title .ttl {
    /* font-size: 1.063rem; */
    /* 진희 수정 0407 */
    /* vw에 따라 폰트 사이즈 적용될 수 있도록 스타일 적용 */
    font-size: 3.5vw;
    font-weight: 600;
    color: #040404;
    width: auto;
  }

  /* 진희 수정중 0407 */
  /* 새로 추가함 */
  .input-title .desc {
    font-size: 2.5vw;
  }

  .modal.modal-write {
    padding: 0 !important;
    position: relative;
  }

  .modal.modal-write .modal-body {
    padding-bottom: 50px;
    /* 0316 다혜님 요청 -> 삭제 김진희 */
    /* height: calc(100% - 70px); */
    /* height: 100%; */
  }

  .modal.modal-write .write-item {
    margin-bottom: 0;
  }

  .modal.modal-write .img-item img.hor {
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .modal.modal-write .img-item img.ver {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  /* 업로드 미리보기 이미지 하단이 짤리는 css 수정 0530 */
  .img-item label div img {
    height: 125% !important;
  }

  .margin-center {
    margin-left: 0%;
  }
}

/*---------------------------------------------------------
    modal - sign
-----------------------------------------------------------*/
.modal.modal-sign {
  width: 500px;
}

.modal.modal-sign .input-group {
  margin-bottom: 20px;
}

.modal.modal-sign .panel {
  display: none;
}

.modal.modal-sign .panel.active {
  display: block;
}

.modal.modal-sign .panel.signin {
  padding-top: 10px;
}

.modal.modal-sign .panel.signin .input-group {
  margin-bottom: 10px;
}

.modal.modal-sign .panel.signup {
  padding-bottom: 10px;
}

.modal.modal-sign .panel.signup .join-txt {
  text-align: center;
  display: inline-block;
  margin-top: 30px;
}

.modal.modal-sign .panel .signup-sns {
  display: none;
  padding-bottom: 10px;
}

.modal.modal-sign .panel .signup-sns strong {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: var(--text-defaultBlackColor);
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.modal.modal-sign .panel .signup-sns .input-group {
  margin-bottom: 10px;
}

.modal.modal-sign .panel .signup-sns .input-group.email {
  margin-top: 25px;
  padding-top: 25px;
  border-top: solid 1px #dcdcdc;
}

.modal.modal-sign .panel .signup-sns.open {
  display: block;
}

.modal.modal-sign .panel .signup-email {
  display: none;
}

.modal.modal-sign .panel .signup-email.open {
  display: block;
}

.modal.modal-sign .modal-footer {
  position: relative;
}

.modal.modal-sign .modal-footer .sns-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal.modal-sign .modal-body {
  min-height: 175px;
}

.modal.modal-sign .modal-btn.kko {
  padding-left: 38%;
  text-align: left;
  background: #fee100;
  font-weight: 500;
  position: relative;
  color: var(--text-defaultBlackColor);
}

.modal.modal-sign .modal-btn.kko::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../imgs/icons/sns_k.svg") no-repeat;
  position: absolute;
  left: calc(38% - 50px);
  top: 0;
}

.modal.modal-sign .modal-btn.nv {
  padding-left: 38%;
  text-align: left;
  background: #19ce60;
  font-weight: 500;
  position: relative;
}

.modal.modal-sign .modal-btn.nv::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../imgs/icons/sns_n.svg") no-repeat;
  position: absolute;
  left: calc(38% - 50px);
  top: 0;
}

.modal.modal-sign .modal-btn.fb {
  padding-left: 38%;
  text-align: left;
  background: #3b5998;
  font-weight: 500;
  position: relative;
}

.modal.modal-sign .modal-btn.fb::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../imgs/icons/sns_f.svg") no-repeat;
  position: absolute;
  left: calc(38% - 50px);
  top: 0;
}

.modal.modal-sign .modal-btn.ggl {
  padding-left: 38%;
  text-align: left;
  background: #e74133;
  font-weight: 500;
  position: relative;
}

.modal.modal-sign .modal-btn.ggl::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../imgs/icons/sns_g.svg") no-repeat;
  position: absolute;
  left: calc(38% - 50px);
  top: 0;
}

.modal.modal-sign .modal-btn.apple {
  padding-left: 38%;
  text-align: left;
  background: #fff;
  color: #000;
  font-weight: 500;
  position: relative;
  border: 1px solid #000;
}

.modal.modal-sign .modal-btn.apple::before {
  content: "";
  width: 48px;
  height: 48px;
  background: url("../imgs/icons/sns_a.svg") no-repeat;
  position: absolute;
  left: calc(38% - 50px);
  top: 0;
}

.modal.modal-sign .modal-btn.email {
  background: #3fb3db;
}

.modal.modal-sign .modal-header {
  padding-top: 30px;
  height: 90px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.modal.modal-sign .modal-header strong {
  line-height: 1;
  margin-bottom: 5px;
}

.modal.modal-sign .modal-header .modal-close {
  width: 32px;
  height: 20px;
  background: url("../../assets/imgs/icons/exit_blue.svg") no-repeat;
  background-position: right center;
  background-size: 14px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.modal.modal-sign .modal-header .modal-close i {
  display: none;
}

.sign-tab-btns {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.sign-tab-btn {
  font-size: 1.25rem;
  color: #9d9d9d;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  margin-right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.sign-tab-btn.active {
  font-size: 1.625rem;
  font-weight: 500;
  color: var(--default-deepBlueColor);
}

.sign-tab-btn:hover {
  color: var(--default-deepBlueColor);
}

.chk-control {
  display: none;
}

.chk-control + label {
  vertical-align: middle;
  color: #4e4e4e;
  font-size: 0.813rem;
  margin-right: 20px;
  line-height: 22px;
}

.chk-control + label::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border: solid 1px #c9c9c9;
  border-radius: 3px;
  margin-right: 10px;
  vertical-align: middle;
}

.chk-control:checked + label::before {
  content: "";
  background: var(--default-deepBlueColor) url(../imgs/icons/done.svg) no-repeat
    center;
  background-size: 22px;
  border-color: var(--default-deepBlueColor);
}

.join-txt {
  font-size: 0.75rem;
  color: #4e4e4e;
}

.join.underline {
  font-size: 0.75rem;
  letter-spacing: -0.03em;
  color: var(--text-linkColor);
  position: relative;
  display: inline-block;
  background: transparent;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  border: none;
}

.input-group.around {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-justify-content: space-between !important;
}

.input-group.center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-justify-content: center !important;
}

.find-pswd {
  border: none;
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  color: var(--text-linkColor);
  line-height: 20px;
  letter-spacing: -0.03em;
  background: transparent;
}

.join.underline::after {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--text-linkColor);
  position: absolute;
  left: 0;
  bottom: -5px;
}

.sns-ttl {
  background: #fff;
  font-size: 1rem;
  font-weight: 400;
  color: #4d4d4d;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  top: -10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.sns-group {
  width: 100%;
  border-top: solid 1px #d2d2d2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 60px;
  position: relative;
}

.sns-group a {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 5px;
}

.sign-nv {
  background: url("../imgs/icons/sns_n.svg") no-repeat;
}

.sign-fb {
  background: url("../imgs/icons/sns_f.svg") no-repeat;
}

.sign-kko {
  background: url("../imgs/icons/sns_k.svg") no-repeat;
}

.sign-ggl {
  background: url("../imgs/icons/sns_g.svg") no-repeat;
}

.sign-appl {
  background: url("../imgs//icons/sns_a.svg") no-repeat;
  border: 1px solid #222;
}

/*---------------------------------------------------------
    modal - reSign
-----------------------------------------------------------*/
.modal.modal-resign {
  width: 450px;
}

.modal.modal-resign .modal-header {
  padding-top: 30px;
  height: 90px;
  width: 95%;
  margin: 0px 15px;
  margin-bottom: 10px;
  border-bottom: solid 1px #dcdcdc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.modal-resign .modal-header strong {
  display: inline-block;
  text-align: center;
  /* width: 100%; */
  /* margin: 0.7rem 0rem 0rem 8rem; */
  /* margin: 0.7rem 0rem 0rem 0rem; */
  margin: 0.7rem auto 0 auto;
  font-size: 1.625rem;
  color: var(--default-deepBlueColor);
  font-weight: 500;
  line-height: 50px;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.modal.modal-resign .modal-header .modal-close {
  background: url("../imgs/icons/exit.svg") no-repeat center;
  background-position: right center;
  background-size: 15px;
  align-self: flex-start;
  width: 15px;
  height: 15px;
}

/* 회원 탈퇴
  진희 작성
  0511 */
.setting-btn-container {
  position: relative !important;
}

.input-title .resign-btn {
  color: rgba(255, 87, 87, 0.733);
  float: right;
  font-size: 13px;
  background-color: transparent;
}

/* 회원 탈퇴
    진희 작성
    0511 */

/*logo 명삼추가*/
/*Starter 이미지 추가*/
.logo-signup-complete {
  width: 40%;
  height: 70%;
  text-align: center;
}

.modal-body-signup-complete {
  height: 320px;
  width: 100%;
  text-align: center;
  /* margin-top: 60px; */
  /* margin-bottom: 15%; */
  margin: 15% 0px;
}

.modal-body-signup-complete .starter-img-div {
  margin-bottom: 10%;
}

.starter-img-div .starter-img {
  width: 40%;
}

.modal-footer-signup-complete {
  width: 100%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.complete.underline {
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: var(--text-linkColor);
  position: relative;
  display: inline-block;
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
}

.modal-btn.signup-complete {
  width: 70%;
  margin-bottom: 20px;
}

.signup-title {
  font-size: 25px;
  color: var(--default-deepBlueColor);
}

.signup-subtitle {
  font-size: 1.15rem;
  margin-top: 5%;
  width: 100%;
  text-align: center;
}

.resign-header .logo {
  width: 92%;
  margin: 15px 15px;
  left: 0;
  top: 18px;
  padding: 0 30px;
}

.resign-header .logo img {
  display: block;
  width: 30%;
  margin: auto;
}

.resign-header .logo.size {
  margin-top: 0;
  margin-bottom: 0;
}

.logo.size img {
  display: block;
  width: 80%;
  height: 45px;
}

.resign-header strong {
  font-size: 1.625rem;
  margin: 3rem 1.2rem;
}

.resign-header .resign-notice {
  margin: 2rem 1.2rem;
}

.resign-header .title {
  text-align: center;
  width: 100%;
  display: inline-block;
  margin: 0;
}

.resign-header .resign-notice {
  line-height: 20px;
}

.resign-body {
  border-bottom: solid 1px #dcdcdc;
  margin: 0px 1.2rem;
}

.resign-body .select-resign {
  font-weight: bold;
}

.resign-radio {
  margin: 1rem 0rem;
}

.resign-check {
  margin: 1.5rem 0rem 0.5rem 0rem;
}

.resign-footer {
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.resign-footer .input-btn {
  border: none;
  margin: 2% 5%;
  padding: 0;
  display: inline-block;
  width: 45%;
  min-width: 178px;
  height: 50px;
  border-radius: 3px;
  text-align: center;
  line-height: 50px;
  font-size: 1rem;
  background: var(--default-deepBlueColor);
  color: #fff;
  font-weight: normal;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.etc-opinion::placeholder {
  color: #cf514a;
  font-weight: 400;
}

.etc-opinion::-webkit-input-placeholder {
  color: #cf514a;
  font-weight: 400;
}

.report-etc-opinion {
  width: 100%;
  font-size: 80%;
}

.report-etc-opinion::placeholder {
  font-size: 80%;
}

/*---------------------------------------------------------
    modal - 소셜로그인 약관동의
-----------------------------------------------------------*/
.modal.modal-agree .modal-header {
  padding: 10px 10px 0px 15px;
  height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.modal.modal-agree {
  padding: 0px 14px 14px 14px;
  height: fit-content;
}

.modal.modal-agree.email {
  padding: 0px 14px 14px 14px;
  height: fit-content;
}

.modal.modal-agree .modal-header .modal-close {
  background: url("../imgs/icons/exit.svg") no-repeat;
  background-position: right center;
  background-size: 16px;
  width: 32px;
  height: 20px;
  align-self: flex-start;
}

@media screen and (max-width: 480px) {
  .modal .modal-btn {
    height: 50px;
  }

  .sign-tab-btn {
    font-size: 1rem;
  }

  .sign-tab-btn.active {
    font-size: 1.375rem;
  }

  .modal.modal-sign {
    padding: 0 14px;
    overflow-y: auto;
  }

  .modal.modal-sign.complete {
    overflow-y: hidden;
  }

  .scroll-lock {
    overflow: hidden;
    touch-action: none;
  }

  .modal.modal-sign .modal-header {
    border: none;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  /* sns 회원 가입 수정 진희 작성 0524 */
  .modal.modal-sign .modal-header strong {
    font-size: 1.375rem;
    font-weight: 500;
    color: var(--default-deepBlueColor);
    position: relative;
    margin-bottom: 15px;
    left: -50%;
    top: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(55%, -20%);
  }

  .modal.modal-sign .modal-header strong.passwordText {
    transform: translate(85%, -20%);
  }

  .modal.modal-sign .modal.modal-agree .modal-header strong {
    margin-bottom: 25px;
  }

  .modal.modal-sign .modal-header .modal-close {
    background: url("../imgs/icons/exit.svg") no-repeat;
    background-position: right center;
    background-size: 16px;
  }

  .modal.modal-sign .modal-body {
    position: relative;
    padding-bottom: 50px;
    overflow-y: hidden;
    max-height: none;
  }

  .modal.modal-sign .panel .input-group.check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .modal.modal-sign .panel .input-group.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .modal.modal-sign .panel.signin .input-group {
    position: unset !important;
  }

  .modal.modal-sign .panel.signin .join-txt {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
  }

  .modal.modal-sign .panel.signin .join-txt .join {
    float: right;
  }

  .modal.modal-sign .panel.signup .join-txt {
    width: 100%;
    text-align: left;
    margin-bottom: 40px;
  }

  .modal.modal-sign .panel.signup .join-txt .join {
    float: right;
  }

  .modal.modal-sign .panel .signup-sns {
    margin-top: 30px;
  }

  .modal.modal-sign .panel .signup-sns strong {
    font-size: 1.438rem;
    margin-bottom: 35px;
  }

  .modal.modal-sign .sns-group a {
    display: inline-block;
    margin: 0 7px;
  }
}

/*---------------------------------------------------------
    modal - detail
-----------------------------------------------------------*/

.modal-detail {
  width: auto;
  max-width: 88vw;
  max-height: 650px;
  position: relative;
  border-radius: 0;
}

.modal-detail > .close {
  border: none;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: solid 2px #fff;
  background: url("../imgs/icons/exit_wh.svg") no-repeat;
  background-size: 50%;
  background-position: center center;
  position: absolute;

  /* 0112 right 0 대각선으로 있는 X 표시보다 popup 바로 위에 표시될 수 있도록 만들 것 -> 다혜 */
  right: 0px;
  top: -40px;
}

.modal-detail > .modal-header {
  display: none;
}

.modal-detail .modal-body {
  padding: 0;
  overflow: hidden;
  max-height: 650px;
}

.modal-detail .feed-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  max-height: 600px;
  position: relative;
}

.modal-detail .feed-wrap .item-header-wrap {
  width: 450px;
  padding: 12px 18px;
  border: none;
  border-bottom: solid 1px #dcdcdc;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-detail .feed-wrap .item-header-wrap .item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 35px;
  position: relative;
}

.modal-detail .feed-wrap .item-header-wrap .item-header .thumb {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-detail .feed-wrap .item-header-wrap .item-header .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-detail .feed-wrap .item-header-wrap .item-header .info-ct {
  width: calc(100% - 121px);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-detail .feed-wrap .item-header-wrap .item-header .info-ct .info {
  min-height: 36px;
  padding-top: 3px;
}

.modal-detail
  .feed-wrap
  .item-header-wrap
  .item-header
  .info-ct
  .info
  .user-name
  a,
.modal-detail
  .feed-wrap
  .item-header-wrap
  .item-header
  .info-ct
  .info
  .location
  a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-detail .feed-wrap .item-header-wrap .item-header .time {
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-weight: 300;
  color: #797979;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: right;
  min-width: 80px;
  padding-bottom: 0;
}

.modal-detail .feed-wrap .item-header-wrap .item-header .menu-open {
  border: none;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -2px;
  top: 0;
  background: url("../imgs/icons/dm_more.svg") no-repeat;
  background-position: center center;
  background-size: 2px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modal-detail .feed-wrap .item-header-wrap .item-header .item-menu {
  position: absolute;
  top: 22px;
  right: -5px;
}

.modal-detail .feed-photo {
  display: inline-block;
  width: 600px;
  height: 600px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-right: solid 1px #dcdcdc;
  /* border-radius: 5px 0 0 0; */
}

.modal-detail .feed-photo.no {
  display: none;
}

.modal-detail .feed-photo .photo {
  height: 100%;
  position: relative;
}

.modal-detail .feed-photo .modal-pht-slider {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.modal-detail .feed-photo .modal-pht-slider .swiper-slide {
  width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-detail .feed-photo .modal-pht-slider .swiper-button-next,
.modal-detail .feed-photo .modal-pht-slider .swiper-button-prev,
.modal-detail .feed-photo .modal-pht-slider .swiper-pagination {
  visibility: hidden;
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-next,
.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-prev,
.live-swiper .swiper-button-next,
.live-swiper .swiper-button-prev {
  visibility: visible;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-next::after,
.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-prev::after,
.live-swiper .swiper-button-next::after,
.live-swiper .swiper-button-prev::after {
  display: none;
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-next::before,
.live-swiper .swiper-button-next::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/next.svg) no-repeat center;
  background-size: contain;
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-button-prev::before,
.live-swiper .swiper-button-prev::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/prev.svg) no-repeat center;
  background-size: contain;
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-pagination {
  visibility: visible;
}

.modal-detail .feed-photo .modal-pht-slider.active .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  opacity: 1;
  background: #dcdcdc;
  margin: 0 3px;
}

.modal-detail
  .feed-photo
  .modal-pht-slider.active
  .swiper-pagination-bullet-active {
  background: var(--text-linkColor);
}

.modal-detail .feed-photo img.hor,
.modal-detail .feed-photo video.hor {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 100%;
  height: auto;
}

.modal-detail .feed-photo img.ver,
.modal-detail .feed-photo video.ver {
  width: auto;
  max-width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-detail .feed-detail {
  width: 450px;
  height: 600px;
  /* padding-bottom: 64px; */
  padding-top: 60px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

/* 0706 진희 */
@media screen and (max-width: 480px) {
  .modal-detail .feed-detail {
    padding-bottom: 64px;
  }
}

.modal-detail .feed-detail .item-body-wrap {
  padding-top: 20px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  border-left: none;
  border-right: none;
}

.modal-detail .feed-detail .item-body-wrap .tags {
  padding: 0 18px;
  margin-bottom: 10px;
}

.modal-detail .feed-detail .item-body-wrap > .text {
  color: var(--text-defaultBlackColor);
  font-size: 14px;
  line-height: 1.6em;
  letter-spacing: -0.02em;
  padding: 0 18px;
  margin-bottom: 20px;
}

.modal-detail .feed-detail .item-body-wrap .item-bar-wrap {
  width: 100%;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
  padding: 7px 9px 9px 15px;
}

/* 텍스트 url ui 수정 진희 */
.modal-detail .feed-detail .item-body-wrap .text a {
  word-break: break-all;
}

.item-body-wrap .text p {
  word-break: break-word;
}

/* 0706 진희수정 */
.modal-detail .feed-detail .item-body-wrap .comments {
  /* padding: 20px 16px; */
  padding: 20px 0;
  /* padding-bottom: 50px; */
  margin-bottom: 60px;
}

@media screen and (min-width: 481px) {
  .modal-detail .feed-detail .item-body-wrap .comments .comments-list {
    padding: 0 16px;
  }
}

.modal-detail .feed-detail .item-body-wrap .comments .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 25px;
  position: relative;
}

.modal-detail .feed-detail .item-body-wrap .comments .comment-item .thumb {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.modal-detail .feed-detail .item-body-wrap .comments .comment-item .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: calc(100% - 68px);
  padding-right: 20px;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .top
  span {
  font-size: 13px;
  color: var(--text-defaultBlackColor);
  font-weight: 400;
  line-height: 1.5em;
  word-break: break-all;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .user-name {
  font-size: 13px;
  color: var(--text-defaultBlackColor);
  padding-right: 10px;
  font-weight: 600;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 7px;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  span,
.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  .comment-add {
  margin: 0;
  padding: 0;
  background: transparent;
  margin-right: 15px;
  font-size: 13px;
  color: #9d9d9d;
  border: none;
  line-height: 1;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  span {
  font-weight: 300;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  .comment-add {
  font-weight: 500;

  font-size: 12px;
  line-height: 13px;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box {
  display: none;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box.cmt-active {
  margin-top: 10px;
  display: block;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input {
  width: 100%;
  background: #f1f1f1;
  min-height: 43px;
  border-radius: 21px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 60px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input
  button {
  width: 30px;
  position: absolute;
  right: 16px;
  top: 0;
  line-height: 43px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 0.875rem;
  color: var(--text-linkColor);

  height: 100%;
}

.modal-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input
  textarea {
  width: 100%;
  height: 23px;

  /* 댓글 여러줄 작성 후 textarea의 크기가 원래대로 돌아오지 않기때문에 max height 삭제했음 진희 수정 0531 */
  /* max-height: 23px; */
  max-height: 61px;
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  line-height: 1.4em;
  background: transparent;
  border: none;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  resize: none;
}

.modal-detail .feed-detail .item-body-wrap .comments .comment-item .menu-open {
  border: none;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  background: url("../imgs/icons/dm_more.svg") no-repeat;
  background-position: center center;
  background-size: 2px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modal-detail .feed-detail .item-body-wrap .comments .comment-item .pop-menu {
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (min-width: 481px) {
  .modal-detail
    .feed-detail
    .item-body-wrap
    .comments
    .comment-item
    .pop-menu.feedCommentOption {
    position: absolute;
  }
}

.modal-detail .feed-detail .item-body-wrap .comments .re-comments {
  padding-left: 47px;
}

.modal-detail .feed-detail .item-body-wrap .comments .re-comments.third {
  padding-left: 47px;
  /* border: 1px solid red; */
}

.modal-detail .feed-detail .item-body-wrap .comments .re-comments .cmt-to {
  padding-right: 10px;
  color: var(--text-linkColor);
  font-weight: 600;
}

.modal-detail .feed-detail .comment-write {
  /* position: absolute; */
  /* 0706 */
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-top: solid 1px #dcdcdc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-bottom: 50px;
}

/* 김경민 220513 */
@media screen and (max-width: 480px) {
  .modal-detail .feed-detail .comment-write {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;

    /* 0526 진희 수정 */
    z-index: 2;
  }
}

.modal-detail .feed-detail .comment-write .thumb {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.modal-detail .feed-detail .comment-write .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-detail .feed-detail .comment-write .comment-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: #f1f1f1;
  height: auto;
  min-height: 38px;
  border-radius: 21px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 60px;
  position: relative;
}

.modal-detail .feed-detail .comment-write .comment-input textarea {
  width: 100%;
  height: 23px;
  /* 댓글 여러줄 작성 후 textarea의 크기가 원래대로 돌아오지 않기때문에 max height 삭제했음 진희 수정 0531 */
  /* max-height: 23px; */
  max-height: 61px;
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  line-height: 1.4em;
  background: transparent;
  border: none;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  resize: none;
}

.modal-detail .feed-detail .comment-write .comment-input button {
  width: 30px;
  position: absolute;
  right: 16px;
  top: 0;
  line-height: 38px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 0.875rem;
  color: var(--text-linkColor);

  height: 100%;
}

.comment-write .comment-input label {
  width: 20px;
}

@media screen and (max-width: 1200px) {
  .modal-detail > .close {
    right: 0;
  }

  .modal-detail .feed-wrap .feed-photo {
    min-width: 50%;
  }

  .modal-detail .feed-wrap .item-header-wrap,
  .modal-detail .feed-wrap .feed-detail {
    width: 320px;
  }
}

@media screen and (max-width: 768px) {
  .modal-detail {
    max-height: 80vh;
  }

  .modal-detail .modal-body {
    max-height: 80vh;
  }

  .modal-detail .feed-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: auto;
    max-height: 80vh;
  }

  .modal-detail .feed-wrap .item-header-wrap {
    position: relative;
    width: 100%;
  }

  .modal-detail .feed-wrap .feed-photo {
    width: 100%;
    height: auto;
    border-right: none;
    padding: 0;

    /* 0628 인스타그램 라이브러리 사용으로 인한 주석처리 */
    /* background: #f1f1f1; */
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }

  .modal-detail .feed-wrap .feed-photo .photo {
    width: 100%;
    height: auto;
  }

  .modal-detail .feed-wrap .feed-photo .photo img.hor,
  .modal-detail .feed-wrap .feed-photo .photo video.hor,
  .modal-detail .feed-wrap .feed-photo .photo img.ver,
  .modal-detail .feed-wrap .feed-photo .photo video.ver {
    /* 피드 디테일이 한 화면에 담기지 않아서 사이즈 수정함
    0414 진희 수정 */
    /* width: 100%; */
    /* height: auto; */
    height: 45vh;
  }

  /* @media screen and (max-width: 480px) {
    .modal-detail .feed-wrap .feed-photo .photo img.hor {
      border: 1px solid red;
    }
  } */

  /* .modal-detail .feed-wrap .feed-photo .photo.multiple, */
  .link-detail .feed-photo .photo.multiple {
    width: 100%;
    height: 110vw;
  }

  /* 멀티 이미지 사이즈 조절 진희 수정 0419 */
  .modal-detail .feed-wrap .feed-photo .photo.multiple {
    width: 100%;
    /* height: 90vw; */
  }

  /* 멀티 이미지 사이즈 조절 진희 수정 0419 */
  /* .modal-detail .feed-wrap .feed-photo .photo.multiple .swiper-slide, */
  .link-detail .feed-photo .link-pht-slider .swiper-slide {
    height: 110vw !important;
  }

  /* .modal-detail .feed-wrap .feed-photo .photo.multiple .swiper-slide {
    height: 90vw;
  } */

  .modal-detail .feed-wrap .feed-photo .photo.multiple img.ver,
  .modal-detail .feed-wrap .feed-photo .photo.multiple video.ver {
    /* 피드 디테일이 한 화면에 담기지 않아서 사이즈 수정함
    0414 진희 수정 */
    width: auto;
    height: 100%;
    /* height: 45vh; */
  }

  .modal-detail .feed-wrap .feed-detail {
    width: 100%;
    max-width: 100%;
    max-height: none;
    height: auto;
    padding-top: 0;
  }

  .modal-detail .feed-wrap .feed-detail .item-body-wrap {
    max-height: none;
  }
}

@media screen and (max-width: 480px) {
  .modal-detail {
    width: 100%;
    max-width: 100%;
    max-height: none;
  }

  .modal-detail > .modal-header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .modal-header .modal-tabCt {
    align-self: end;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .modal-header .modal-tabCt button {
    border: solid 1px #dcdcdc;
    border-radius: 5px 5px 0 0;
    width: 35%;
    height: 35px;

    color: #040404;
  }

  .modal-header .modal-tabCt button.active {
    background-color: var(--default-deepBlueColor);
    color: #fff;
  }

  .modal-detail .modal-body {
    height: calc(100% - 50px);
    max-height: none;
    overflow-y: auto;
    /* 메뉴 전체 높이 인식 안됨
	  -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);*/
  }

  .modal-detail .modal-body .item-menu.active ul {
    /* 0614김경민 */
    bottom: -60%;
    overflow-y: scroll; /*  */
    /* top: auto; */
  }

  .modal-detail .feed-wrap {
    width: 100%;
    /* 0614김경민 */
    /* height: auto; */
    height: 100%;

    max-height: none;
    min-height: 100%;
    position: relative;
  }

  .modal-detail .feed-wrap .item-header-wrap {
    padding: 12px 14px;
    border: none;
  }

  /* 0628 인스타그램 라이브러리 사용으로 인한 주석처리 */
  /* .modal-detail .feed-wrap .feed-photo,
  .link-detail .feed-wrap .feed-photo {
    background: #f1f1f1;
  } */

  .modal-detail .feed-detail {
    position: unset;
  }

  .modal-detail .feed-detail .item-body-wrap .text,
  .modal-detail .feed-detail .item-body-wrap .tags {
    padding: 0 14px;
  }

  .modal-detail .feed-detail .item-body-wrap .item-bar-wrap {
    padding: 7.5px 5px 9px 14px;
  }

  .modal-detail .feed-detail .item-body-wrap .comments {
    padding: 20px 14px;
    padding-bottom: 0;
  }
}

.contents-wrap {
  /* 0614김경민 */
  /* padding-top: 80px; */
  width: 100%;
  height: 100%;
}

/* 23.02.14 codelua  모바일 배너 영역 시작 */
.section-mobile-banner-line {
  margin-top: 35px;
}

/* 
.section-mobile-banner {
  height: 64px;
} */
/* 모바일 배너 영역 끝 */

.section-banner {
  height: 70px;
  background: #034882;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#034882),
    to(#6a95cc)
  );
  background: linear-gradient(90deg, #3484fd 0%, #29bef7 100%);
}

.section-banner .banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.section-banner .banner strong {
  display: inline-block;
  font-size: 1.313rem;
  line-height: 1.3em;
  font-weight: 400;
  word-break: keep-all;
  color: #fff;
  margin-right: 20px;
}

.section-banner .banner .btn-md {
  display: inline-block;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
  border: solid 1px transparent;
}

.section-banner .banner .btn-border-wh {
  color: #fff;
  background: transparent;
  border: solid 2px #fff;
}

.section-contents {
  /* height: 600px; */
  padding: 60px 0;
}

.section-contents .content-wrap {
  /* height: 380px; 높이값이 고정되어 있으면 .section-contents:padding 값이 제대로 적용되지않음*/

  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1090px) {
  .section-contents {
    height: auto;
  }

  /* .section-banner.pc .banner .swiper-container{
    width: 100% !important;
  } */

  .section-contents .content-wrap {
    display: flex;
    flex-wrap: wrap;
    height: max-content;
    gap: 10px;
  }
  .section-contents .content-wrap .cont-01 {
    width: 100%;
  }

  .section-contents .content-wrap .cont-01,
  .section-contents .content-wrap .cont-03 {
    flex: 1 0 auto;
    margin-top: 15px;
  }

  .section-contents .content-wrap .cont-01 {
    order: 1;
  }

  .section-contents .content-wrap .cont-02 {
    order: 0;
    flex: 1 0 100%;
  }

  .section-contents .content-wrap .cont-03 {
    order: 2;
  }
}

/* @media screen and (max-width: 940px) and ( min-width:820px){
  .section-mytab .tab-btns{
    border: 1px solid blue;
  }
} */

.section-contents .content-wrap::after {
  content: "";
  display: block;
  clear: both;
}

.section-contents .content-wrap .content-container {
  /* float: left; */
}

/* 캠페인&테마 페이지네이션 고정 0816 */
.content-container .swiper-pagination {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.section-contents .content-wrap strong {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.25rem;
  line-height: 1;
  color: #343434;
}

.section-contents .content-wrap .box {
  height: 100%;
  height: 352px;

  /* height: 350px;  BannerSection1 에 적용*/
}

/* .section-contents .cont-01 {
  width: 680px;
} */
.section-contents .cont-01 {
  width: calc(100% - 786px);
}
.section-contents .cont-01,
.section-contents .cont-02 {
  /* width: calc(37.5% - 17px); */
  flex: 2 1 auto;
  /* margin-right: 17px; */
}

.section-contents .cont-03 {
  width: 25%;
}

.section-contents .cont-01 .box,
.cont02-slide {
  margin-top: 15px;
}

@media screen and(max-width:940px) {
  .section-contents .cont-02 {
    height: 440px;
  }
}

/* 0427 캠페인 수정 */
.section-contents .cont-01 .box {
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}

.cont-01 .campaign-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  padding: 10px;
}

.cont-01 .campaign-container .title {
  text-align: center;
  padding: 10px 0;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 30px;
  color: var(--text-linkColor);
}

.cont-01 .campaign-container .campaign-btnContainer {
  bottom: 25px;
  right: 10px;
  position: absolute;
}

.cont-01 .campaign-container .campaign-btnContainer button {
  padding: 10px;
  border-radius: 3px;
  margin: 4px;
  background-color: var(--default-deepBlueColor);
  color: #fff;
  font-size: 0.8rem;
  font-weight: 300;
}

.cont-01 .campaign-container .campaign-btnContainer button:hover {
  background-color: var(--text-linkColor);
  color: #f5f5f5;
}

.bannerSectionImg {
  width: 100%;
  height: 100%;
}

/* 0427 캠페인 수정 */

/* 0511 캠페인 피드 수정 */
.campaign-body-wrap {
  position: relative;
}

.campaign-body-wrap.single {
  margin-top: 10px;
}

.campaign-body-wrap.single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.campaign-item-title {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  margin-top: 30px;
  font-size: 1.5rem;
  color: var(--text-linkColor);
  z-index: 3;
}

.campaign-item-title.desc {
  margin-top: 70px;
  font-size: 1rem;
  color: #444;
  word-wrap: break-word;
  padding: 0 15px;
}

.campaign-btn-container {
  position: absolute;
  bottom: 25px;
  border: 0;
  right: 0;
  background-color: transparent;
}

.campaign-btn-container button {
  padding: 5px;
  border-radius: 3px;
  margin: 4px;
  background-color: rgb(1, 70, 129);
  color: rgb(255, 255, 255);
}

/* 0511 캠페인 피드 수정 */

/* 0512 캠페인 피드 수정 슬라이드 */

.campaign-feed {
  padding-bottom: 0 !important;
}

/* .campaignPageFeedContainer .swiper-slide {
  height: 520px !important;
} */

.campaign-body-wrap {
  height: 100%;
}

.campaign-body-wrap .item-body {
  height: 100%;
}

.campaign-body-wrap .item-body .item-photo {
  height: 100%;
}

/* item photo를 height 높이 값을 지워야 함 그 밑에부터 100% 접근 */

.campaign-body-wrap .item-body img {
  /* height: 100% !important; */
  object-fit: unset !important;
  /* height: 110% !important; */
}

/* 0512 캠페인 피드 수정 슬라이드 */

/* 0620 캠페인 피드 설명 추가 */
.campaign-container .desc-hashtag {
  text-align: center;
  padding: 0 10px;
  color: var(--text-defaultGrayColor);

  word-break: keep-all;
  /* word break 여부 확인할 것 */
  /* 글이 2줄이상 넘어갈때 어떻게 처리되는지
  중앙정렬 또는 왼쪽 정렬 되는지 확인하기 */
}

/* 0620 캠페인 피드 설명 추가 */

/*-----------------------------------------------------------------
  main section - event slide
-----------------------------------------------------------------*/
.report-swiper {
  width: 100%;
  height: 100%;
}

.report-swiper .swiper-wrapper {
  height: 100%;
}

.report-swiper .swiper-pagination {
  bottom: 15px;
}

.report-swiper .swiper-pagination .swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}

.report-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #aab3b7;
  opacity: 1;
}

.report-item {
  width: 100%;
  height: 100%;
  padding: 40px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-position: center 60px;
  background-repeat: no-repeat;
  text-align: center;
}

.report-item span {
  display: inline-block;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-defaultBlackColor);
  line-height: 1.5em;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.report-item.type01 {
  background-color: #e8f5fa;
}

.report-item.type02 {
  background-color: #e9eba3;
}

.report-item.type03 {
  background-color: #fff;
}

.report-item .btn-md {
  display: inline-block;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
  border: solid 1px transparent;
  color: #fff;
  background: #15a3d3;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.report-item .btn-md:hover {
  opacity: 0.7;
}

/*-----------------------------------------------------------------
  main section - live
-----------------------------------------------------------------*/
.live-swiper {
  width: 100%;
  height: 100%;
}

.live-item {
  overflow: hidden;
}

.live-item img.hor,
.live-item video.hor {
  min-width: 100%;
  height: auto;
  min-height: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.live-item .item-header {
  width: 100%;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.live-item .item-header .thumb {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.live-item .item-header .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.live-item .item-header .info-ct {
  width: calc(100% - 37px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 5px;
  color: #fff;
}

.live-item .item-header .info-ct .info .user-name,
.live-item .item-header .info-ct .info .location {
  color: #fff;
}

/* 0110 거리 삽입을 위한 주소 줄이기 진희 수정*/
.info-ct .info .location {
  width: 120px;
}

.traffic .info-ct .info .location {
  width: 100%;
}

/* 0110 */

.live-item .bottom {
  width: 100%;
  height: 85px;
  padding: 20px 17px;
  background: rgba(247, 247, 247, 0.9);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.live-item .bottom .text {
  margin-bottom: 5px;
  font-size: 0.85rem;
}

.live-item .bottom .text span {
  color: var(--text-defaultBlackColor);
  line-height: 1.2em;
  letter-spacing: -0.03em;
}

.live-item .bottom .text span .tag {
  color: #3179cb;
  font-size: inherit !important;
}

.section-contents .content-container .box.no-border {
  border: none;
}

.live-swiper .swiper-pagination {
  text-align: right;
  padding-right: 12px;
}

.live-swiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 0.5;
}

.live-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: #000;
  opacity: 1;
}

/* 0110 hashTag 수정 진희 */
.item-header-hashTag {
  max-width: 140px;
  min-width: 60px;
  margin-right: 30px;

  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 10px;
  padding: 0px 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

/* 0406 */
/* 실시간 제보 #해쉬태그가 짤리는 것 방지 */
.item-header-hashTag .tag {
  /* display: inline-block; */
  line-height: 30px;
  max-width: 250px;

  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

/* 0110 hashTag 수정 진희 */

/*-----------------------------------------------------------------
  main section - ranker
-----------------------------------------------------------------*/
.user-rank {
  /* height: 100%; */
  height: 375px;
  padding: 7px 10px;
  background: #f8f8f8;
  border: solid 1px #dcdcdc;
}

.user-rank .top {
  padding: 12px 8px;
}

.user-rank .top strong {
  color: #333;
  display: block;
  margin-bottom: 12px;
  font-size: 1rem;
}

.user-rank .top span {
  color: #333;
  font-size: 0.875rem;
  line-height: 1.4em;
  word-break: keep-all;
  letter-spacing: -0.04em;
}

.user-rank .bottom {
  width: 100%;
  height: 336px;
}

.user-rank .bottom ul {
  width: 100%;
  height: 100%;
}

.user-rank .bottom ul li {
  width: 100%;
  height: 20%;
  border-bottom: solid 1px #cacaca;
}

.user-rank .bottom ul li:last-child {
  border-bottom: none;
}

.user-item {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.user-item .thumb {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 14px;
  top: 14px;
}

.user-item .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.user-item .info {
  padding-left: 72px;
  padding-right: 10px;
  min-height: 34px;
  width: 90%;
}

.user-item .info span a {
  line-height: 34px;
}

@media screen and (min-width: 4px) and (max-width: 768px) {
  .section-contents {
    height: auto;
  }

  .section-contents .cont-01 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 27px;
  }

  .section-contents .cont-02 {
    width: calc(60% - 17px);
  }

  .section-contents .cont-03 {
    width: 40%;
  }
}

@media screen and (max-width: 480px) {
  .section-contents {
    height: auto;
    padding: 0;
  }

  .section-contents .content-wrap {
    width: 100%;
  }
}

/*-----------------------------------------------------------------
  main section - tab
-----------------------------------------------------------------*/
.section-tab {
  background: #f5f5f5;
  padding: 20px 12px;
  /* 0614김경민 */
  /* border-bottom: solid 1px #dcdcdc; */

  -webkit-transition: 0.15s;
  transition: 0.15s;
  overflow: hidden;
}

.section-tab.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  /* 0614김경민 */
  /* top: 80px; */
  top: 0;

  z-index: 8;
  padding: 12px;
  background: #fff;
}

.section-tab .tab-btns {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.section-tab .tab-btns .btn {
  display: inline-block;
  width: auto;
  min-width: 90px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
  border: solid 1px transparent;
  margin-right: 10px;
  border: solid 1px #dcdcdc;
  background: #fff;
  font-size: 0.875rem;
  color: #292929;
  font-weight: normal;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.section-tab .tab-btns .btn:hover {
  font-weight: bold;
}

.section-tab .tab-btns .btn:last-of-type {
  margin-right: 0;
}

/*-----------------------------------------------------------------
  main section - feed
-----------------------------------------------------------------*/
.section-feed {
  background: #f5f5f5;
  /* min-height: 100vh; */
  /* 0614김경민 */
  /* padding-bottom: 100px; */
}

.section-feed .cont-sort {
  padding: 30px 0;
}

.section-feed .cont-sort .list {
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-feed .cont-sort .list > li {
  width: auto;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-feed .cont-sort .list > li button {
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  letter-spacing: -0.02em;
  color: var(--text-defaultBlackColor);
  background: none;
  border: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.section-feed .cont-sort .list > li button:hover {
  color: var(--hover-color);
}

.section-feed .cont-sort .list > li .sort-sel {
  width: 75px;
  height: 25px;
  display: none;
}

.section-feed .cont-sort .list > li .sort-sel select {
  width: 75px;
  height: 25px;
  border: solid 1px #dcdcdc;
  padding: 0 10px;
  margin-left: 13px;
  color: var(--text-defaultBlackColor);
  font-size: 12px;
  background: url("../imgs/icons/select.png") no-repeat;
  background-position: right center;
  background-color: #fff;
}

.section-feed .cont-sort .list > li.active button {
  color: var(--category-allColor);
  font-weight: bold;
}

.section-feed .cont-sort .list > li.active .sort-sel {
  display: block;
}

@media screen and (max-width: 480px) {
  .contents-wrap {
    padding-top: 0;
    background: #ececec;
    overflow-x: hidden;
  }

  .contents-wrap.home {
    height: 100%;
    padding-top: 45px;
    padding-bottom: 55px;
    min-height: 100vh;
  }

  /* 0614김경민 */
  /* .contents-wrap .content {
    position: relative;
    padding-top: 85px;
  } */

  .section-location {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    background: #fff;
    display: flex !important;
    /* 0614김경민 */
    /* position: absolute;
    left: 0;
    top: 0; */
    height: 35px;
    position: fixed;
    top: 45px;
    left: 0;

    z-index: 2;
    padding: 0 14px;
    padding-top: 5px;
  }

  .section-location .btn-loc {
    border: none;
    margin: 0;
    padding: 0;
    color: var(--text-defaultBlackColor);
    width: auto;
    height: 100%;
    /* font-size: 15px; */
    font-size: 12px;
    background: transparent;
    letter-spacing: -0.02em;
    vertical-align: middle;
  }

  .section-location .btn-loc::before {
    content: "";
    width: 16px;
    height: 21px;
    background: url("../imgs/icons/m_map_on.svg") no-repeat;
    background-position: center center;
    background-size: 100%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  .section-location .btn-loc::after {
    vertical-align: middle;
    display: inline-block;
    line-height: 29px;
    padding: 0;
    margin-left: 10px;
    content: "";
    background: url(../imgs/icons/m_expand.svg) no-repeat center;
    background-size: cover;
    width: 1.25em;
    height: 1.25em;
  }

  .section-tab {
    width: 100%;
    /* 0614김경민 */
    /* position: absolute;
    left: 0;
    top: 37px; 
    height: 48px;*/
    height: 35px;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 2;

    background: #fff;
    padding: 7px 14px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);

    -webkit-transition: none;
    transition: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-tab .tab-btns {
    width: 100%;
    padding-bottom: 1px;
  }

  .section-tab .tab-btns .btn {
    width: 100%;
    height: 27px;
    min-width: 0;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1;
  }

  .section-tab .tab-btns .btn:hover {
    font-weight: 400;
  }

  .section-tab .tab-btns .btn.active {
    font-weight: bold !important;
  }

  .section-contents .content-wrap .box {
    height: 120px;
  }

  .section-contents .content-wrap .box .report-swiper .swiper-pagination {
    text-align: left;
    padding-left: 14px;
  }

  .section-contents
    .content-wrap
    .box
    .report-swiper
    .swiper-pagination
    .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 3px !important;
  }

  .section-contents .content-wrap .box .report-item {
    padding: 0 14px;
    padding-top: 25px;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
  }

  .section-contents .content-wrap .box .report-item .bg {
    position: absolute;
    right: 10px;
    max-height: 90%;
    top: 5%;
  }

  .section-contents .content-wrap .box .report-item span {
    position: relative;
    z-index: 2;
    font-size: 1.25rem;
    line-height: 1.3em;
  }

  .section-contents .content-wrap .box .report-item button {
    display: none;
  }

  .section-feed {
    /* 0614김경민 */
    /* margin-top: 12px; */
    padding-top: 35px;
    background: #ececec;
  }

  .section-feed .cont-sort {
    /* 0614김경민 */
    height: 35px;
    position: fixed;
    top: 115px;
    left: 0;
    width: 100%;
    z-index: 2;

    padding: 0;
    padding-left: 14px;
    padding-right: 4px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2px;
  }

  /* 0614김경민 */
  .section-feed .cont-sort.search {
    top: 85px;
  }

  .section-feed .cont-sort .list {
    width: 100%;
  }

  .section-feed .cont-sort .list li {
    width: 45px;
  }

  .section-feed .cont-sort .list li:last-child.active {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-feed .cont-sort .list li:last-child.active .sort-sel select {
    border-color: transparent;
    font-size: 0.85rem;
  }

  /* .fixed .section-location {
    position: fixed;
    left: 0;
    top: -37px;
    z-index: 9;
  } */

  /* .fixed .section-tab {
    top: 0px;
    padding: 0 14px;
    z-index: 2;
  } */

  /* .fixed.up .section-location {
    -webkit-transition: top 0.1s;
    transition: top 0.1s;
    top: 0;
  } */

  /* .fixed.up .section-tab {
    -webkit-transition: top 0.1s;
    transition: top 0.1s;
    top: 37px;
  } */
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
  border: solid 5px #000000;
  border-right-color: transparent;
  opacity: 0.3;
  -webkit-animation: rotating 0.7s ease-in-out;
  animation: rotating 0.7s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.contents-wrap.search {
  background: #f5f5f5;
}

.contents-wrap.search .content {
  padding-top: 0;
}

.contents-wrap.search .search-wrap {
  width: 100%;
  height: 74px;
  padding: 0 14px;
  padding-top: 20px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

.contents-wrap.search .search-wrap .btn-back {
  width: 40px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url("../imgs/icons/img_pre.svg") no-repeat;
  background-color: #fff;
  background-position: left center;
  position: relative;
  left: -10px;
}

.contents-wrap.search .search-wrap .search-ct {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.contents-wrap.search .search-wrap .search-ct .search-group {
  position: relative;
  width: 100%;
  height: 50px;
}

.contents-wrap.search .search-wrap .search-ct .search-group input {
  width: 100%;
  height: 100%;
  border: solid 1px #e2e2e2;
  border-radius: 3px;
  position: relative;
  padding-left: 22px;
  padding-right: 65px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* .contents-wrap.search .search-wrap .search-ct .search-group input:focus {
  font-size: 13px;
} */

.contents-wrap.search
  .search-wrap
  .search-ct
  .search-group
  input::-webkit-input-placeholder {
  color: #bababa;
  font-size: 14px;
}

.contents-wrap.search
  .search-wrap
  .search-ct
  .search-group
  input:-ms-input-placeholder {
  color: #bababa;
  font-size: 14px;
}

.contents-wrap.search
  .search-wrap
  .search-ct
  .search-group
  input::-ms-input-placeholder {
  color: #bababa;
  font-size: 14px;
}

.contents-wrap.search .search-wrap .search-ct .search-group input::placeholder {
  color: #bababa;
  font-size: 14px;
}

.contents-wrap.search .search-wrap .search-ct .search-group .search-btn {
  width: 40px;
  height: 46px;
  position: absolute;
  right: 2px;
  top: 2px;
  border: none;
  margin: 0;
  padding: 0;
  background: url("../imgs/icons/search.svg") no-repeat;
  background-size: 20px;
  background-color: #fff;
  background-position: center center;
}

.contents-wrap.search .search-word {
  text-align: center;
  /* padding-top: 60px; */
  padding-bottom: 20px;
}

.contents-wrap.search .search-word span {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-linkColor);

  display: inline-block;
  max-width: 80%;
  word-break: break-all;
}

.keyword-wrap.sc {
  width: 100%;
  position: relative;
  border-radius: 3px;
  padding: 0;
  display: block;
  top: 0;
  border: none;
  background: none;
}

.keyword-wrap.sc .k-panel {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  background: #fff;
  padding: 5.4vw 14px;
}

.keyword-wrap.sc .k-panel strong {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #1e2530;
}

.keyword-wrap.sc .k-panel .delete-all {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 12px;
  background: transparent;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.03em;
}

.keyword-wrap.sc .k-panel.recent {
  margin-bottom: 0;
}

@media screen and (max-width: 480px) {
  .contents-wrap.search {
    /* 김경민 0503 : pc 화면과 스크롤 기준이 달라짐 js 이벤트 에러 이후 구조 변경 필요 */
    /* position: absolute; */
    /* top: 0;
    left: 0; */

    /* 0621 김경민 */
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    z-index: 10;
    height: calc(100% - 48px);
    /* overflow-x: hidden; */
  }

  .contents-wrap.search .search-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 12px;
    height: 85px;

    /*0614김경민*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .contents-wrap.search .section-feed {
    margin-top: 0;
    padding-bottom: 0;
  }

  /* 0614김경민 */
  .section-keyword {
    padding-top: 86px;
    padding-bottom: 50px;
    height: 100%;
  }
}

.contents-wrap.my {
  background: #f5f5f5;
}

@media screen and (min-width: 481px) {
  .contents-wrap.my .item-info .text {
    height: 47px !important;
    overflow: hidden;
    word-wrap: break-word;
    height: 100%;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.section-profile {
  padding-top: 50px;
  padding-bottom: 30px;
}

.section-profile .p-header {
  background: #fff;
  border-bottom: solid 1px #dcdcdc;
  height: 48px;
  padding: 0 14px;

  /* 0621 김경민 */
  /* position: relative; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: none;
}

.section-profile .profile-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  padding: 29px;
  border: solid 1px #dcdcdc;
  position: relative;
}

.section-profile .profile-box .user-profil {
  width: 100%;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.section-profile .profile-box .user-profil .profile-thumb {
  width: 120px;

  /* 0111 팔로잉 퍼블리싱으로 인한 height 주석처리 진희수정 */
  /* height: 120px; */
  position: relative;
}

.section-profile .profile-box .user-profil .profile-thumb .thumb {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.section-profile .profile-box .user-profil .profile-thumb .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.section-profile .profile-box .user-profil .profile-thumb .thumb-edit {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  border-radius: 50%;
  border: none;
  background: url("../imgs/icon_camera.svg") no-repeat;
  background-size: 15px 12px;
  background-position: center center;
  background-color: #e9e9e9;
}

.section-profile .profile-box .user-profil .user-info {
  width: calc(100% - 120px);
  padding-left: 30px;

  /* 0112 */
  position: relative;
}

/* .section-profile .profile-box .user-profil .user-info .name  */
.section-profile .profile-box .user-profil .name {
  font-size: 1.375rem;
  color: #4e4e4e;
  display: block;
  font-weight: 500;
  padding-right: 15px;

  margin-bottom: 18px;
  margin-top: 20px;
  word-wrap: break-word;
}

.section-profile .profile-box .user-profil .user-info .badge-box {
  display: inline;
  position: relative;
}

/* 0111 구독 퍼블리싱 */
.section-profile .profile-box .user-profil .user-introduce {
  flex: 1 0 100%;
}

.section-profile .profile-box .user-profil .user-info .user-info-box {
  display: flex;
  margin-bottom: 22px;
  margin-top: 10px;
}

.section-profile
  .profile-box
  .user-profil
  .user-info
  .user-info-box
  .user-info-box-item {
  margin-right: 1.75rem;
  text-align: center;

  cursor: pointer;
}

.section-profile
  .profile-box
  .user-profil
  .user-info
  .user-info-box
  .user-info-box-item
  h2 {
  /* font-size: 24px; */
  font-size: 1.5rem;
  font-weight: bold;
  color: #4e4e4e;
  margin-bottom: 0.4375rem;
}

.section-profile
  .profile-box
  .user-profil
  .user-info
  .user-info-box
  .user-info-box-item
  span {
  font-size: 0.875rem;
  color: #4e4e4e;
}

/* 0111 구독 퍼블리싱 */

.section-profile .profile-box .btn-edit {
  border: none;
  margin: 0;
  padding: 0;
  height: 28px;
  padding: 0 12px;
  border-radius: 5px;
  border: solid 1px #dcdcdc;
  background: #fff;
  font-size: 13px;
  color: var(--text-defaultBlackColor);
  font-weight: 300;
  margin-right: 5px;
  margin-bottom: 5px;
}

.section-profile .profile-box .user-text {
  width: 100%;
  padding-top: 3px;
  margin-top: 20px;
}

.section-profile .profile-box .user-text .text {
  resize: none;
  overflow-y: hidden;
  height: 20px;
  width: 100%;
  font-size: 0.85rem;
  color: #4e4e4e;
  display: block;
  line-height: 1.4em;
  word-break: break-all;
  border: none;
}

.section-mytab {
  width: 100%;
  height: 40px;
  margin-bottom: 30px;
}

.section-mytab .tab-btns {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: solid 1px #dcdcdc;
}

.section-mytab .tab-btns li {
  width: 100px;
  height: 100%;
}

.section-mytab .tab-btns li .my-tab {
  display: block;
  width: 100%;
  line-height: 37px;
  border-bottom: solid 3px transparent;
  text-align: center;
  color: #4e4e4e;
  font-size: 0.875rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.section-mytab .tab-btns li.active .my-tab {
  border-color: var(--category-allColor);
  color: var(--category-allColor);
  font-weight: 500;
}

.section-mytab .tab-btns li:hover .my-tab {
  color: var(--category-allColor);
}

@media screen and (max-width: 480px) {
  .contents-wrap.my {
    width: 100%;
    height: 100%;
    /* height: calc(100% - 48px); */
    background: #fff;

    padding-bottom: 55px;

    /* 0621 김경민 */
    /* position: absolute; */
    /* position: fixed;

    left: 0;
    top: 0;
    z-index: 9; */
  }

  .contents-wrap.my .content {
    padding-top: 0;
  }

  .section-grid {
    background: #fff;
  }

  .section-grid .grid {
    width: 100%;
    padding-top: 10px;
  }

  .section-grid .grid.nopadding {
    padding-top: 0px;
  }

  .section-grid .grid ul {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1px;
  }

  .section-grid .grid ul li {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .section-grid .grid ul li a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .section-grid .grid ul li::before {
    content: "";
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .section-grid .grid ul li.pl::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    background: url("../imgs/icons/multi.png") no-repeat;
    background-size: 18px 18px;
    background-position: center center;
    text-align: center;
    right: 10px;
    bottom: 10px;
    z-index: 2;
  }

  .section-grid .grid ul li img,
  .section-grid .grid ul li video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .section-grid .grid ul li .default-img {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    /* 진희수정 0603 피드 리스트 텍스트 아래로 밀림 수정*/
    position: absolute;
    top: 0;
    left: 0;
  }

  .section-grid .grid ul li .default-img-share {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    /* 진희수정 0603 피드 리스트 텍스트 아래로 밀림 수정*/
    position: absolute;
    top: 0;
    left: 0;
  }

  .section-grid .grid ul li .default-img span {
    line-height: 1.4em;
    text-align: center;
    color: #fff;
    font-weight: 500;
    /* 진희 수정 0526 */
    word-wrap: break-word;
    word-break: break-all;
    /* word-break: keep-all; */
    font-size: 14px;
  }

  .section-profile {
    padding: 0;
  }

  .section-profile .p-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-profile .p-header .menu-dm {
    width: 26px;
    height: 32px;
    background: url(../imgs/icons/dm_line.svg) no-repeat;
    background-position: center center;
    background-size: 24px;
    margin-right: 10px;
    position: relative;
  }

  .section-profile .p-header strong {
    color: var(--text-defaultBlackColor);
    font-size: 1rem;
    letter-spacing: -0.03em;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    /* 0901 수정 진희 */
    width: 55%;
    text-align: center;
  }

  .section-profile .profile-box {
    border: none;
    padding: 25px 12px;
    padding-bottom: 15px;

    /* 0621 김경민 */
    padding-top: 70px;
  }

  .section-profile .profile-box .user-profil {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .section-profile .profile-box .user-profil .profile-thumb {
    width: 75px;
    /* height: 75px; */
  }

  .section-profile .profile-box .user-profil .profile-thumb .thumb {
    display: inline-block;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .section-profile .profile-box .user-profil .profile-thumb .thumb img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .section-profile .profile-box .user-profil .user-info {
    flex: 1 1 auto;
    padding-left: 24px;
    text-align: left;
    /* margin-top: 13px; */
  }

  /* .section-profile .profile-box .user-profil .user-info .name */
  .section-profile .profile-box .user-profil .name {
    color: var(--text-defaultBlackColor);
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;

    word-wrap: break-word;
    margin-top: 0;
  }

  .section-profile .profile-box .user-profil .user-info .text {
    font-size: 12px;
  }

  .section-profile .profile-box .btn-edit {
    width: 80px;
    height: 24px;
    line-height: 22px;
    font-size: 12px;
    padding: 0;
  }

  .section-profile .profile-box .user-text {
    width: 90vw;
    /* margin-left: -100px; */
    margin-top: 35px;
  }

  .section-mytab {
    background: #fff;
    margin-bottom: 12px;
  }

  .section-mytab .tab-btns li {
    width: 50%;
  }
}

.badge-ct {
  width: 560px;
  border: solid 1px #e8e8e8;
  padding: 20px;
  background: #fff;
  padding-bottom: 0;
  /* top: 45px; */
  top: 35px;
  left: 50%;
  -webkit-transform: translatex(-182px);
  transform: translatex(-182px);
}

/* .badge-ct::before {
  content: "";
  width: 14px;
  height: 14px;
  background: #e8e8e8;
  position: absolute;
  z-index: 1;
  top: -8.5px;
  left: 170px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.badge-ct::after {
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  position: absolute;
  z-index: 3;
  top: -7px;
  left: 170px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
} */

.badge-ct .badge-list {
  position: relative;
  z-index: 2;
}

.badge-ct .badge-list li {
  margin-bottom: 0;
}

.badge-ct .badge-list li strong {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .badge-ct {
    width: 400px;
  }

  .badge-ct .badge-list li {
    height: 150px;
  }
}

@media screen and (max-width: 480px) {
  .badge-ct {
    width: 100%;
    height: calc(100vh - 48px);
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    border: none;
  }

  .badge-ct .badge-list {
    max-height: calc(100vh - 117px);
    overflow-y: auto;
    padding: 20px 0;
  }
}

.wrap.dm {
  height: 100%;
}

.section-dm {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  background: #f5f5f5;
}

.dm-wrap {
  width: 100%;
  height: 80vh;
  border: solid 1px #dcdcdc;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.dm-wrap .dm-list-ct {
  width: 400px;
}

.dm-wrap .dm-list-ct .dm-body {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
}

.dm-wrap .dm-list-ct .dm-body .dm-list {
  padding: 13px 0;
  background: #fff;
}

.dm-wrap .dm-list-ct .dm-body .list {
  overflow-y: auto;
}

.dm-wrap .dm-list-ct .dm-body .dm-item {
  padding: 13px 22px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
}

.dm-wrap .dm-list-ct .dm-body .dm-item.active {
  background: #eaf1fa;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .thumb {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dm-wrap .dm-list-ct .dm-body .dm-item .info-ct {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: calc(100% - 110px);
  padding: 9px 0 9px 13px;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .info-ct .info {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .info-ct .text {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #666666;
  font-size: 13px;
  line-height: 1.3em;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .time {
  width: 55px;
  padding-top: 9px;
  font-size: 11px;
  color: #666666;
  text-align: right;
}

.dm-wrap .dm-list-ct .dm-body .dm-item .badge {
  display: block;
  position: absolute;
  right: 22px;
  top: 42px;
  width: auto;
  min-width: 19px;
  height: 19px;
  line-height: 18px;
  padding: 0 6px;
  font-size: 9px;
  text-align: center;
  border-radius: 10px;
  background: #cf514a;
  color: #fff;
}

.dm-wrap .dm-viewer-ct {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  border-left: solid 1px #dcdcdc;
  background: #fff;
  position: relative;
}

.dm-wrap .dm-viewer-ct .dm-viewer {
  width: 100%;
  height: 100%;
}

.dm-wrap .dm-viewer-ct .dm-viewer .dm-body {
  width: 100%;
  height: calc(100% - 50px);
}

.dm-wrap .dm-viewer-ct .dm-viewer .msg-list {
  height: calc(100% - 79px);
  overflow-y: auto;
  padding: 15px 28px;
}

.dm-wrap .dm-viewer-ct .dm-viewer .dm-footer {
  width: 100%;
  height: 79px;
}

.dm-wrap .dm-body.empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dm-wrap .dm-body.empty span {
  color: #666666;
  font-size: 13px;
}

.dm-wrap .dm-header {
  width: 100%;
  height: 50px;
  border-bottom: solid 1px #dcdcdc;
  padding: 0 22px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dm-wrap .dm-header strong {
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
  letter-spacing: -0.03em;
}

.dm-wrap .dm-header strong i {
  margin-left: 10px;
  color: #999999;
}

.dm-wrap .dm-header .menu-open {
  width: 50px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  margin: 0;
  padding: 0;
  background: url("../imgs/icons/dm_more.svg") no-repeat;
  background-size: 2.2px;
  background-position: center center;

  transform: rotate(90deg);
}

.dm-wrap .dm-header .pop-menu {
  right: 10px;
  top: 35px;
}

.dm-wrap .dm-header .nickName {
  width: 100%;
  text-align: center;
  display: inline-block;
  cursor: unset;
}

.dm-wrap .dm-time {
  display: block;
  padding-top: 20px;
  padding-bottom: 28px;
  text-align: center;
  font-size: 11.5px;
  color: #666666;
  font-weight: 300;
  letter-spacing: 0.04em;
}

.dm-wrap .dm-box {
  margin-bottom: 12px;
}

.dm-wrap .dm-box .thumb {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 18px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.dm-wrap .dm-box .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dm-wrap .dm-box .line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.dm-wrap .dm-box .line .bubble {
  width: auto;
  max-width: 320px;
  padding: 12px 20px;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.45em;
  word-break: keep-all;
}

.dm-wrap .dm-box .line .time {
  font-size: 11.5px;
  color: #666666;
  margin-bottom: 8px;
  font-weight: 300;
  letter-spacing: 0.04em;
  padding-left: 10px;
  white-space: nowrap;
}

.dm-wrap .dm-box .line .feed-item {
  width: 200px;
  height: auto;
  border: solid 1px #dcdcdc;
  border-radius: 20px;
  margin-bottom: 8px;
  overflow: hidden;
  background: #fff;
}

.dm-wrap .dm-box .line .feed-item .item-photo {
  width: 100%;
  height: auto;
  min-height: 148px;
  position: relative;
  overflow: hidden;
}

.dm-wrap .dm-box .line .feed-item .item-photo.pl::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("../imgs/icons/multi.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  text-align: center;
  right: 10px;
  bottom: 10px;
  z-index: 2;
}

.dm-wrap .dm-box .line .feed-item .item-photo.vdo::after {
  content: "";
  position: absolute;
  width: 42px;
  height: 42px;
  background: url("../imgs/icons/play-button.svg") no-repeat;
  background-size: 100%;
  background-position: center center;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.dm-wrap .dm-box .line .feed-item .pht-slider {
  width: 100%;
  height: 148px;
  overflow: hidden;
}

.dm-wrap .dm-box .line .feed-item .pht-slider img.ver,
.dm-wrap .dm-box .line .feed-item .pht-slider img.hor {
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.dm-wrap .dm-box .line .feed-item .info {
  padding: 10px 9px;
}

.dm-wrap .dm-box .line .feed-item .info .user-name {
  font-size: 14px;
  margin-bottom: 3px;
}

.dm-wrap .dm-box .line .feed-item .info .location {
  font-size: 12px;
  margin-bottom: 7px;
}

.dm-wrap .dm-box .line .feed-item .info .text {
  font-size: 12px;
  color: #666666;
  line-height: 1.3em;
}

.dm-wrap .dm-box .line .feed-item .info .text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dm-wrap .dm-box.left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-wrap .dm-box.left .line .bubble {
  border-radius: 5px 20px 20px 20px;
  background: #3179cb;
  color: #ffffff;

  /* 0322 김진희 */
  word-break: break-all;
  /* 0322 */
}

.dm-wrap .dm-box.right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-wrap .dm-box.right .line {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dm-wrap .dm-box.right .line .bubble {
  border-radius: 20px 5px 20px 20px;
  background: #f5f5f5;
  color: var(--text-defaultBlackColor);
  /* 0322 김진희 */
  word-break: break-all;
  white-space: pre-wrap;
}

.dm-wrap .dm-box.right .line .time {
  padding-right: 10px;
}

.dm-wrap .dm-write {
  height: 79px;
  position: relative;
  padding-left: 24px;
  padding-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: solid 1px #dcdcdc;
}

.dm-wrap .dm-write .msg-group {
  width: 100%;
  height: 50px;
  position: relative;
}

.dm-wrap .dm-write .msg-group .msg-text {
  width: 100%;
  height: 100%;
  /* resize: none; */
  border-radius: 25px;
  position: relative;
  z-index: 1;
  border: solid 1px #dcdcdc;
  padding-left: 20px;
  padding-right: 80px;
  padding-top: 15px;
}

.dm-wrap .dm-write .msg-group .msg-text::placeholder {
  font-size: 14px;
  vertical-align: middle;
}

.dm-wrap .dm-write .msg-group .msg-file {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 50px;
  top: 7px;
  background: url("../imgs/icons/dm_img.svg") no-repeat;
  background-position: center center;
  background-size: 23px;
  z-index: 2;
  cursor: pointer;
}

.dm-wrap .dm-write .msg-group .msg-file input {
  display: none;
}

.dm-wrap .dm-write .msg-group .msg-send {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  background: url("../imgs/icons/dm_bold.svg") no-repeat;
  background-position: 8px center;
  background-size: 18px;
  background-color: #3179cb;
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 2;
}

@media screen and (max-width: 830px) {
  .dm-wrap .dm-list-ct,
  .dm-wrap .dm-viewer-ct {
    width: 50%;
  }

  .dm-wrap .dm-list-ct {
    min-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .contents-wrap.dm {
    /* padding-top: 0; */
    /*김경민 0503 스크롤 이슈로 인해 주석처리*/
    /* position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: calc(100% - 48px); */
    height: 100%;
    width: 100%;
  }

  .section-dm {
    padding: 0;
  }

  .dm-wrap {
    height: 100%;
    border: none;
    /* position: absolute;
    left: 0;
    top: 0; */
  }

  .dm-wrap .dm-header {
    height: 48px;
    background: #fff;
    border-bottom: solid 1px #dcdcdc;
    position: fixed;
    top: 0;
    left: 0;
    /* padding: 0 7px; */
    z-index: 2;
  }

  .dm-wrap .dm-header a {
    width: 90%;
    text-align: center;
  }

  .dm-wrap .dm-header strong {
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .dm-wrap .dm-header .menu-open {
    top: 0;
    /* 진희수정 0712 윤경님 요청 */
    /* top: 3px; */
  }

  .dm-wrap .dm-list-ct .dm-body {
    padding-top: 48px;
    height: 100%;
    overflow: unset;
  }

  .dm-wrap .dm-list-ct .dm-body .list {
    overflow: unset;
    padding-bottom: 50px;
  }

  .dm-wrap .dm-footer {
    padding: 0 14px;
  }

  .dm-wrap .dm-list-ct {
    width: 100%;
  }

  .dm-wrap .dm-viewer-ct {
    width: 100%;
    height: 100%;
    /* position: fixed; */
    padding-top: 48px;
    /* top: 48px;
    left: 0; */
    /* z-index: 3; */
    /* left: 100%; */
  }

  .dm-wrap .dm-viewer-ct.open {
    -webkit-animation: dmSlide 0.2s ease-i;
    animation: dmSlide 0.2s ease-in;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .dm-wrap .dm-viewer-ct .dm-header {
    z-index: 3;
  }

  .dm-wrap .dm-viewer-ct .dm-header strong {
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    display: inline-block;
    width: 90%;
    text-align: center;
  }

  .dm-wrap .dm-viewer-ct .dm-viewer .dm-body {
    background: #f2f2f2;
    padding-bottom: 48px;
    height: 100%;
    min-height: 100vh;
  }

  .dm-wrap .dm-viewer-ct .dm-viewer .dm-body .msg-list {
    padding: 0 14px;
    height: 100%;
    padding-bottom: 70px;
    /* height: calc(100% - 70px); */
    overflow-y: hidden;
  }

  .dm-wrap .dm-viewer-ct .dm-viewer .dm-body .dm-write {
    padding: 0 14px;
    background: #fff;
    border-top: none;
    height: 70px;
    width: 100%;
    position: fixed;
    bottom: 48px;
    left: 0;
  }

  .dm-wrap .dm-box .line .time {
    font-size: 11px;
  }

  .dm-wrap .dm-box.right .line .bubble {
    background: #ffffff;

    /* 0322 김진희 수정*/
    word-break: break-all;
    /* 0322 */
  }
}

.section-link {
  width: 100%;
  height: 100%;
  padding: 50px 0;
  background: #f5f5f5;
}

.link-detail {
  width: auto;
  background: #fff;
  margin: 0 auto;
  border: solid 1px #dcdcdc;
  height: 100%;
}

.link-detail > .link-header {
  display: none;
}

.link-detail .link-body {
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.link-detail .feed-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.link-detail .feed-wrap .item-header-wrap {
  width: 450px;
  padding: 15px 25px;
  border: none;
  border-bottom: solid 1px #dcdcdc;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.link-detail .feed-wrap .item-header-wrap .item-header {
  width: 100%;
  height: 35px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify-content;
  -ms-flex-pack: justify-content;
  justify-content: justify-content;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-detail .feed-wrap .item-header-wrap .item-header .thumb {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
}

.link-detail .feed-wrap .item-header-wrap .item-header .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.link-detail .feed-wrap .item-header-wrap .item-header .info-ct {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.link-detail
  .feed-wrap
  .item-header-wrap
  .item-header
  .info-ct
  .info
  .user-name
  a,
.link-detail
  .feed-wrap
  .item-header-wrap
  .item-header
  .info-ct
  .info
  .location
  a {
  display: inline-block;
}

.link-detail .feed-wrap .item-header-wrap .item-header .time {
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 300;
  color: #797979;
}

.link-detail .feed-wrap .item-header-wrap .item-header .menu-open {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -2px;
  border: none;
  margin: 0;
  padding: 0;
  background: url(../imgs/icons/dm_more.svg) no-repeat;
  background-position: center center;
  background-size: 2px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.link-detail .feed-wrap .item-header-wrap .item-header .item-menu {
  position: absolute;
  top: 22px;
  right: -5px;
}

.link-detail .feed-photo {
  display: inline-block;
  width: auto;
  max-width: calc(100% - 450px);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-right: solid 1px #dcdcdc;
}

.link-detail .feed-photo .photo {
  height: 100%;
  position: relative;
}

.link-detail .feed-photo .photo .warning {
  display: inline-block;
  height: 30px;
  border-radius: 15px;
  padding: 0 13px;
  color: #cf514a;
  background: #fff;
  border: solid 1px #cf514a;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
}

.link-detail .feed-photo .link-pht-slider {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.link-detail .feed-photo .link-pht-slider .swiper-slide {
  width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-detail .feed-photo .link-pht-slider .swiper-button-next,
.link-detail .feed-photo .link-pht-slider .swiper-button-prev,
.link-detail .feed-photo .link-pht-slider .swiper-pagination {
  visibility: hidden;
}

.link-detail .feed-photo .link-pht-slider.active .swiper-button-next,
.link-detail .feed-photo .link-pht-slider.active .swiper-button-prev {
  visibility: visible;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}

.link-detail .feed-photo .link-pht-slider.active .swiper-button-next::after,
.link-detail .feed-photo .link-pht-slider.active .swiper-button-prev::after {
  display: none;
}

.link-detail .feed-photo .link-pht-slider.active .swiper-button-next::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/next.svg) no-repeat center;
  background-size: contain;
}

.link-detail .feed-photo .link-pht-slider.active .swiper-button-prev::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/prev.svg) no-repeat center;
  background-size: contain;
}

.link-detail .feed-photo .link-pht-slider.active .swiper-pagination {
  visibility: visible;
}

.link-detail .feed-photo .link-pht-slider.active .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  opacity: 1;
  background: #dcdcdc;
  margin: 0 3px;
}

.link-detail
  .feed-photo
  .link-pht-slider.active
  .swiper-pagination-bullet-active {
  background: var(--text-linkColor);
}

.link-detail .feed-photo .link-pht-slider img.hor,
.link-detail .feed-photo .link-pht-slider video.hor {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.link-detail .feed-photo .link-pht-slider img.ver,
.link-detail .feed-photo .link-pht-slider video.ver {
  width: auto;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.link-detail .feed-detail {
  width: 450px;
  height: 100%;
  max-height: 100%;
  padding-bottom: 75px;
  padding-top: 66px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.link-detail .feed-detail .item-body-wrap {
  padding-top: 20px;
  height: 100%;
  border-left: none;
  border-right: none;
  overflow-y: auto;
}

.link-detail .feed-detail .item-body-wrap .tags {
  padding: 0 25px;
  margin-bottom: 20px;
}

.link-detail .feed-detail .item-body-wrap > .text {
  color: var(--text-defaultBlackColor);
  font-size: 13px;
  line-height: 1.429em;
  letter-spacing: -0.02em;
  padding: 0 25px;
  margin-bottom: 20px;
}

.link-detail .feed-detail .item-body-wrap .item-bar-wrap {
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
  border-radius: 0;
  padding: 9px 9px 9px 25px;
}

.link-detail .feed-detail .item-body-wrap .comments {
  padding: 20px 25px;
  padding-bottom: 0;
}

.link-detail .feed-detail .item-body-wrap .comments .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 25px;
}

.link-detail .feed-detail .item-body-wrap .comments .comment-item .thumb {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.link-detail .feed-detail .item-body-wrap .comments .comment-item .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: calc(100% - 68px);
  padding-right: 20px;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .top
  span {
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  font-weight: 400;
  line-height: 1.5em;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .user-name {
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  padding-right: 10px;
  font-weight: 600;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 7px;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  span,
.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .bottom
  .comment-add {
  margin: 0;
  padding: 0;
  background: transparent;
  margin-right: 15px;
  font-size: 0.813rem;
  color: #9d9d9d;
  border: none;
  line-height: 1;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box {
  display: none;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box.cmt-active {
  margin-top: 10px;
  display: block;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input {
  width: 100%;
  background: #f1f1f1;
  min-height: 43px;
  border-radius: 21px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 60px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input
  button {
  width: 30px;
  position: absolute;
  right: 16px;
  top: 0;
  line-height: 43px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 0.875rem;
  color: var(--text-linkColor);
}

.link-detail
  .feed-detail
  .item-body-wrap
  .comments
  .comment-item
  .comment-text
  .comment-box
  .comment-input
  textarea {
  width: 100%;
  min-height: 23px;
  max-height: 61px;
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  line-height: 1.4em;
  background: transparent;
  border: none;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  resize: none;
}

.recomment-write textarea {
  min-height: 25px;
}

.link-detail .feed-detail .item-body-wrap .comments .comment-item .menu-open {
  width: 20px;
  height: 20px;
  border: none;
  margin: 0;
  padding: 0;
  background: url(../imgs/icons/dm_more.svg) no-repeat;
  background-position: center center;
  background-size: 2px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.link-detail .feed-detail .item-body-wrap .comments .re-comments {
  padding-left: 47px;
}

.link-detail .feed-detail .item-body-wrap .comments .re-comments .cmt-to {
  padding-right: 10px;
  color: var(--text-linkColor);
  font-weight: 600;
}

.link-detail .feed-detail .comment-write {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-top: solid 1px #dcdcdc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-detail .feed-detail .comment-write .thumb {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.link-detail .feed-detail .comment-write .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.link-detail .feed-detail .comment-write .comment-input {
  width: 100%;
  background: #f1f1f1;
  height: auto;
  min-height: 43px;
  border-radius: 21px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 60px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-detail .feed-detail .comment-write .comment-input textarea {
  width: 100%;
  height: 23px;
  max-height: 61px;
  font-size: 0.875rem;
  color: var(--text-defaultBlackColor);
  line-height: 1.4em;
  background: transparent;
  border: none;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  resize: none;
}

.link-detail .feed-detail .comment-write .comment-input button {
  width: 30px;
  position: absolute;
  right: 16px;
  top: 0;
  line-height: 43px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 0.875rem;
  color: var(--text-linkColor);
}

@media screen and (max-width: 1200px) {
  .link-detail .feed-wrap .feed-photo {
    min-width: 50%;
    max-width: calc(100% - 320px);
  }

  .link-detail .feed-wrap .item-header-wrap,
  .link-detail .feed-wrap .feed-detail {
    width: 320px;
  }
}

@media screen and (max-width: 768px) {
  .section-link {
    height: auto;
  }

  .link-detail .link-body {
    max-height: none;
  }

  .link-detail .feed-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    max-height: none;
  }

  .link-detail .feed-wrap .item-header-wrap {
    position: relative;
    width: 100%;
  }

  .link-detail .feed-wrap .feed-photo {
    width: 100%;
    max-width: 100%;
    max-height: none;
    height: auto;
    border-right: none;
    padding: 0;
    flex: none;
  }

  .link-detail .feed-wrap .feed-photo img.hor,
  .link-detail .feed-wrap .feed-photo img.ver {
    width: 100%;
    object-fit: contain;
  }

  .link-detail .feed-wrap .feed-detail {
    width: 100%;
    max-width: 100%;
    max-height: none;
    height: auto;
    padding-top: 0;

    /* 0110 */
    margin-bottom: 50px;
  }

  .link-detail .feed-wrap .feed-detail .item-body-wrap {
    max-height: none;
  }
}

@media screen and (max-width: 480px) {
  .section-link {
    padding: 0;
  }

  .link-detail {
    border: none;
  }

  .link-detail > .link-header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .link-detail .link-body {
    height: calc(100% - 55px);
    max-height: none;
  }

  .link-detail .feed-wrap {
    max-height: none;
  }

  .link-detail .feed-wrap .item-header-wrap {
    padding: 12px 14px;
  }

  .link-detail .feed-photo .link-pht-slider.active .swiper-button-next,
  .link-detail .feed-photo .link-pht-slider.active .swiper-button-prev {
    visibility: hidden;
  }

  .link-detail .feed-detail .item-body-wrap .text,
  .link-detail .feed-detail .item-body-wrap .tags {
    padding: 0 14px;
  }

  .link-detail .feed-detail .item-body-wrap .item-bar-wrap {
    padding: 9px 5px 9px 14px;
  }

  .link-detail .feed-detail .item-body-wrap .comments {
    padding: 20px 14px;
  }

  /* push 알람 접속시 이미지 크기 조절 0110 */
  .link-detail .feed-photo .link-pht-slider .swiper-slide {
    height: auto !important;
  }
}

.wrap.setting {
  min-height: 100%;
}

.contents-wrap.setting {
  background: #ececec;
  /* 김경민 0512 수정 : scrollelement 이슈 */
  height: 100vh;
}

.section-error {
  width: 100%;
  height: 100%;
  padding: 50px;
  background: #f5f5f5;
}

.error-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  border: solid 1px #dcdcdc;
  background: #fff;
  text-align: center;
}

.error-wrap .error {
  display: block;
  margin: auto;
}

.error-wrap .title-error {
  display: block;
  padding-top: 47px;
  margin: auto;
  height: 315px;
  position: relative;
  font-size: 150px;
  font-weight: 600;
  color: var(--category-allColor);
  background: url("../imgs/icons/error.svg") no-repeat;
  background-size: 300px;
  background-position: center center;
}

.error-wrap .content-error {
  display: block;
  position: relative;
  font-size: 36px;
  font-weight: 600;
  color: var(--category-allColor);
  padding: 30px 0;
  letter-spacing: -0.05rem;
}

.error-wrap .detail-error {
  display: block;
  font-size: 0.938rem;
  line-height: 1.5rem;
  letter-spacing: -0.05rem;
}

.error-wrap .btn {
  display: block;
  padding-top: 70px;
}

.error-wrap .btn a {
  display: inline-block;
  padding: 17px 0;
  border: 1px solid var(--category-allColor);
  width: 150px;
  margin: 7px 7px;
  color: var(--category-allColor);
}

.error-wrap .btn a:hover {
  background: var(--category-allColor);
  color: #fff;
  transition: 0.3s;
  font-size: 0.938rem;
}

.section-setting {
  width: 100%;
  height: 100%;
  padding: 50px 0;
}

.setting-wrap {
  width: 100%;
  height: 100%;
  border: solid 1px #dcdcdc;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.setting-wrap .s-list-ct {
  width: 400px;
  max-width: 30%;
  min-width: 160px;
}

.setting-wrap .s-list-ct.account-manage {
  width: 100%;
  max-width: 100%;
}

.setting-wrap .s-list-ct .s-header {
  width: 100%;
  height: 50px;
  border-bottom: solid 1px #dcdcdc;
  padding: 0 22px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.setting-wrap .s-list-ct .s-header strong {
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.03em;
}

.setting-wrap .s-list-ct .s-header strong i {
  margin-left: 10px;
  color: #999999;
}

.setting-wrap .s-list-ct .s-header .menu-open {
  width: 50px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  margin: 0;
  padding: 0;
  background: url("../imgs/icons/dm_more.svg") no-repeat;
  background-size: 2.2px;
  background-position: center center;
}

.setting-wrap .s-list-ct .s-header .pop-menu {
  right: 10px;
  top: 35px;
}

.setting-wrap .s-list-ct .s-body {
  width: 100%;
  height: calc(100% - 50px);
}

/* .setting-wrap .s-list-ct .s-profile {
  padding-top: 50px;
} */

.setting-wrap .s-list-ct .s-body .list {
  padding: 15px 0;
}

.setting-wrap .s-list-ct .s-body .setting-item {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 22px;

  background-color: #fff;
}

.setting-wrap .s-list-ct .s-body .setting-item span {
  font-size: 15px;
  color: var(--text-defaultBlackColor);
  font-weight: 300;
  letter-spacing: -0.03em;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.setting-wrap .s-list-ct .s-body .setting-item.active span,
.setting-wrap .s-list-ct .s-body .setting-item:hover span {
  font-weight: 600;
}

.setting-wrap .s-list-ct .s-body .setting-item i {
  color: #999999;
  display: none;
}

.setting-wrap .s-viewer-ct {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  border-left: solid 1px #dcdcdc;
  background: #fff;
  position: relative;
}

.setting-wrap .s-viewer-ct .s-body {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: none;
  overflow-y: auto;
}

.setting-wrap .s-viewer-ct .s-body.active {
  display: block;
}

.setting-wrap .s-viewer-ct .s-body .s-title {
  margin-bottom: 40px;
}

.setting-wrap .s-viewer-ct .s-body .s-title strong {
  color: var(--text-defaultBlackColor);
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.04em;
}

.setting-wrap .s-viewer-ct .s-body .s-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.setting-wrap .s-viewer-ct .account-manage .s-body .s-inner {
  flex-direction: column;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo {
  width: 160px;
  margin-right: 40px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: center;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .profile-thumb {
  display: inline-block;
  width: 123px;
  height: 123px;
  position: relative;
  margin-bottom: 20px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .profile-thumb .thumb-edit {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 2;
  border-radius: 50%;
  border: none;
  background: url(../imgs/icon_camera.svg) no-repeat;
  background-size: 15px 12px;
  background-position: center center;
  background-color: #e9e9e9;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .profile-thumb .thumb {
  display: inline-block;
  width: 123px;
  height: 123px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .profile-thumb .thumb img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* 프로필 이름 한줄 표시 */
.setting-wrap .s-viewer-ct .s-body .s-inner .photo strong {
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
  display: block;
}

/* 프로필 이름 한줄 표시 */
@media screen and (max-width: 480px) {
  .setting-wrap .s-viewer-ct .s-body .s-inner .photo {
    width: 180px !important;
  }
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .menu-open {
  border: none;
  margin: 0;
  padding: 0;
  color: var(--text-linkColor);
  font-size: 13px;
  font-weight: 600;
  background: transparent;
  margin-top: 15px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .photo .item-menu {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.setting-wrap .s-viewer-ct .s-body .s-inner .info-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .write-item {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 18px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .write-item.org {
  margin-bottom: 38px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .write-item.loc {
  margin-bottom: 10px;
  position: relative;
  top: -8px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .input-title {
  margin-bottom: 10px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .input-title .desc {
  float: right;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .input-group {
  position: relative;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .write-btn {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .write-btn .desc {
  color: var(--text-linkColor);
  font-size: 13px;
  font-weight: 500;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .input-btn {
  border: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: auto;
  min-width: 178px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  font-size: 1rem;
  background: var(--default-deepBlueColor);
  color: #fff;
  font-weight: normal;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .push-list {
  width: 100%;
  max-width: 100%;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .push-item,
.setting-wrap .s-viewer-ct .s-body .s-inner .push-title {
  padding-left: 10%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .push-title {
  margin-bottom: 10px;
  padding-left: 0;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .push-group:nth-child(1) {
  margin-bottom: 50px;
}

.setting-wrap .s-viewer-ct .s-body .s-inner .push-item span {
  color: var(--text-defaultBlackColor);
  font-size: 0.938rem;
  font-weight: 400;
}

.setting-wrap .s-viewer-ct .s-body .loc-item {
  display: inline-block;
  width: auto;
  /* 0628 관심지역 2줄 처리 수정 진희 */
  /* height: 35px; */
  margin-right: 5px;
  margin-bottom: 7px;
  border: solid 1px #dcdcdc;
  border-radius: 3px;
  padding-left: 13px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
}

.setting-wrap .s-viewer-ct .s-body .loc-item span {
  font-size: 12px;
  line-height: 33px;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
}

.setting-wrap .s-viewer-ct .s-body .loc-item .del {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: 6px;
  background: url("../imgs/icons/exit.svg") no-repeat;
  background-size: 9px 9px;
  background-position: center center;
  cursor: pointer;
}

.setting-wrap .s-viewer-ct .s-body .loc-item.active {
  background: #f5f5f5;
}

.badge-list {
  width: 100%;
}

.badge-list::after {
  content: "";
  display: block;
  clear: both;
}

.badge-list li {
  width: 25%;
  height: 180px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  float: left;
  text-align: center;
  position: relative;
}

.badge-list li .icon {
  display: block;
  width: 100%;
  height: 120px;
  z-index: 0;
}

.badge-list li .icon::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  opacity: 0.85;
  z-index: 3;
}

.badge-list li .icon::after {
  content: "";
  width: 50%;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url("../imgs/icons/lock.svg") no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: 3;
}

.badge-list li .icon.active::before,
.badge-list li .icon.active::after {
  display: none;
}

.badge-list li strong {
  display: block;
  margin-bottom: 10px;
  color: var(--text-defaultBlackColor);
  font-size: 14px;
  word-break: break-all;
}

.badge-list li span {
  color: var(--text-defaultBlackColor);
  font-size: 13px;
  letter-spacing: -0.03em;
  word-break: keep-all;
}

.badge01 .icon {
  background: url("../imgs/badges/badge_01.svg") no-repeat;
  background-position: center center;
  background-size: 110px;
}

.badge02 .icon {
  background: url("../imgs/badges/badge_02.svg") no-repeat;
  background-position: center center;
  background-size: 125px;
}

.badge03 .icon {
  background: url("../imgs/badges/badge_03.svg") no-repeat;
  background-position: center center;
  background-size: 97px;
}

.badge04 .icon {
  background: url("../imgs/badges/badge_04.svg") no-repeat;
  background-position: center center;
  background-size: 110px;
}

.badge05 .icon {
  background: url("../imgs/badges/badge_05.svg") no-repeat;
  background-position: center center;
  background-size: 106px;
}

.badge06 .icon {
  background: url("../imgs/badges/badge_06.svg") no-repeat;
  background-position: center center;
  background-size: 114px;
}

.badge07 .icon {
  background: url("../imgs/badges/badge_07.svg") no-repeat;
  background-position: center center;
  background-size: 112px;
}

.badge08 .icon {
  background: url("../imgs/badges/badge_08.svg") no-repeat;
  background-position: center center;
  background-size: 114px;
}

.badge09 .icon {
  background: url("../imgs/badges/badge_09.svg") no-repeat;
  background-position: center center;
  background-size: 110px;
}

.badge10 .icon {
  background: url("../imgs/badges/badge_10.svg") no-repeat;
  background-position: center center;
  background-size: 110px;
}

/* 0628 배지 수정중 */
/* .badge11 .icon,
.badge12 .icon,
.badge13 .icon,
.badge14 .icon {
  background: url("../imgs/icons/") no-repeat;
  background-position: center center;
  background-size: 80px;
} */

@media screen and (max-width: 768px) {
  .setting-wrap .s-viewer-ct .s-body .s-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .push-list {
    width: 100%;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .photo {
    width: 140px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  /* .setting-wrap .s-viewer-ct .s-body .s-inner .info-wrap {
    width: 100%;
    height: 100%;
  } */

  .setting-wrap .s-viewer-ct .s-body .s-inner .input-title .desc {
    float: none;
    display: block;
    margin-top: 6px;
  }

  .badge-list {
    width: 100%;
  }

  .badge-list::after {
    content: "";
    display: block;
    clear: both;
  }

  .badge-list li {
    width: 33%;
    padding: 0 10px;
    margin-bottom: 15px;
  }

  .badge-list li .icon {
    height: 100px;
  }

  .badge-list li strong {
    margin-bottom: 8px;
  }

  .badge-list .badge01 .icon {
    background-size: 82px;
  }

  .badge-list .badge02 .icon {
    background: url("../imgs/badges/badge_02.svg") no-repeat;
    background-position: center center;
    background-size: 88px;
  }

  .badge-list .badge03 .icon {
    background: url("../imgs/badges/badge_03.svg") no-repeat;
    background-position: center center;
    background-size: 69px;
  }

  .badge-list .badge04 .icon {
    background: url("../imgs/badges/badge_04.svg") no-repeat;
    background-position: center center;
    background-size: 80px;
  }

  .badge-list .badge05 .icon {
    background: url("../imgs/badges/badge_05.svg") no-repeat;
    background-position: center center;
    background-size: 76px;
  }

  .badge-list .badge06 .icon {
    background: url("../imgs/badges/badge_06.svg") no-repeat;
    background-position: center center;
    background-size: 85px;
  }

  .badge-list .badge07 .icon {
    background: url("../imgs/badges/badge_07.svg") no-repeat;
    background-position: center center;
    background-size: 80px;
  }

  .badge-list .badge08 .icon {
    background: url("../imgs/badges/badge_08.svg") no-repeat;
    background-position: center center;
    background-size: 85px;
  }

  .badge-list .badge09 .icon {
    background: url("../imgs/badges/badge_09.svg") no-repeat;
    background-position: center center;
    background-size: 82px;
  }

  .badge-list .badge10 .icon {
    background: url("../imgs/badges/badge_10.svg") no-repeat;
    background-position: center center;
    background-size: 82px;
  }
}

@media screen and (max-width: 480px) {
  .contents-wrap.setting {
    /* padding-top: 0; */
    /* position: fixed;
    left: 0;
    top: 0;
    z-index: 10; */
    padding-bottom: 55px;
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
  }

  .section-setting {
    padding: 0;
    /* height: calc(100% - 48px);
    top: 0;
    left: 0; */
  }

  .contents-wrap.error {
    height: calc(100% - 48px);
  }

  .section-error {
    margin: 0 auto;
    background: #fff;
  }

  .error-wrap {
    border: none;
  }

  .error-wrap .title-error {
    font-size: 100px;
    height: 250px;
    background-size: 250px;
  }

  .error-wrap .content-error {
    font-size: 30px;
    padding: 20px;
  }

  .error-wrap .detail-error {
    font-size: 14px;
  }

  .error-wrap .btn {
    padding-top: 50px;
  }

  .error-wrap .btn a {
    font-size: 14px;
    padding: 12px 0;
    width: 120px;
  }

  .setting-wrap {
    /* height: 100%; */
    /* padding-bottom: 48px; */
    /* height: calc(100% - 48px); */

    display: block;
    border: none;
    /* position: absolute;
    left: 0;
    top: 0; */
    background: #ececec;
  }

  .setting-wrap .s-list-ct {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    /* overflow: auto; */
    /* padding-bottom: 12px; */
  }

  .setting-wrap .s-list-ct .s-header {
    background: #fff;
    border-bottom: solid 1px #dcdcdc;
    height: 48px;
    padding: 0 7px;
    position: fixed;
    z-index: 2;
  }

  .setting-wrap .s-list-ct .s-header strong {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .setting-wrap .s-list-ct .photo {
    margin-bottom: 12px;
    text-align: center;
    padding: 0;
    padding: 30px 0;
    background: #fff;
  }

  .setting-wrap .s-list-ct .photo .thumb {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .setting-wrap .s-list-ct .photo .thumb img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .setting-wrap .s-list-ct .photo .profile-thumb {
    margin-bottom: 20px;
  }

  .setting-wrap .s-list-ct .photo strong {
    font-size: 1.125rem;
    color: var(--text-defaultBlackColor);
  }

  .setting-wrap .s-list-ct .s-body {
    /* height: auto; */
    /* padding-bottom: 50px; */
    /* height: 77%; */
    height: 100%;
    padding-top: 48px;
  }

  .setting-wrap .s-list-ct .s-body .list {
    padding: 0;
  }

  .setting-wrap .s-list-ct .s-body .setting-item {
    background: #fff;
    border-top: solid 1px #f1f1f1;
    height: 50px;
  }

  .setting-wrap .s-list-ct .s-body .setting-item span {
    font-weight: 400 !important;
  }

  .setting-wrap .s-list-ct .s-body .setting-item:first-of-type {
    border-top: none;
  }

  .setting-wrap .s-list-ct .s-body .setting-item:last-child {
    border-top: none;
    margin-top: 12px;
  }

  .setting-wrap .s-list-ct .s-body .account-manage .setting-item:last-child {
    margin-top: 0px;
  }

  .setting-wrap .s-list-ct .s-body .setting-item i {
    display: inline-block;
  }

  .setting-wrap .s-viewer-ct {
    /* width: 100%;
    height: 100%; */
    position: static;
    /* position: absolute;
    left: 100%;
    top: 0; */
    /* overflow: auto; */
  }

  .setting-wrap .s-viewer-ct.open {
    -webkit-animation: dmSlide 0.2s ease-in;
    animation: dmSlide 0.2s ease-in;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .setting-wrap .s-viewer-ct .s-body {
    padding: 0;
    overflow: unset;
  }

  .setting-wrap .s-viewer-ct .s-body .s-title {
    background: #fff;
    border-bottom: solid 1px #dcdcdc;
    height: 48px;
    margin-bottom: 0;
    padding: 0 7px;
    position: fixed;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 3;
  }

  .setting-wrap .s-viewer-ct .s-body .s-title strong {
    color: var(--text-defaultBlackColor);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .setting-wrap .s-viewer-ct .s-body .s-title .s-btn {
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    font-size: 0.875rem;
    color: var(--text-linkColor);
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner {
    padding: 80px 16px;
    display: block;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner.account-manage {
    padding: 50px 0px;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .write-item.org {
    margin-bottom: 28px;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .write-btn {
    position: fixed;
    z-index: 2;
    bottom: 48px;
    width: 100%;
    right: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px 12px;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .write-btn .desc {
    display: block;
    width: 100%;
    margin: 20px 0;
    text-align: right;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .input-btn {
    width: 100%;
    border-radius: 3px;
  }

  .setting-wrap .s-viewer-ct .s-body .s-inner .photo .item-menu {
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .list-back,
  .page-back {
    width: 27px;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    background: url("../imgs/icons/img_pre.svg") no-repeat;
    background-color: #fff;
    background-position: left center;
  }
}

.map {
  width: 100%;
  height: 100%;
}

.contents-wrap.map {
  padding-top: 0;
  position: relative;
  z-index: 10;
  height: 100vh;
}

.section-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.searchSection-map {
  height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #dcdcdc;
  border-radius: 5px;
}

.section-map .map-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.section-map .right {
  width: 390px;
  height: 100%;
  position: absolute;
  left: -390px;
  top: 0;
  z-index: 1;
  background: #ececec;
}

.section-map .right .btn-fold {
  border: none;
  margin: 0;
  padding: 0;
  width: 25px;
  height: 65px;
  border: solid 1px #e7e7e7;
  border-radius: 0 5px 5px 0;
  background: url("../imgs/icons/img_next.svg") no-repeat;
  background-size: 20px;
  background-position: center center;
  background-color: #fff;
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0px 0 8px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0 8px 0 rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.section-map .right.open {
  -webkit-animation: resultOpen 0.15s ease-in-out;
  animation: resultOpen 0.15s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  left: 0;
}

.section-map .right.open .btn-fold {
  background: url("../imgs/icons/img_pre.svg") no-repeat;
  background-size: 20px;
  background-position: center center;
  background-color: #fff;
}

/* 0725 진희 작성 feed map 만들기 */
.searchMap-ct {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchMap-ct .searchSection-map {
  width: 1200px;
  position: relative;
}

.searchMap-ct .searchSection-map .map-container {
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 480px) {
  .searchMap-ct {
    margin-top: 140px;
    margin-bottom: -100px;
  }

  .searchMap-ct .searchSection-map {
    border-radius: 0;
    height: 300px !important;
  }
}

/* 0725 진희 작성 feed map 만들기 */

.list-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.list-container .list-header {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
}

.list-container .list-header .top {
  width: 100%;
  height: 48px;
  background: #fff;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  /* align-items: flex-end; */
  align-items: center;
}

.list-container .list-header .top.mobile {
  display: none;
}

.list-container .list-header .logo {
  display: inline-block;
  /* width: 125px; */
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.03em;
  cursor: default;
}

.list-container .list-header .logo img {
  max-width: 100%;
}

.list-container .list-header .search-ct {
  /*
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 10px;

  padding: 0 20px;
  padding-bottom: 15px;
  height: 115px;
  background: #fff;
  */

  padding: 0 20px;
  padding-bottom: 15px;
  height: 115px;
  background: #fff;
}

.list-container .list-header .search-ct .map-location {
  font-size: 16px;
  line-height: 55px;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
}

.list-container .list-header .search-ct .map-location::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url("../imgs/icons/m_map.svg") no-repeat;
  margin: 0 5px;
  position: relative;
  top: 3px;
}

/* 0206 */
.search-ct .mapInfo-ct .map-location {
  line-height: 20px !important;
}

.search-ct .mapInfo-ct {
  /* height: 30px;
  display: flex;
  align-items: center;
  margin: 5px 0; */

  /* 편의지도 화면 작업(230307) */
  height: 34px;
  display: flex;
  background-color: #fff;
  border: 1px solid #e2e5e7;
  border-radius: 20px;
  margin: 5px 0;
}

.search-ct .mapIconCt {
  /* margin-left: auto;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center; */

  /* background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);
  width: 80px;
  height: 29px; */

  font-weight: 400;
  /* font-size: 13px; */
  line-height: 18px;
  color: #232323;

  /* 편의지도 화면 작업(230307) */
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  font-size: 11px;
}

.search-ct .mapIcon {
  width: 14px;
  height: 21.83px;
  margin-right: 3px;
}

.search-ct .publicInfoCt.mapIcon {
  background: url(../imgs/icons/icon_mapMarker_blue.svg) no-repeat;
}

.search-ct .jeboInfoCt.mapIcon {
  background: url(../imgs/icons/icon_mapMarker_red.svg) no-repeat;
}

.search-ct .mapInfo-ct .mapInfo-select {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  width: auto;
  padding: 5px 8px;
  margin-top: 5px;
  color: #222;
}

/* 0206 */

.list-container .list-header .search-ct .search-group {
  width: 100%;
  height: 50px;
  position: relative;
}

.list-container .list-header .search-ct input {
  width: 100%;
  height: 100%;
  border: solid 1px #dcdcdc;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  padding-left: 18px;
  padding-right: 65px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.list-container .list-header .search-ct .search-btn {
  width: 40px;
  height: 40px;
  z-index: 2;
  position: absolute;
  right: 2px;
  top: 2px;
  border: none;
  margin: 0;
  padding: 0;
  background: url(../imgs/icons/search.svg) no-repeat;
  background-size: 20px;
  background-color: #fff;
  background-position: center center;
}

.list-container .list-header .section-tab {
  background: #fff;
  padding: 0 20px;
  padding-bottom: 15px;
  border-bottom: none;
}

.list-container .section-feed {
  width: 100%;
  height: calc(100% - 220px);
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.list-container .section-feed .container {
  padding: 0;
}

.list-container .section-feed .item-photo,
.list-container .section-feed .pht-slider {
  height: 390px;
}

.list-container .cont-sort {
  padding: 0 15px;
  background: #fff;
}

.list-container .cont-feed {
  width: 100%;
  margin-left: 0;
}

.list-container .cont-feed .list {
  width: 100%;
}

.list-container .cont-feed .list .item {
  width: 100%;
  height: auto;
  margin: 0;
  margin-bottom: 12px;
}

.list-container .item-header-wrap {
  border: none;
  padding: 8px 15px;
  border-radius: 0;
  z-index: 2;
}

.list-container .item-body-wrap {
  border: none;
}

.list-container .item-bar-wrap {
  border: none;
  border-top: solid 1px #dcdcdc;
  border-radius: 0;
}

.list-container .search-ct .current-location {
  line-height: 35px;
  font-weight: 500;
  font-size: 0.875em;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0px 0px;
  margin: 0;
  border-radius: 10px;
}

.list-container .search-ct .current-location-search {
  font-weight: 600;
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #5293c5;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 10px;
}

.list-container .current-location {
  border: none;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  font-weight: 500;
  color: #5293c5;
  line-height: 35px;
  background: transparent;
}

.list-container .current-location::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url("../imgs/icons/gps.svg") no-repeat;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.input-title .current-location {
  border: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: #5293c5;
  line-height: 30px;
  background: transparent;
}

.input-title .current-location::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url("../imgs/icons/gps.svg") no-repeat;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.map-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* width: 80px;
  height: 52px; */
  width: "max-content";
  height: "40px";
  text-align: center;
  background: #15a3d3;
  border-radius: 10px 10px 10px 0;
  border: solid 1px #0681aa;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.map-info::before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 11px solid #0681aa;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  position: absolute;
  left: -8px;
  top: 98%;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  z-index: 1;
}

.map-info::after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 11px solid #15a3d3;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  position: absolute;
  left: -7px;
  top: 93%;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  z-index: 2;
}

.map-info span {
  display: block;
  color: #fff;
}

.map-info .location {
  font-size: 12px;
  margin-bottom: 6px;
}

.map-info .count {
  font-size: 16px;
  margin: 10px;
}

.map-info .count b {
  font-size: 22px;
  padding-right: 5px;
}

.clear-btn {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 3px;
  border: none;
  margin: 0;
  padding: 0;
  background: url(../imgs/icons/clear.svg) no-repeat;
  background-size: 15px;
  background-color: #fff;
  background-position: center center;
}

@media screen and (min-width: 480px) {
  .clear-btn {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 480px) {
  .contents-wrap.map {
    width: 100%;
    height: calc(100% - 48px);

    /* 0621 김경민 */
    /* position:absolute; */
    position: fixed;

    top: 0;
    left: 0;
    z-index: 10;
    padding-top: 48px;
  }

  .section-map {
    overflow-y: auto;
  }

  .section-map .map-container {
    width: 100%;
    height: 120vw;
    overflow: hidden;
  }

  /* 검색창에서 지도 사용 시 범위 조절  */
  .section-map .map-container-search {
    width: 100%;
    /* 230303 지도높이 수정 (기존:105vw)*/
    height: 120vw;
    overflow: hidden;
    z-index: 0;
  }

  .section-map .right {
    width: 100%;
    height: auto;
    position: unset;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .list-container {
    position: unset;
  }

  .list-container .list-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  .list-container .list-header .top.mobile {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    padding: 0;
  }

  .list-container .list-header .top.mobile .map-header {
    border-bottom: solid 1px #dcdcdc;
    height: 48px;
    padding: 0 7px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .list-container .list-header .top.mobile .map-header strong {
    color: var(--text-defaultBlackColor);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.03em;
    /* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
  }

  .list-container .list-header .search-ct {
    background: transparent;
    /* 230303 좌우 여백 수정 */
    padding: 0 8px;
    height: auto;
  }

  .list-container .list-header .search-ct .map-location {
    line-height: 35px;
    font-weight: 600;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 8px;
    border-radius: 10px;
  }

  .list-container .list-header .section-tab {
    background: transparent;
    position: relative;
    top: 0;
    padding: 0 16px;
  }

  .list-container .list-header .section-tab .tab-btns .btn {
    height: 30px;
    line-height: 25px;
  }

  .list-container .cont-sort {
    padding-left: 16px;
    padding-right: 4px;
  }

  .list-container .section-feed {
    margin-top: 0;
    background: #fff;
    padding-bottom: 0;
    padding-top: 0;
  }

  .map-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* width: 78px;
    height: 52px; */
    width: "max-content";
    height: "40px";
  }

  .map-info::before {
    content: "";
    border-top: 8px solid #0681aa;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    left: -6px;
    top: 99%;
  }

  .map-info::after {
    content: "";
    border-top: 8px solid #15a3d3;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    left: -5px;
    top: 93%;
  }

  .map-info .location {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .map-info .count {
    font-size: 14px;
  }

  .map-info .count b {
    font-size: 16px;
  }
}

.search-map-info span {
  display: inline-block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .mobile {
    display: block;
  }

  .pc {
    display: none !important;
  }
}

/*# sourceMappingURL=style.css.map */

/* trafficMapSection */
.TrafficMapSection_logo {
  display: inline-block;
  width: 160px;
  position: absolute;
  top: 18px;
  left: 30px;
  z-index: 1;
  cursor: pointer;
}

/* trafficMapSection */

/* trafficInfoBox */
.traffic-map-btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 300;
  background-color: #15a3d3;
  color: #fff;
}

.traffic-map-btn:focus {
  font-size: 11px;
}

.traffic-map-btn:hover {
  /* opacity: 0.8; */
  font-weight: bold;
}

.live-traffic {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 10px;
  background-color: var(--default-deepBlueColor);
  cursor: pointer;
}

.live-around {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  padding: 10px;
  background-color: var(--default-deepBlueColor);
  cursor: pointer;
}

/* trafficInfoBox */

/* FeedDetailLayer */

.btn-prev,
.btn-next {
  border-radius: 100%;
  border: 2px solid #fff;
  width: 30px;
  height: 30px;
  color: transparent;
  position: absolute;
  top: 50%;
  z-index: 100;
}

@media screen and (max-width: 480px) {
  .btn-prev,
  .btn-next {
    display: none;
  }
}

.btn-prev {
  left: -2.8125rem;
  background: url("../imgs/icons/arrow_back_white.svg") no-repeat center left;
}

.btn-next {
  right: -2.8125rem;
  background: url("../imgs/icons/arrow_forward_white.svg") no-repeat center
    right;
}

/* FeedDetailLayer */

/* ProfileSection */
/* .btn-edit.subscribe, .btn-edit.subscribing{
  width: 82px;
} */

/* 피드내 feedheader 구독하기 버튼 추가 */
.btn-edit.subscribe-btn {
  border: none;
  height: 21px;
  padding: 0 6px;
  border-radius: 5px;
  border: solid 1px #dcdcdc;
  background: #fff;
  font-size: 10px;
  font-weight: 300;

  margin-left: 8px;
  color: #666;
}

.subscribing-btn {
  background-color: #7cc5d8 !important;
  color: #fff !important;
  border: none !important;
}

/* ProfileSection */

/* feedHeader */

/* 구독중 이미지 */
.subscribing-img {
  background: url("../imgs/icons/subscribing.png") no-repeat center;
  width: 15px;
  height: 15px;
  margin-left: 6px;
}

/* feedHeader */

/* 구독자, 관심 구독 팝업 */

.subs-info-ct {
  position: absolute;
  overscroll-behavior: none;
  top: 160px;
  left: 190px;
  border: 1px solid #dcdcdc;
  /* padding: 19px 20px; */
  width: 301px;
  max-height: 221px;
  overflow-y: scroll;

  z-index: 5;
  background-color: #fff;
}

.subs-info-top {
  display: flex;
  align-items: center;
  color: var(--text-defaultBlackColor);
  font-size: 14px;
  font-weight: bold;
  padding: 19px 20px 13px 20px;
}

.subs-info-top .subs-info-top__closeBtn {
  margin-left: auto;
  background-color: #fff;
  border: none;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: url("../imgs/icons/exit.svg") no-repeat center;
}

.subs-list-ct {
  padding: 0 20px 0px 20px;
  max-height: 221px;
  /* overflow-y: scroll; */
}

.subs-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.subs-list-item .thumb {
  margin-right: 8px;

  display: inline-block;
}

.subs-list-item .thumb img {
  display: inline-block;
  width: 32px;
  height: 32px;
  /* border: 1px solid #a8a8a8; */
  border-radius: 100%;
}

.subs-list-item .name {
  flex: 1 1 60% !important;
  color: var(--text-defaultBlackColor) !important;
  font-size: 0.75rem !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subs-list-item .subscribe-btn,
.subs-list-item .subscribing-btn {
  width: 50px;
  height: 22px;
  font-size: 0.625rem !important;
  padding: 0 !important;
  margin-left: auto !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 480px) {
  .subs-info-ct {
    display: none;
  }
}

.subscribe-mobile-popup {
  display: none;
}

.subscribe-mobile-popup.blockMemberPopup {
  display: flex;
}

@media screen and (min-width: 481px) {
  .blockMemberPopup-ct {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .blockMember-list {
    min-width: 100%;
  }

  .blockMember-list li {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
  }

  .blockMember-list .tab-subs-list-item__pic {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .blockMember-list .tab-subs-list-item__pic img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .blockMember-list li:last-child {
    margin-bottom: 0;
  }

  .blockMember-list li:first-child {
    margin-top: 0;
  }

  .blockMember-list li .btn-edit {
    margin-left: auto;
  }
}

@media screen and (max-width: 480px) {
  .subscribe-mobile-popup.active {
    display: block;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 27px;
    left: 0;
    background-color: #fff;
    padding-top: 60px;
    z-index: 1;

    /* 230120 GNB */
    padding-bottom: 70px;
    overflow-y: scroll;
  }

  .tab-subs-ct {
    display: none;
  }

  .tab-subs-ct.active {
    display: block;
  }

  .subscribe-mobile-popup-title-box {
    display: flex;
  }

  .subscribe-mobile-popup-title-box h2 {
    text-align: center;
    flex: 1 1 50%;
    color: #666666;
    font-weight: 300;
    /* font-size: 24px; */
    font-size: 1.25rem;
    padding-bottom: 22px;
    cursor: pointer;
    /* margin-top: 58px; */
  }

  .subscribe-mobile-popup-title-box h2.active {
    border-bottom: 3px solid var(--category-allColor);
  }

  .subscribe-mobile-popup-title-box h2.active {
    color: var(--category-allColor);
    font-weight: bold;
  }

  .tab-subs-list {
    padding: 0 1.75rem;
  }

  .tab-subs-list-item {
    display: flex;
    margin: 1.25rem 0 0.625rem;
    align-items: center;
  }

  .tab-subs-list-item__pic {
    margin-right: 24px;
  }

  .tab-subs-list-item__pic img {
    /* width: 4.6875rem;
    height: 4.6875rem; */

    /* width: 3.5rem;
    height: 3.5rem; */
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
  }

  .tab-subs-list-item__name {
    color: var(--text-defaultBlackColor);
    /* font-size: 1.75rem; */
    font-size: 1rem;
  }

  .tab-subs-list-item button {
    margin-left: auto !important;
    width: 70px;
    min-height: 1.8rem !important;
    /* font-size: 13px !important; */
    font-size: 11px !important;
    border-radius: 5px;
  }
}

/* feedsection map 추가 */

.search-word-map-ct {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
}

@media screen and (max-width: 480px) {
  .search-word-map-ct {
    height: 180px;
  }
}

/* 구독자, 관심 구독 팝업 */

/* 탑메뉴 0314 프로필 사진 추가하기 */
.menu-mypage.header {
  margin-right: 30px !important;
  margin-top: 18px !important;
  text-align: center;
  position: relative;
}

.menu-mypage.header img {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

/* 탑메뉴 0314 프로필 사진 추가하기 */

/* 0315 퀵제보 input label 테그 변경 김진희*/
.quickJeboIconInput {
  display: none;
}

/* quickJebo 수정 */

/* 0315 퀵제보 input label 테그 변경 김진희*/

/* 0323 */

.search-dropdown-list li.selected button {
  background-color: #edf2f6;
}

/* 0323 */

/* 0324 아이폰에서 topmenu button 안되는 것 막기 */
.top-menu button {
  color: #000 !important;
}

/* 0324 아이폰에서 topmenu button 안되는 것 막기 */

/* 빠른제보 미리보기 CSS 0411*/
.previewFillContainer {
  z-index: 102 !important;
}

.previewFillContainer,
.previewContainer {
  padding: 0 20px;
  background-color: #000000;

  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 101;
}

.prevCt {
  margin: 10px 0;
  flex: 1 1 100%;
}

.previewFillContainer button,
.previewContainer button {
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border: none;
}

.previewRegistBtn {
  color: var(--text-linkColor);
  margin-left: auto;
  padding-right: 0;
}

.previewCloseBtn {
  color: #fff;
  padding-left: 0;
}

.previewCloseBtn img {
  width: 11px;
}

.previewHeader {
  display: flex;
}

.previewHeader button {
  background-color: transparent;
  width: auto;
}

.previewImgContainer {
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;

  /* 김진희 수정 0418 */
  /* 사진 크기 방지 한화면 */
  /* width: 100%; */
  height: 75%;
  overflow: hidden;
  position: relative;
}

.preImgTag {
  width: 100%;
  margin: auto 0;

  /* 김진희 수정 0418 */
  /* 사진 크기 방지 한화면 */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loading_container {
  z-index: 1000;
  background-color: rgb(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.launching {
  z-index: 1001;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.previewLocationTextArea,
.previewTextArea {
  width: 100%;
  margin-right: 10px;
  min-height: 35px;
  padding-left: 10px;
  display: flex;
}

.previewTextArea {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding-right: 25px;
  padding-left: 20px;
}

.previewText {
  max-height: 100%;
  line-height: 18px;
  outline: none;
}

.addressCheckBtn {
  color: #ccc;
  background-color: transparent;
  position: relative;
  font-size: 15px !important;
  display: flex;
  align-items: center;
}

.addressCheckBtn.check {
  color: #fff;
}

.addressCheckBtn__Img {
  margin-left: 5px;
  margin-top: 2px;
  width: 15px;
  height: 15px;
}

.addressCheckBtn.check .addressCheckBtn__Img {
  width: 12px;
  height: 12px;
}

.previewLocationTextArea {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.previewLocationTextArea input {
  visibility: hidden;
  display: none;
}

.previewText {
  font-size: 16px;
  color: #666;
  padding: 5px 0;
}

.previewBtmNav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.previewBtmNav button {
  flex: 1 1 40%;
  padding: 10px 20px;
}

.quickRegiBtn {
  margin-right: 5px;
  color: var(--text-defaultBlackColor);
  background-color: #dbdbdb;
}

.fillTextBtn {
  margin-left: 5px;
  color: #fff;
  background-color: var(--default-deepBlueColor);
}

.previewImgContainer-Fill {
  height: 90%;
  position: relative;
  display: flex;
}

.previewTextArea-Fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
}

/* 0531 textarea로 변경 수정 배경색 추가 */
.previewTextArea-Fill .previewText {
  color: #fff;
  /* width: 100%; */
  text-align: left;
  overflow-y: scroll;
  padding: 20px;
  background-color: transparent;

  max-height: 100%;
  border: none;
}

/*  text area 스크롤
.previewTextArea-Fill .previewText::-webkit-scrollbar {
}
*/

.previewTextArea-Fill .previewText::placeholder {
  color: #fff;
}

.previewTextArea-Fill .previewText:focus::placeholder {
  color: transparent;
}

.previewText[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #fff;
}

.write-btn.btmBtnContainer {
  display: flex;
  flex-wrap: wrap;
}

.btmBtnContainer .invalid {
  flex: 1 0 100%;
}

.write-btn.btmBtnContainer button {
  margin: 0 10px;
  flex: 1 1 40%;
}

.btmBtnContainer .cancle-btn {
  background-color: #ccc;
}

@media screen and (max-width: 480px) {
  .modal-body .item-header-wrap .item-header .time {
    margin-bottom: -4.5px;
  }

  .modal-body .item-header-wrap .item-header .distance {
    margin-bottom: -4.5px;
  }
}

/* 서비스 이용 약관 */
.serviceArgreeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceArgreeContainer.full-content {
  height: 70vh;
  max-height: calc(75vh - 90px);
}

.serviceArgreeContainer .serviceArgreeAll {
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  font-size: 18px;
  padding: 1rem;
  background-color: #ccc;
  border-radius: 3px;
}

.serviceAgreeContainer__wapper {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;

  max-height: 190px;
  overflow-y: scroll;
}

.serviceAgreeItem {
  display: flex;
  align-items: center;
  margin: 10px;
}

.serviceAgreeItem label {
  font-size: 12px;
}

.serviceAgreeItem span {
  font-size: 10px;
  margin-left: 4px;
  color: #333;
}

.serviceBtn {
  margin-left: auto;
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-size: 12px;
}

.serviceBtn:focus {
  font-size: 12px;
}

/* 이용약관 페이지 */

.info-wrap .service-wrap .service-item {
  margin: 1rem 0;
  display: flex;
}

.info-wrap .service-wrap .service-item span {
  color: #cf514a;
}

.info-wrap .service-wrap .service-item label {
  font-size: 0.8rem;
  line-height: 1.3rem;
  margin-left: 0.3rem;
}

.info-wrap .service-wrap .service-item .service-desc {
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  color: #666;
  font-weight: bold;
  font-size: 13px;
}

.info-wrap .service-wrap .service-complete-btn {
  border: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: auto;
  min-width: 178px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  font-size: 1rem;
  background: var(--default-deepBlueColor);
  color: #fff;
  font-weight: normal;
  transition: 0.15s;
  margin-top: 50px;
}

/* 이용 약관 전문 보기 */

/* 0603 전문보기 수정
  pc에서 사용하면 전문보기가 밑으로 짤려서 안보임
  -> min-width 이메일 회원가입 전문보기
*/
@media screen and (min-width: 481px) {
  .policy-container {
    /* position: absolute;
    height: 90%;
    left: 0;
    background-color: #fff;
    overflow-y: scroll; */

    height: 600px !important;
    overflow-y: scroll;
    transform: translate(0%, -1rem);
    height: 70%;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 15px 55px;
  }

  .policy-container.settings {
    transform: translate(0%, -10rem);
  }

  .about-us {
    overflow-y: hidden;
  }
}

.policy-container.sign {
  position: relative;
  height: 100%;
  top: 0px;
  left: 0;
  padding: 15px 50px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 3;
}

/* 진희 작성 모달 수정 0525*/
/* 진희 작성 모달 수정 0603 웹에서 화면이 위로 올라감 재수정*/
@media screen and (min-width: 481px) {
  .modal-wrap.agree.open {
    /* transform: translateY(-30%); */
    transform: translateY(-12.5%);
  }

  /*명삼 수정*/
  .modal-wrap.agree.open .modal-close {
    margin-top: 20px;
    align-self: flex-start;
    width: 32px;
    height: 20px;
  }
}

/* 전문보기 수정 진희 작성 0511 */

@media screen and (max-width: 480px) {
  .policy-container {
    position: absolute;
    height: 90%;
    left: 0;
    background-color: #fff;
    overflow-y: scroll;

    top: 50px;
    padding: 15px 20px;
    padding-bottom: 40px;
  }

  .policy-container button {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .serviceArgreeContainer.full-content {
    height: 80vh;
    max-height: 100vh;
  }

  /*웹 - 소셜로그인 회원가입 전문보기*/
  .policy-container.sign.email-sign {
    position: relative;
    height: 87vh;
    width: 100%;
    overflow-y: scroll;
    transform: translateY(0%);
  }

  .policy-container.sign.email-sign button {
    display: block;
  }

  .policy-container.sign.email-sign .modal-close {
    width: 100%;
    background: url("../imgs/icons/exit.svg") no-repeat;
    background-position: right center;
    background-size: 16px;
    margin-top: 0px;
  }
}

.policy-container__textBox {
  margin: 20px 0;
}

.policy__main__title {
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 1000;
  text-align: center;
  text-decoration: underline;
  text-decoration-style: double;
  text-underline-position: under;
}

@media screen and (max-width: 480px) {
  .policy__main__title {
    font-size: 18px;
  }

  .policy-container.sign {
    padding: 15px 20px;
  }

  .policy__table h2,
  .policy__table p {
    font-size: 11px;
    line-height: 12.5px;
  }

  .policy__table td:nth-child(1) {
    width: 20%;
  }

  .policy__table td:nth-child(2) {
    width: 45%;
  }

  .policy__table td:nth-child(3) {
    width: 35%;
  }
}

.policy__title {
  margin-bottom: 10px;
  font-weight: 500;
}

.policy__desc {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-bottom: 10px;
  margin-left: 5px;
  word-break: keep-all;
}

.policy__desc.no {
  margin-left: 25px;
  text-indent: -1em;
}

.policy__desc__detail {
  color: #666;
  display: block;
  margin: 5px 0 5px 20px;
  line-height: 1.2rem;
  font-size: 0.8rem;
  text-indent: -1.3em;
}

.policy__desc__detail.hyphen {
  text-indent: -0.7em;
}

.redColor {
  color: #cb2027;
}

.policy-container .service-complete-btn {
  border: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: auto;
  min-width: 178px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  font-size: 1rem;
  background: var(--default-deepBlueColor);
  color: #fff;
  font-weight: normal;
  transition: 0.15s;
  margin-top: 50px;
}

.policy__table {
  width: 100%;
  margin: 30px 0;
}

.policy__table tr {
  border-bottom: 1px solid #222;
}

.policy__table tr td {
  padding: 10px;
  text-align: center;
}

.table__thrRow td p {
  padding: 20px 0;
}

.policy-check-div {
  margin-top: 30px;
  border-top: 1px solid;
  padding-top: 10px;
}

/* 제보 찾을 수 없는 @멤버 디자인 */
.wrongTag_contianer .text {
  color: #666;
  margin: 5px;
  cursor: pointer;
  color: var(--default-deepBlueColor);
}

.wrongTag_contianer .text:hover {
  text-decoration: underline;
}

/* 제보 찾을 수 없는 검색어 내용 디자인 */

.emptySearch_container,
.wrongTag_contianer {
  background-color: #dcdcdc;
  text-align: center;
  width: 100%;
  height: 35%;
  top: 100px;
  padding: 50px 0;
}

/* .wrongTag_contianer {
  position: absolute;
} */
.wrongTag_contianer {
  height: 95vh;
}

.wrongTag_contianer,
.emptySearch_container {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.wrongTag_contianer .title,
.emptySearch_container .title {
  margin: 20px 0;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
}

.wrongTag_contianer .title {
  margin-top: 50px;
}

.emptySearch_container .title {
  padding: 15px;
}

.emptySearch_container .title .desc {
  font-size: 0.7rem;
  font-weight: 400;
}

.wrongTag_contianer .wrongTag_imgCt,
.emptySearch_container .emptySearch_imgCt {
  height: 75px;
}

.wrongTag_contianer .wrongTag_img,
.emptySearch_container .emptySearch_img {
  width: 100%;
  height: 100%;
  background-image: url(../imgs/icons/api_none.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emptySearch_container .keyword {
  display: none;
  color: var(--text-linkColor);
  margin: 20px 0;
  font-size: 1rem;
  font-weight: 500;
}

.wrongTag_contianer .back_btn,
.emptySearch_container .back_btn {
  display: none;
}

.wrongTag_contianer .back_btn:hover,
.emptySearch_container .back_btn:hover {
  font-weight: bold;
}

.wrongTag_contianer .back_btn:focus,
.emptySearch_container .back_btn:focus {
  font-size: 13px;
}

.search_popularKeyword {
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_popularKeyword__tagContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;

  margin-top: 10px;
  width: 60%;
}

.search_popularKeyword__tagContainer .search_popularKeyword__tag {
  background-color: var(--default-deepBlueColor);
  width: 200px;
  padding: 10px;
  word-wrap: break-word;
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
}

.search_popularKeyword__tagContainer .search_popularKeyword__tag div {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .emptySearch_container {
    /* margin-top: 80px; */
    padding-bottom: 20px;
  }

  .search_popularKeyword {
    margin-top: 2px;
    padding-top: 25px;
    display: block;
  }

  .search_popularKeyword__title {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .emptySearch_container .keyword {
    display: inline-block;
    margin-right: 5px;
  }

  .search_popularKeyword__tagContainer .search_popularKeyword__tag {
    width: 150px;
  }

  .search_popularKeyword__tagContainer {
    width: 100%;
  }

  .emptySearch_container .emptySearch_imgCt {
    height: 50px;
  }

  .emptySearch_container .emptySearch_img {
    width: 100%;
    height: 100%;
    background-image: url(../imgs/icons/api_none.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .wrongTag_contianer .back_btn,
  .emptySearch_container .back_btn {
    display: block !important;
    margin: 0 auto;
    margin-top: auto;
    color: #fff;
    background-color: var(--default-deepBlueColor);
    width: 80px;
    height: 30px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 30px;
  }
}

/* 제보 찾을 수 없는 @멤버 디자인 */
/* 제보 찾을 수 없는 검색어 내용 디자인 */

/* 스크롤 바 삭제 0531*/
@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* 날씨 테그 삭제 및 설명 추가 */
.weather-tags {
  margin-bottom: 0 !important;
}

.weather-desc {
  padding-top: 1px;

  margin-top: 10px;
  font-size: 11px;
  line-height: 13px;
  color: #666;
  /* color: var(--text-defaultGrayColor); */
}

.weather-desc__notice {
  color: var(--category-weatherColor);
}

/* 교통 text 더보기 버튼 이미지 */
.traffic .more-info-ct {
  display: flex;
  align-items: center;
  justify-content: center;
}

.traffic .more-infoBtn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(180deg);
  transition: 0.5s;
  background-color: transparent;
  background-image: url("../imgs/icons/m_expand.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center;
  border: 1px solid var(--text-defaultGrayColor);

  z-index: 3;
}

.traffic .more-infoBtn.active {
  transform: rotate(0deg);
}

.traffic .traffic-desc {
  height: 61px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3 !important;
  transition: 0.5s;
}
@media screen and (min-width: 481px) {
  .traffic .traffic-desc {
    height: 49px !important;
  }
}

.traffic .traffic-desc.active {
  height: 100px !important;
  -webkit-box-orient: unset !important;
  transition: 0.5s;
}

.traffic .item-info {
  transition: 0.5s;
  height: 100%;
}

@media screen and (min-width: 481px) {
  .traffic .more-infoBtn {
    width: 15px;
    height: 15px;
  }

  .traffic .item-info.active {
    z-index: 1;
    transform: translateY(-22px);
  }

  .traffic .traffic-desc.active {
    height: fit-content !important;
  }

  .traffic .item-bar-wrap {
    position: absolute;
    bottom: -19px;
    width: 100%;
  }
}

/* 교통 text 더보기 버튼 이미지 */

/* 최근 검색 내역 추가 0705 진희 작업 */
.recentLocation-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}

.recentLocation-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.recentLocation-header .title {
  margin-right: auto;
  font-size: 3.5vw;
}

.recentLocation-header .delAll-btn {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.7rem;
}

.recentLocation-list__item {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  background-color: var(--default-backgroundGrayColor);
  margin: 5px 0;
  padding: 10px 5px;
  font-size: 0.7rem;
  cursor: pointer;
  display: flex;
}

.recentLocation-list__item.active {
  background-color: #f5f5f5;
}

.recentLocation-list__item span {
  width: 100%;
  display: inline-block;
  word-break: break-all;
  line-height: 20px;
  font-size: 12px;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  padding-left: 8px;
}

.recentLocation-list__item .delete {
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/exit.svg) no-repeat;
  background-size: 9px 9px;
  background-position: center;
  cursor: pointer;
}

/* 최근 검색 내역 추가 0705 진희 작업 */
.menu-open.hidden {
  display: none;
}

.msg-send.hidden {
  background-color: var(--default-backgroundGrayColor) !important;
}

.under-line {
  border-bottom: 1px solid #f1f1f1;
}

/* 배너섹션 제목 밑 설명  */
.banner_section__desc {
  font-size: 13px;
  color: #343434;
  margin-bottom: 10px;
}

.jeboRank_box {
  display: flex;
  flex-direction: column;
}

/* .jeboRank_box .user-item{
    border: 1px solid red;
  } */

.jeboRank_box .user-rank .bottom {
  display: flex;
  height: 100%;
}

.jeboRank_box .jeboRank_bottom__desc {
  font-size: 11px;
  display: flex;
  color: var(--text-defaultGrayColor);
  margin-top: 10px;
}

.jeboRank_box .jeboRank_bottom__desc span {
  margin-right: 2px;
}

.rankerFeed_count {
  max-width: 75px;
}

.rankerFeed_countText {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  word-break: keep-all;
}

/* 제보 탑5 멤버 */

/* 계정 관리 */
@media screen and (min-width: 481px) {
  .account-manage .setting-item {
    border-bottom: none !important;
    padding: 0 !important;
  }

  .account-manage .setting-item {
    height: auto !important;
    margin-bottom: 30px;
  }

  .account-manage .setting-item span {
    font-weight: 400 !important;
  }
}

/* 계정 관리 */

/* 리워드 디자인 0706 */

.badge-list .badge-item {
  cursor: pointer;
}

.badge-list .badge-item .icon {
  display: inline-block;
}

.badge-item_desc {
  margin: 5px 0;
}

/* 리워드 디자인 0707 */
.badgeDetail_modal.web {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 250px;
  height: auto;
  display: block;
  background-color: #fff;
  border: 1px solid var(--text-defaultGrayColor);
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  padding: 5px 5px;
}

.badgeDetail_background.mobile {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.badgeDetail_modal.mobile {
  position: fixed;
  border: 1px solid #dcdcdc;
  border-bottom: none;
  background-color: #fff;

  border-radius: 10px 10px 0 0;

  animation: ModalOpenBadge 0.5s ease-in-out;
  width: 100%;
  bottom: -20%;
  left: 0%;

  padding: 10px;

  display: flex;
  flex-direction: column;
}

.my .badgeDetail_modal.mobile {
  bottom: -30%;
  animation: ModalOpenBadge_settings 0.5s ease-in-out;
}

.badgeDetail_modal.mobile.active {
  bottom: 50px;
}

.my .badgeDetail_modal.mobile.active {
  bottom: 0px;
}

.badgeDetail_modal.web .header {
  display: flex;
  margin-bottom: 10px;
}

.badgeDetail_modal.web .header .close_btn {
  height: 10px;
  width: 10px;

  float: right;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: auto;

  cursor: pointer;

  background: url(../imgs/icons//exit.svg) no-repeat;
}

/* .badgeDetail_modal.web::after{
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate( -45deg );

    width: 5px;
    height: 5px;
    background-color: #fff;
    border-top: 1px solid var(--text-defaultGrayColor);
    border-right: 1px solid var(--text-defaultGrayColor);
  } */
.badgeDetail_modal.mobile .showPercentText,
.badgeDetail_modal.web .showPercentText {
  min-width: 70px;
  margin-left: auto;
  font-size: 12px;
  margin-bottom: 10px;
}

.badgeDetail_modal.mobile .showPercentText {
  margin-top: 10px;
}

.badgeDetail_modal.mobile .showPercentText .percent,
.badgeDetail_modal.web .showPercentText .percent {
  color: var(--text-warningColor);
  font-size: 12px;
  letter-spacing: -0.8px;
  font-weight: bold;
}

.badgeDetail_modal.mobile .badgeDetail,
.badgeDetail_modal.web .badgeDetail {
  display: flex;
  margin-top: auto;
  border-radius: 10px;
  background-color: var(--default-backgroundGrayColor);
}

.badgeDetail_modal.mobile .badgeDetail {
  padding: 5px;
}

.badgeDetail_modal.mobile .badgeDetail img {
  width: 100px;
  height: 100px;
}

.badgeDetail_modal.web .badgeDetail img {
  width: 90px;
  height: 90px;
  padding: 5px;
}

.badgeDetail_modal.web .badgeDetail .badgeDetailTextBox,
.badgeDetail_modal.mobile .badgeDetail .badgeDetailTextBox {
  flex: 1 0 65%;
  padding: 5px;
}

.badgeDetail_modal.web .badgeDetail .badgeDetailTextBox p,
.badgeDetail_modal.mobile .badgeDetail .badgeDetailTextBox p {
  margin: 3px;
}

.badgeDetail_modal.mobile .badgeDetail__title,
.badgeDetail_modal.web .badgeDetail__title {
  font-size: 14px;
  font-weight: bold;
  word-break: break-all;
}

.badgeDetail_modal.mobile .badgeDetail__desc,
.badgeDetail_modal.web .badgeDetail__desc {
  color: var(--text-defaultGrayColor);
  font-size: 12px;
  padding: 3px 5px 5px 0;
  word-break: break-all;
}

/* 리워드 디자인 0707 */

/* 프로필 사진찍기 변경 0712 */
.menu-directCamera {
  display: none;
}

.directCamera_text {
  display: block;
  height: 45px !important;
  text-align: center;
  color: var(--text-defaultBlackColor);
  font-size: 0.835em;
  letter-spacing: -0.03em;
  line-height: 45px;
}

@media screen and (max-width: 480px) {
}

.removeProfilePic-notice {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  left: 0;
  bottom: 10px;
}

.removeProfilePic-notice.active {
  animation: fadeIn 1s ease-in-out;
}

.removeProfilePic-text {
  text-align: center;
  font-size: 12px;
  color: #fff;
  background-color: var(--default-deepBlueColor);
  padding: 10px;
  border-radius: 10px;
  width: 60%;
}

/* 프로필 사진찍기 변경 0712 */

/* 1:1 dm */
@media screen and (max-width: 480px) {
  .dm-link {
    line-height: 45px !important;
    width: 100% !important;
  }
}

/* 1:1 dm */

/* 피드 캠페인 아이템 */

.campaign .default-img {
  height: 100%;
  padding: 0;
  position: relative;
}

.campaign .default-img img {
  width: 100%;
  height: 100%;
  cursor: pointer;

  /* 1118 */
  /* object-fit : cover */
}

.campaign .default-img a {
  width: 100%;
  height: 100%;
}

.campaign .event_text {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  margin: 10px 5px;

  color: var(--text-defaultGrayColor);
}

.campaign .event_text::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: -15px;
  top: -1px;
  background: url("../imgs/icons/arrow_drop_down.svg") no-repeat;
  background-size: 15px 15px;
  transform: rotate(-90deg);
}

.campaign .item-bar-wrap {
  border-top: none;
}

.campaignRegister_btn_outlink {
  position: absolute;
  right: 40px;
  bottom: 85px;
  z-index: 100;
  /* background-color: var(--category-trafficColor); */
  background-color: var(--default-backgroundGrayColor);
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  width: 100px;
  height: 100px;
  cursor: pointer;

  color: var(--text-defaultBlackColor);
}

.feedinserted.campaign-btn-container {
  margin-bottom: 70px;
  margin-right: 5px;
  z-index: 1;
}

@media screen and (max-width: 480px) {
  .feedinserted.campaign-btn-container {
    margin-bottom: 20px;
    bottom: -10px;
  }

  .campaign-btn-container.singleCamp {
    bottom: 7px;
  }

  .section-feed.campaign-feed {
    padding-top: 0;
  }

  .cont-feed.campaignPageFeedContainer {
    padding-top: 55px;
  }

  .mobile-header.p-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
}

/* 피드 캠페인 아이템 */

/* 위치 검색 리스트 수정 */
.locSearch-list li a {
  overflow: unset;
  white-space: unset;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .location .sub-desc {
    color: var(--text-defaultGrayColor);
    font-size: 10px;
  }

  .locSearch-list {
    max-height: 245px;
    overflow-y: scroll;
    padding: 0 15px;
    border-radius: 3px;
  }

  .locSearch-list li {
    border: 1px solid #dcdcdc;
    background-color: #f5f5f5;
    margin: 3px 0;
  }

  .locSearch-list li a {
    width: 100%;
    display: inline-block;
    word-break: break-all;
    line-height: 20px;
    font-size: 12px;
    color: var(--text-defaultBlackColor);
    font-weight: 500;
  }

  .recentLocation-list {
    max-height: 245px;
    overflow-y: scroll;
  }

  .recentToFavLocationBtn {
    width: 20px;
    height: 20px;
    background: url(../imgs/icons/add_black_icon.svg) no-repeat;
    background-size: 15px;
    background-position: center center;
  }
}

/* 위치 검색 리스트 수정 */

.jeboRegiModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

/* banner 섹션 수정 */
.section-banner.pc {
  height: 120px;
}

.section-banner.pc .banner {
  text-align: center;
  flex-direction: column;
  overflow-x: hidden;
}

.section-banner.pc .banner .live-swiper {
  height: auto;
  /* width: 100% !important; */
}

.section-banner.pc .banner button {
  margin-top: 13px;
}

.section-banner.pc .banner .swiper-container {
  /* width: fit-content; */
  width: 100%;
}

/* 진희님 수정 필요 0725 */
/* .section-banner.pc .banner .swiper-container strong{
  transform: translateY(-100%);
} */
/* banner 섹션 수정 */

/* 이미지 미리보기 눌려보이는 현상 수정완료 */
div[data-rmiz-modal-content] img {
  object-fit: contain;
}

/* 이미지 미리보기 눌려보이는 현상 수정완료 */

/* 0726 캠페인 해쉬테그 영역 */
.campaignHashTag-ct {
  display: inline-block;
  margin-left: 20px;
  max-width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.campaignHashTag__text {
  font-size: 12px;
  color: rgb(82, 147, 197);
  margin-right: 5px;
}

@media screen and (max-width: 480px) {
  .campaignHashTag-ct {
    /* max-width: 100%; */
    /* display: block; */
    /* margin-left: 0px; */
    margin-left: 10px;
    max-width: 50%;
  }

  .campaignHashTag__text {
    font-size: 10px;
  }
}

/* 0726 캠페인 해쉬테그 영역 */

/* 위치 검색 아이콘  수정 */
.k-list li .delete.interloc {
  width: 20px;
  height: 20px;
  background: url(../imgs/icons/exit.svg) no-repeat;
  background-size: 9px;
  background-position: center;
}

/* 위치 검색 아이콘  수정 */

/* 공유하기 디자인 */

@media screen and (min-width: 481px) {
  .modal.modal-write {
    min-height: 770px;
  }

  .modal-header .modal-tabCt {
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
  }

  .modal-header .modal-tabCt button {
    border: solid 1px #dcdcdc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    width: 30%;
    height: 35px;

    color: #040404;
  }

  .modal-header .modal-tabCt button.active {
    background-color: var(--default-deepBlueColor);
    color: #fff;
  }

  .write-item.share {
    margin-bottom: 80px;
    margin-top: 10px;
  }
}

.write-item.share {
  margin-bottom: 80px;
  margin-top: 10px;
}

.item.share .item-body-wrap {
  background-color: #fff;
  min-height: 390px;
}

.share__textContainer {
  padding: 25px 15px 15px 15px;
  background-color: #89c8d5;
  width: 100%;
  height: 130%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 481px) {
  .right.open .share__textContainer {
    height: 100%;
  }
}

.share__title {
  flex: 0 0 auto;
  width: 90px;
  text-align: center;
  margin-bottom: 10px;

  display: inline-block;
  border: 1px solid #3773ff;
  border-radius: 20px;
  background-color: #e2eaf6;
  /*  
  border: 1px solid transparent;
  border-radius: 20px;
  background-image: linear-gradient(#E2EAF6, #E2EAF6), var(--category-allColor);
  background-origin: border-box;
  background-clip: content-box, border-box;
  */
  font-size: 12px;
  font-weight: 700;
  color: #3773ff;
  padding: 10px 15px;
}

.share__title__grid {
  left: 10px;
  top: 10px;
  flex: 0 0 auto;
  position: absolute;
  width: 60px;
  text-align: center;
  border: 1px solid #3773ff;
  border-radius: 20px;
  background-color: #e2eaf6;
  font-size: 5px;
  font-weight: 700;
  color: #3773ff;
  padding: 6px 5px;
}

.share__desc {
  width: 100%;

  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: -0.03em;

  /* 아래 높이를 추가해야 iphone에서 text가 잘 보임 */
  height: 100%;
  max-height: 195px;
}
.share__desc .text,
.share__desc .text-clamp {
  white-space: normal;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 28px;
}

/* 멤버페이지 제보요청 */
.share__desc__grid {
  width: 100%;
  white-space: normal;
  display: -webkit-box;

  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: -0.03em;
  margin-top: 30px;
}

.share__textBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80%;
  width: 100%;
}

.item-photo.share {
  background-color: #ddd;
  /* min-height: 360px; */
}

@media screen and (max-width: 480px) {
  .shareTab {
    height: 38px;
    transition: 0.5s;
    position: relative;
  }

  .shareTab.active {
    /* height: 110px; */
    height: 30vh;
  }

  .shareTab .shareTabBtn {
    top: 12px !important;
  }
}

/* 1016 */
.shareTab {
  height: 35px;

  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  overflow: hidden;
  transition: 0.5s;
  position: relative;
}

.shareTab.active {
  height: 140px;
  border-bottom: 0;
  overflow: unset !important;
  margin-bottom: auto;
  transition: 0.5s;
}

.shareTab .shareTabBtn {
  top: 7px;
  right: 5px;
  background: url(../imgs/icons/down_ac.svg) no-repeat;
  position: absolute;
  width: 51px;
  height: 17px;
}

.shareTab.active .shareTabBtn {
  background: url(../imgs/icons/up_ac.svg) no-repeat;
}

.shareTab .input-group.image {
  height: 100% !important;
}

/* 공유하기 디자인 */
.brder_class {
  border: 5px solid red;
}

/* 알림 내역 디자인 */

/* 알림 내역 종모양 */
.userNotice {
  margin-right: 20px !important;
  position: relative;
  z-index: 1000;
}

.userNotice__contents {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
}

.userNotice__contents img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  display: inline-block;
  border-radius: 100%;
}

.userNotice__count {
  display: none;
}

.userNotice__count.active {
  display: flex;
  position: absolute;
  top: 6px;
  right: -8px;
  font-size: 13px !important;
  padding: 0 5px;
  line-height: 20px;
  color: #fff;
  border-radius: 10px;
  background-color: #db1a1a;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 14px;
  letter-spacing: -0.015em;
}

/* 알림 내역 종모양 */

.userNoticeContainer {
  padding-bottom: 30px;
}

.noNoticeCt {
  display: flex;
  align-items: center;
  height: 85vh;
}

.pastNoticeCt {
  border-top: 1px solid #dcdcdc;
  padding-bottom: 15px;
}

.noticeCt {
  margin-bottom: 36px;
}

/*  웹 */

@media screen and (min-width: 481px) {
  .noNoticeCt {
    justify-content: center;
    height: 500px;
  }
}

.webUserNotice .clearNoticeBtn,
.webUserNotice .noNotice__desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.webUserNotice .notice__time {
  display: block;
  font-size: 14px;
}

.webUserNotice .unitNoticeCt .memberImg {
  width: 42px;
  height: 42px;
}

.webUserNotice .title {
  margin-left: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.webUserNotice .unitNoticeCt,
.webUserNotice .noticeBtnContainer {
  padding-left: 16px;
  padding-right: 14px;
}

.pastNoticeCt .unitNoticeCt,
.webUserNotice .noticeBtnContainer {
  background-color: #fff;
}

.currentNoticeCt .unitNoticeCt {
  background-color: #f3f8ff;
}

.webUserNotice .unitNoticeCt {
  width: 100%;
  height: 84px;
  margin-bottom: 0;
}

.webUserNotice .noticeBtnContainer {
  margin-bottom: 0;
}

@media screen and (max-width: 650px) {
  .webUserNoticeCt {
    width: 50vh !important;
    left: -35vh !important;
  }
}

.webUserNoticeCt {
  position: absolute;
  top: 60px;
  width: 566px;
  height: 563px;
  left: -330%;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  border-radius: 15px;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.webUserNoticeCt::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.webUserNoticeCt .userNoticeContainer,
.webUserNoticeCt .noticeCt {
  margin-bottom: 0px;
}

.webUserNotice {
  padding: 18px 0px 18px 0px;
}

.webUserNotice .noticeBtnContainer.current {
  margin-top: 26px;
}

.webUserNotice .noticeBtnContainer {
  padding-bottom: 11px;
  border-bottom: 1px solid #d0d0d0;
}

.webUserNotice .pastNoticeCt {
  border-top: 0;
}

.webUserNotice .notice__content {
  align-items: center;
}

.webUserNotice .notice__pic {
  width: 32px;
  height: 32px;
  margin-right: 37px;
  border: 1px solid #dcdcdc;
}

.webUserNotice .noticeDeleteBtn img {
  width: 16px;
  height: 16px;
}

.webUserNotice .notice__subBtn {
  width: 74px;
  height: 32px;
  font-size: 14px;
}

/* 웹 */

.unitNoticeCt {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.unitNoticeCt .memberImg {
  display: inline-block;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 13px;
}

.notice__text {
  flex: 1 1 auto;
  word-break: break-all;
  font-size: 16px;
  line-height: 22px;
  margin-right: 10px;
  cursor: pointer;
}

.notice__text .notice__userNick {
  font-weight: bold;
}

.notice__desc {
  margin-left: 3px;
  margin-right: 13px;
}

.notice__time {
  display: block;
  color: #595959;
  word-break: keep-all;
}

.notice__content {
  flex: 0 0 auto;
  margin-left: auto;
  display: flex;
}

.notice__content .notice__pic {
  width: 52px;
  height: 52px;
  margin-right: 1px;
}

.notice__content .notice__subBtn {
  width: 55px;
  min-height: 1.8rem;
  font-size: 8px;
  border-radius: 5px;
  border: solid 1px #dcdcdc;
  background-color: transparent;
  color: #000 !important;
}

.notice__content .notice__subBtn.active {
  background-color: #7cc5d8;
  color: #fff !important;
  border: none;
}

.notice__content .noticeDeleteBtn {
  height: 14px;
  border-radius: 5px;
  margin-left: 5px;
  background-color: transparent;
}

.notice__content .noticeDeleteBtn img {
  width: 100%;
  height: 100%;
}

.noticeBtnContainer {
  display: flex;
  margin-top: 14px;
  margin-bottom: 14px;
}

.noticeBtnContainer .clearNoticeBtn {
  background-color: transparent;
  text-decoration: underline;
  margin-left: auto;
  color: #595959;
}

/* 모바일 기준 */

@media screen and (max-width: 480px) {
  .userNotice {
    margin-right: 12px !important;
  }

  .noticeBtnContainer {
    background-color: #fff;

    padding-left: 12px;
    padding-right: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .unitNoticeCt {
    padding-left: 12px;
    padding-right: 12px;
    height: 84px;
    margin-bottom: 0;
  }

  .clearNoticeBtn {
    padding-right: 0;
  }

  .notice__desc {
    font-size: 16px;
    line-height: 22px;
  }

  .notice__text {
    margin-right: 16px;
  }

  .notice__time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .pastNoticeCt {
    border-top: none;
  }

  .notice__content .notice__pic {
    width: 42px;
    height: 42px;
  }
}

.mobileUserNoticeCt {
  padding: 0 !important;
}

.mobileUserNoticeCt .noticeBtnContainer {
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

.mobileUserNoticeCt .memberImg {
  width: 36px;
  height: 36px;
}

/* 알림 내역 디자인 */

/* reComment 수정 */
.reComment {
  padding-right: 5px !important;
}

.reComment .bottom {
  width: 100%;
}

.reComment .bottom .count {
  flex: 0 0 auto;
  margin-right: 5px;
}

.reComment .bottom .comment-add {
  word-break: keep-all;
  margin-right: 8px;
}

.reComment .bottom .re-time {
  margin-right: 0 !important;
}

.reCommentPic {
  width: 20px;
}
.recomment-write .file-upload {
  /* visibility: hidden;
  width: 10px;
  height: 10px; */
  display: none;
}

/* reComment 수정 */
.likeHistory--test {
  font-size: 8px;
  color: red;
}

.likeHistoryLayer {
  width: 80% !important;
  height: 88%;
  top: 10% !important;
  left: 50%;
  transform: translateX(-50%);
}

/* 1114 */
/* 공감내역 */
.likeHistoryTextBox {
  background-color: #fff;
  height: 26px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

@media screen and (min-width: 481px) {
  .modal-detail .feed-detail .likeHistoryTextBox {
    border: none;
  }
}

.likeHistoryTextBox .likeHistory__title {
  background-color: transparent;
  padding: 0px 0px 7px 11px;
  font-size: 12px;
  background-color: transparent;
  color: #4e4e4e;
  font-weight: bold;
  display: inline-block;
  text-align: left;
}

/* 웹영역 */
@media screen and (min-width: 481px) {
  .item.share .item-body-wrap {
    background-color: #fff;
    height: 280px !important;
    min-height: 364px !important;
  }

  .right.open .item.share .item-body-wrap {
    min-height: 400px !important;
  }

  .item-photo {
    height: 274px;
  }

  .pht-slider {
    height: 274px;
  }

  .item-info {
    height: 90px;
  }

  .item-wheather .item-info {
    height: 116px;
  }

  .item.campaign .item-body-wrap .pht-slider {
    height: 300px;
  }
}

/* 모바일 영역 */
@media screen and (max-width: 480px) {
  .item-info {
    height: 110px;
  }

  .share .item-photo {
    height: 274px;
  }

  .likeHistoryTextBox {
    border: none;
  }
}

/* 공감내역 */
/* 1114 */

/* 이미지 에디터 */
.img-editor-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.origin-layer,
.blur-layer,
.img-layer,
.drag-layer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.origin-layer {
  display: none;
}

.edit-header {
  position: absolute;
  /* margin-top: 1vh; */
  margin-left: 22px;
  z-index: 5;
  width: 100%;
  top: 10%;
}

.img-editor-modal .edit-btn {
  height: 30px;
  margin-top: auto !important;
  width: auto;
}

.edit-commit {
  background: var(--category-allColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: -10px;
  font-size: 17px;
  font-weight: bold;
}

.edit-btn {
  width: 80%;
  /* margin-left: 10%; */
  margin: 0 3%;
  border-radius: 25px;
  color: white;
  background-color: #0582db;
  cursor: pointer;
  outline: none;
  margin-bottom: 20px;
}

.edit-btn.blur-btn {
  background: url("../imgs/icons/Cloudy.svg") no-repeat;
  background-size: 100%;
  box-shadow: 0px 4px 17px rgb(0 0 0 / 35%);
  float: left;
  width: 6vh;
  height: 6vh;
}

.blur-btn.active {
  background: url("../imgs/icons/Hover_Cloudy.svg") no-repeat;
  background-size: 100%;
}

.edit-btn.draw-btn {
  background: url("../imgs/icons/Brush.svg") no-repeat;
  background-size: 100%;
  box-shadow: 0px 4px 17px rgb(0 0 0 / 35%);
  float: right;
  width: 6vh;
  height: 6vh;
}

.draw-btn.active {
  background: url("../imgs/icons/Hover_Brush.svg") no-repeat;
  background-size: 100%;
}

.edit-btn.undo-btn {
  background: url("../imgs/icons/Left.svg") no-repeat;
  background-size: 100%;
  box-shadow: 0px 4px 17px rgb(0 0 0 / 35%);
  /* background-color: #0081ffa6; */
  margin: 5px;
  width: 6vh;
  height: 6vh;
}

.undo-btn:active {
  background: url("../imgs/icons/Hover_Left.svg") no-repeat;
  /* background-size: 100%; */
}

.edit-btn.redo-btn {
  background: url("../imgs/icons/Right.svg") no-repeat;
  background-size: 100%;
  box-shadow: 0px 4px 17px rgb(0 0 0 / 35%);
  /* background-color: #0081ffa6; */
  margin: 5px;
  width: 6vh;
  height: 6vh;
}

.redo-btn:active {
  background: url("../imgs/icons/Hover_Right.svg") no-repeat;
  /* background-size: 100%; */
}

.edit-btn.reset-btn {
  background: url("../imgs/icons/Eraser.svg") no-repeat;
  background-size: 100%;
  box-shadow: 0px 4px 17px rgb(0 0 0 / 35%);
  margin: 5px;
  position: absolute;
  /* left: 83vw; */
  left: 75%;
  width: 6vh;
  height: 6vh;
}

.reset-btn:active {
  background: url("../imgs/icons/Hover_Eraser.svg") no-repeat;
  /* background-size: 100%; */
}

.modal .edit-modal-btn {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: none;
  background: var(--default-deepBlueColor);
  color: #ffffff;
  font-size: 1rem;
}

.btmBtnContainer .edit-cancle-btn {
  background-color: #ccc;
}

.modal-btn.edit {
  width: auto;
  float: right;
  height: 20px;
  font-size: 3vw;
  margin-left: 5vw;
  display: inline-block;
  clear: both;
}

.select-edit-btn {
  background: var(--default-deepBlueColor);
  color: #ffffff;
  position: absolute;
  top: 32.5%;
  left: 15%;
  width: 70%;
  height: 35%;
  z-index: 9;
}

/* 이미지 에디터 */

/* 캠페인 header 수정 */
.campaign.list-container {
  position: relative;
  z-index: 10;
}

.campaign.list-container .top.mobile {
  height: 65px;
  /* padding-top: 10px; */
}

/* 
.campaign.list-container .top.mobile .mobile-header{
  border-bottom: 0;
} */

.cont-sort.search.campaign {
  top: 65px;
}

/* 캠페인 header 수정 */

/* 밀어서 지우기 */
.swipe-to-delete {
  position: relative !important;
  padding: 0 !important;
  /* overflow:hidden !important */
}

.swipe-to-delete .js-delete {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
}

.swipe-to-delete .js-content {
  position: relative !important;
  z-index: 2 !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

.swipe-to-delete .js-content :first-child {
  position: relative !important;
}

.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-property: left !important;
  transition-duration: 0.5s;
}

.swipe-to-delete .js-transition-delete-right {
  left: 100% !important;
}

.swipe-to-delete .js-transition-delete-left {
  left: -100% !important;
}

.swipe-to-delete .js-transition-cancel {
  left: 0 !important;
}

.swipe-to-delete .js-delete {
  /* background:#ff5f58   */
  background: #dcdcdc;
}

.swipe-to-delete .js-delete svg {
  position: absolute;
  left: 17px;
  top: 40%;
  margin-top: -10px;
  width: 25px;
  height: 25px;
}

.swipe-to-delete .js-delete svg:first-child {
  left: auto;
  right: 17px;
}

.swipe-to-delete .js-content :first-child {
  cursor: pointer;
}

.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

/* 0105 */
/* 미디어가 없을때의 feed photo */
.feed-photo.pc .noMedia {
  height: 100%;
  width: 100%;
  padding: 60px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #89c8d5;
}

.feed-photo.pc .noMedia .noMedia__text {
  width: 85%;
  max-height: 52%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  font-size: 24px;

  color: #fff;
  line-height: 35px;
}

.feed-photo .noMedia .noMedia__text a {
  font-size: 28px;
}

@media screen and (max-width: 480px) {
  .feed-photo .noMedia {
    height: 356px;
    background-color: #89c8d5;
    display: flex;
    align-items: center;
  }

  .feed-photo .noMedia .noMedia__text {
    padding: 15px 15px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    word-break: break-all;
    height: 60%;
    margin: 15px 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.3em;

    letter-spacing: -0.03em;
    color: #fff;
  }

  .feed-photo .noMedia .noMedia__text a {
    font-size: 30px;
    color: #5293c5;
  }
}

/* 미디어가 없을때의 feed photo */

/* feed item 내 특정 태그 처리 */
.text-highlight {
  color: var(--text-warningColor);
  font-size: 15px;
  font-weight: bold;
}

.text-normal {
  color: #5293c5;
}

/* feed item 내 특정 태그 처리 */

.location-tag {
  position: absolute;
  color: gray;
  margin-left: 20px;
  font-size: 13px;
}

.location-tag {
  position: absolute;
  color: #8e8e8e;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
}

.location-tag.hidden {
  display: none;
}

/* .pulling-contents,
.pulling-contents * {
  -webkit-overflow-scrolling: auto !important;
} */

.ptr,
.ptr__children {
  -webkit-overflow-scrolling: auto !important;
}

/* 0109 #특정해시태그 검색시 나오는 text */
/* .mapFeed__textCt {
  background-color: #89c8d5;
  color: #fff;
  text-align: center;}

.mapFeed__text {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;}

.text__hashTagCt {
  color: var(--text-warningColor);
  font-weight: bold;
  font-size: 13px;} */
/* 0109 #특정해시태그 검색시 나오는 text */

/* 앱 유도 CSS  START */
.induceAppPopLayer_background {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  touch-action: none;
}

.induceApp_Pop {
  top: 25%;
  left: 0;
  position: fixed;
  width: 100%;
  height: 35%;
}

.induceApp_Pop_Layer {
  background-color: white;
  width: 85%;
  height: 165px;
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  border-radius: 35px;
}

.induceApp_Desc {
  display: flex;
  height: 70%;
}

.induceApp_Logo {
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.induceApp_Logo img {
  width: 70px;
  height: 70px;
  border-radius: 20px;
}

.induceApp_Text_Box {
  margin: auto auto auto 5px;
  line-height: 1.3;
  word-break: break-word;
}

.induceApp_title {
  font-size: 15px;
}

.induceApp_Link {
  z-index: 10;
  touch-action: none;
  position: fixed;
  background: var(--category-allColor);
  width: 100%;
  height: 30%;
  border-radius: 0px 0px 30px 30px;
}

.induceApp_Link a {
  width: 100%;
  height: 100%;
  text-align: center;
  transform: translateY(50%);
  top: -25%;
  position: absolute;
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.not_induceApp__Btn {
  margin-top: 165px;
  font-size: 15px;
  background-color: inherit;
  color: white;
  border-bottom: 1px solid;
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  width: max-content;
  /* letter-spacing: 3px; */
}

/* 앱 유도 CSS  END */

/* gridFeddSection 내 list item ui */
.mapTagMarkCt {
  /* width: 65%; */
  height: 100%;

  /* 편의지도 화면 작업(0221) */
  width: 100%;
  position: relative;
  display: flex;
}
.mapTagMarkList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  overflow-y: hidden;
}

.mapTagMarkList.active {
  overflow-y: unset;
}
.mapMark-item {
  flex: 0 0 auto;

  /* 편의지도 화면 작업(230307) */
  /* margin-bottom: 3px; */
}
.mapMark-itemBtn {
  /* 편의지도 화면 작업(230307) */
  /* background-color: rgba(255,255,255,0.7); */
  /* border-radius: 6px; */
  /* margin: 0 4px; */
  /* margin-bottom: 3px; */
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  color: #238cfa;
}

/* 편의지도 화면 작업(230307) */
.mpaIconCt-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 8px;
  top: 360px;
}
.current-Location {
  width: 29px;
  height: 29px;
  font-size: 0;
}
.current-Location::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 21px;
  background: url(../imgs/icons/icon_mapMarker_blue.svg) no-repeat;
  margin-right: 5px;
  position: relative;
  top: 5px;
  left: 11px;
  border-radius: 20px;
}

.itemBtnPlus {
  height: 32px;
  background-color: #e2e5e7;
  color: #232323;
  border-radius: 0 20px 20px 0;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 0.9rem;
  padding: 8px;
  font-weight: bold;
}

.itemList {
  visibility: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e2e5e7;
  padding: 10px;
  border-radius: 10px;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}
.itemList.active {
  visibility: visible;
}
.itemList p {
  display: inline-block;
  margin: 5px 10px 5px 0;
  font-weight: 400;
  font-size: 14px;
}
.itemList .list-close {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 14px;
  height: 14px;
  background: url("../imgs/icons/exit.svg") no-repeat;
}

/* gridFeddSection 내 list item ui */

.my_progress_bar {
  display: grid;
  width: 80vw;
  align-items: center;
}
